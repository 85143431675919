// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverRequest
 */
export interface DriverRequest  {
    /**
     * 
     * @type {string}
     * @memberof DriverRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DriverRequest
     */
    forename: string;
    /**
     * 
     * @type {string}
     * @memberof DriverRequest
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof DriverRequest
     */
    adrExpiration?: Date;
    /**
     * 
     * @type {string}
     * @memberof DriverRequest
     */
    shippingAgentId: string;
}

export function DriverRequestFromJSON(json: any): DriverRequest {
    return {
        'name': json['name'],
        'forename': json['forename'],
        'language': json['language'],
        'adrExpiration': !exists(json, 'adrExpiration') ? undefined : new Date(new Date(json['adrExpiration']).getTime()+ new Date(json['adrExpiration']).getTimezoneOffset()*60*1000),
        'shippingAgentId': json['shippingAgentId'],
    };
}

export function DriverRequestToJSON(value?: DriverRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'forename': value.forename,
        'language': value.language,
        'adrExpiration': value.adrExpiration === undefined ? undefined : new Date(value.adrExpiration.getTime() - value.adrExpiration.getTimezoneOffset()*60*1000).toISOString(),
        'shippingAgentId': value.shippingAgentId,
    };
}


