// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccountCreationRequest,
    AccountCreationRequestFromJSON,
    AccountCreationRequestToJSON,
    CollectorAccountCreationRequestAllOf,
    CollectorAccountCreationRequestAllOfFromJSON,
    CollectorAccountCreationRequestAllOfToJSON,
} from './';

/**
 * @type CollectorAccountCreationRequest
 * @export
 */
export interface CollectorAccountCreationRequest extends AccountCreationRequest, CollectorAccountCreationRequestAllOf {
}

export function CollectorAccountCreationRequestFromJSON(json: any): CollectorAccountCreationRequest {
    return {
        ...AccountCreationRequestFromJSON(json),
        ...CollectorAccountCreationRequestAllOfFromJSON(json),
    };
}

export function CollectorAccountCreationRequestToJSON(value?: CollectorAccountCreationRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AccountCreationRequestToJSON(value),
        ...CollectorAccountCreationRequestAllOfToJSON(value),
    };
}
