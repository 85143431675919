import React, { useRef, useState } from 'react';
import {
  ClearanceData,
  clearanceToClearanceReq,
  GlobaleClearanceData,
} from '../components/CollectorClearances';
import { useMutation } from 'redux-query-react';
import {
  createCustomerFull,
  CustomerCreationResponse,
  upsertCustomerClearance,
} from '../../../generated/api/src';
import * as stalenessActions from '../../staleness/actions';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import CollectorForm from '../components/CollectorForm';
import { AddressData } from '../../common/components/AddressContact';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { collectorScmListStoreKey } from '../../../store/store-keys';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { ScmRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';

const mapStateToProps = () => ({});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AddCollector: React.FC<Props> = ({ markListAsStale }) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<AddressData>({
    name: '',
    country: '',
    email: '',
    fax: '',
    language: '',
    mobile: '',
    street: '',
    telephone: '',
    town: '',
    zipCode: '',
  });
  const [clearances, setClearances] = useState<ClearanceData>({});
  const [globalClearances, setGlobalClearances] =
    useState<GlobaleClearanceData>({
      maxWeight: 0,
      active: false,
    });

  const [{ isPending: customerCreationPending }, createCustomer] = useMutation(
    (addr: AddressData, globalClear: GlobaleClearanceData) =>
      createCustomerFull<CustomerCreationResponse>({
        customerFullRequest: {
          name: addr.name,
          active: globalClear.active,
          maxWeight: globalClear.maxWeight,
          country: addr.country,
          email: addr.email !== '' ? addr.email : undefined,
          fax: addr.fax !== '' ? addr.fax : undefined,
          language: addr.language !== '' ? addr.language : undefined,
          mobile: addr.mobile !== '' ? addr.mobile : undefined,
          street: addr.street,
          telephone: addr.telephone !== '' ? addr.telephone : undefined,
          town: addr.town,
          zipCode: addr.zipCode,
        },
      })
  );
  const [{ isPending: clearanceCreationPending }, upsertClearances] =
    useMutation((clear: ClearanceData, customerId: string) =>
      upsertCustomerClearance(clearanceToClearanceReq(clear, customerId))
    );

  const content = () => {
    if (customerCreationPending || clearanceCreationPending) {
      return <Loading />;
    }
    return (
      <>
        <CollectorForm
          setClearanceData={setClearances}
          setAddressData={setAddressData}
          clearanceData={clearances}
          addressData={addressData}
          setGlobalClearanceData={setGlobalClearances}
          globalClearanceData={globalClearances}
          onValidation={setAddressDataValid}
        />
        <Link to={ScmRouteMap.collectorList} ref={cancelLink} />
        <RequestErrorSnackbar
          error={creationError}
          onClose={() => setCreationError(null)}
        />
        <EditButtonRow
          onSave={() => {
            markListAsStale(collectorScmListStoreKey);
            createCustomer(addressData, globalClearances)?.then(response => {
              if (response.status > 299) {
                setCreationError(response);
                return Promise.reject();
              }

              return upsertClearances(
                clearances,
                response.transformed!.id
              )?.then(resp => {
                if (resp.status > 299) {
                  setCreationError(resp);
                } else {
                  cancelLink.current?.click();
                }
              });
            });
          }}
          disableSave={!addressDataValid}
          onCancel={() => cancelLink?.current?.click()}
          allowDelete={false}
        />
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader
        linkUrl={ScmRouteMap.collectorList}
        linkText={t('collectors')}
        heading={t('addCollector')}
      />
      {content()}
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(AddCollector);
