import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Home from '../features/operator/screens/Home';
import EditDriver from '../features/operator/screens/EditDriver';
import EditVehicle from '../features/operator/screens/EditVehicle';
import AddAccount from '../features/scm/screens/AddAccount';
import { AccountCreationRequestAccountTypeEnum } from '../generated/api/src';
import EditAccount from '../features/scm/screens/EditAccount';
import { OperatorRouteMap } from './root-route-map';

const Routes: React.FC = () => {
  const path = useRouteMatch().path;

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}drivers`} />}
      />
      <Route exact path={Object.values(OperatorRouteMap)} component={Home} />

      <Route exact path={`${path}edit-driver/:id`} component={EditDriver} />
      <Route exact path={`${path}edit-vehicle/:id`} component={EditVehicle} />

      <Route
        exact
        path={`${path}add-account`}
        component={() => (
          <AddAccount
            allowedRoles={[AccountCreationRequestAccountTypeEnum.Operator]}
            returnLinkTarget={OperatorRouteMap.accountList}
          />
        )}
      />
      <Route
        exact
        path={`${path}edit-account/:id`}
        component={() => (
          <EditAccount returnLinkTarget={OperatorRouteMap.accountList} />
        )}
      />
    </Switch>
  );
};

export default Routes;
