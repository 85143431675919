import { createReducer } from 'typesafe-actions';
import i18n from '../../i18n';
import {
  assumeRoleAsync,
  setLanguage,
  signedInAsync,
  signOutAsync,
} from './actions';
import { UserData } from './types';

const browserLanguage = () => {
  var language = navigator.language.substring(0, 2);
  if (language === 'de') {
    i18n.changeLanguage('de');
    return 'de';
  }
  i18n.changeLanguage('en');
  return 'en';
};

interface State {
  // The role and name (email) of a user
  user?: UserData;
  userError?: Error;
  // The role this user has chosen to assume
  // Only valid for admin users.
  // This role, if set, is transmitted with every request
  // to fake different roles.
  // The backend checks if a user has the permission to assume roles
  assumedRole?: UserData | null;
  readonly stage: string;
  language: string;
}

const initialState = {
  stage: process.env.REACT_APP_STAGE,
  language: browserLanguage(),
} as State;

export const commonReducer = createReducer(initialState)
  .handleAction(signedInAsync.success, (state, { payload: user }) => ({
    ...state,
    user,
    userError: undefined,
  }))
  .handleAction(signedInAsync.failure, (state, { payload: error }) => ({
    ...state,
    userError: error,
  }))
  .handleAction(signOutAsync.success, () => ({
    ...initialState,
  }))
  .handleAction(setLanguage, (state, action) => ({
    ...state,
    language: action.payload === 'de' ? 'de' : 'en',
  }))
  .handleAction(assumeRoleAsync.success, (state, { payload: assumedRole }) => ({
    ...state,
    assumedRole,
  }));

export default commonReducer;
export type CommonState = ReturnType<typeof commonReducer>;
