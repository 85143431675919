// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    VehicleCustomerListEntry,
    VehicleCustomerListEntryFromJSON,
    VehicleCustomerListEntryToJSON,
    VehicleOperatorListEntryAllOf,
    VehicleOperatorListEntryAllOfFromJSON,
    VehicleOperatorListEntryAllOfToJSON,
} from './';

/**
 * @type VehicleOperatorListEntry
 * @export
 */
export interface VehicleOperatorListEntry extends VehicleCustomerListEntry, VehicleOperatorListEntryAllOf {
}

export function VehicleOperatorListEntryFromJSON(json: any): VehicleOperatorListEntry {
    return {
        ...VehicleCustomerListEntryFromJSON(json),
        ...VehicleOperatorListEntryAllOfFromJSON(json),
    };
}

export function VehicleOperatorListEntryToJSON(value?: VehicleOperatorListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...VehicleCustomerListEntryToJSON(value),
        ...VehicleOperatorListEntryAllOfToJSON(value),
    };
}
