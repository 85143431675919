// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperatorAccountCreationRequestAllOf
 */
export interface OperatorAccountCreationRequestAllOf  {
    /**
     * 
     * @type {Array<string>}
     * @memberof OperatorAccountCreationRequestAllOf
     */
    plants: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorAccountCreationRequestAllOf
     */
    shouldBeAdmin: boolean;
}

export function OperatorAccountCreationRequestAllOfFromJSON(json: any): OperatorAccountCreationRequestAllOf {
    return {
        'plants': json['plants'],
        'shouldBeAdmin': json['shouldBeAdmin'],
    };
}

export function OperatorAccountCreationRequestAllOfToJSON(value?: OperatorAccountCreationRequestAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plants': value.plants,
        'shouldBeAdmin': value.shouldBeAdmin,
    };
}


