export const RootRouteMap = {
  scm: '/scm/',
  customer: '/customer/',
  plantOperator: '/operator/',
  admin: '/admin/',
};

export const CustomerRouteMap = {
  collectorList: `${RootRouteMap.customer}collectors`,
  shippingAgentList: `${RootRouteMap.customer}shipping-agents`,
  driverList: `${RootRouteMap.customer}drivers`,
  vehicleList: `${RootRouteMap.customer}vehicles`,
};

export const OperatorRouteMap = {
  driverList: `${RootRouteMap.plantOperator}drivers`,
  vehicleList: `${RootRouteMap.plantOperator}vehicles`,
  accountList: `${RootRouteMap.plantOperator}accounts`,
};

export const ScmRouteMap = {
  collectorList: `${RootRouteMap.scm}collectors`,
  accountList: `${RootRouteMap.scm}accounts`,
};
