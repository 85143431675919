// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerFullRequestAllOf,
    CustomerFullRequestAllOfFromJSON,
    CustomerFullRequestAllOfToJSON,
    CustomerRequest,
    CustomerRequestFromJSON,
    CustomerRequestToJSON,
} from './';

/**
 * @type CustomerFullRequest
 * @export
 */
export interface CustomerFullRequest extends CustomerFullRequestAllOf, CustomerRequest {
}

export function CustomerFullRequestFromJSON(json: any): CustomerFullRequest {
    return {
        ...CustomerFullRequestAllOfFromJSON(json),
        ...CustomerRequestFromJSON(json),
    };
}

export function CustomerFullRequestToJSON(value?: CustomerFullRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerFullRequestAllOfToJSON(value),
        ...CustomerRequestToJSON(value),
    };
}
