// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverCustomerListEntry
 */
export interface DriverCustomerListEntry  {
    /**
     * 
     * @type {string}
     * @memberof DriverCustomerListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DriverCustomerListEntry
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverCustomerListEntry
     */
    forename?: string;
    /**
     * 
     * @type {Date}
     * @memberof DriverCustomerListEntry
     */
    adrExpiration?: Date;
    /**
     * 
     * @type {string}
     * @memberof DriverCustomerListEntry
     */
    shippingAgentName: string;
}

export function DriverCustomerListEntryFromJSON(json: any): DriverCustomerListEntry {
    return {
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'forename': !exists(json, 'forename') ? undefined : json['forename'],
        'adrExpiration': !exists(json, 'adrExpiration') ? undefined : new Date(new Date(json['adrExpiration']).getTime()+ new Date(json['adrExpiration']).getTimezoneOffset()*60*1000),
        'shippingAgentName': json['shippingAgentName'],
    };
}

export function DriverCustomerListEntryToJSON(value?: DriverCustomerListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'forename': value.forename,
        'adrExpiration': value.adrExpiration === undefined ? undefined : new Date(value.adrExpiration.getTime() - value.adrExpiration.getTimezoneOffset()*60*1000).toISOString(),
        'shippingAgentName': value.shippingAgentName,
    };
}


