import React from 'react';
import { useTranslation } from 'react-i18next';
import { Customer, listCustomers } from '../../../generated/api/src';
import List from '../../../components/list/List';
import { customerCustomerViewListStoreKey } from '../../../store/store-keys';

const CustomerList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <List
      hasPagination={true}
      hasSearchBar={true}
      editEntityLink={'/customer/edit-collector'}
      columnLabels={[
        { prop: 'name', label: t('name') },
        { prop: 'town', label: t('city') },
      ]}
      columnLabelGrid={'2.5fr 1.75fr'}
      rowValueTransform={(obj: Customer) => [
        obj.name,
        obj.town ? obj.town : '-',
      ]}
      entityKey={customerCustomerViewListStoreKey}
      // @ts-ignore
      queryBuilder={listCustomers}
      key={'customersList'}
    />
  );
};

export default CustomerList;
