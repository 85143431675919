import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import { Link, useParams } from 'react-router-dom';

import {
  DriverClearance,
  DriverClearanceRequest,
  DriverFull,
  getClearancesForDriver,
  getDriverOperator,
  updateDriverOperator,
  upsertClearancesForDriver,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import { useTranslation } from 'react-i18next';
import { RootState } from 'typesafe-actions';
import formStyles from '../../../components/form/formStyles.module.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import DriverClearancesEdit, {
  DriverClearanceData,
} from '../components/DriverClearancesEdit';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { DatePicker } from '@mui/lab';
import * as stalenessSelectors from '../../staleness/selectors';
import {
  driverClearancesForDriverStoreKey,
  driverOperatorListStoreKey,
  driverOperatorStoreKey,
} from '../../../store/store-keys';
import { OperatorRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import LabelValuePair from '../../../components/LabelValuePair';
import ShippingAgentDisplay from '../components/ShippingAgentDisplay';
import LanguageDisplay from '../../common/components/LanguageDisplay';
import { format } from 'date-fns';
import { ValidationRegex } from '../../common/components/ValidationRegex';
import { sanitizeDateFromDatePicker } from '../../../util/SanitizeDateFromDatePickerHelper';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditDriver: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);
  const { id: driverId } = useParams<{ id: string }>();

  const [clearances, setClearances] = useState<DriverClearanceData>({});
  const [transponder, setTransponder] = useState<string>('');
  const [globalClearance, setGlobalClearance] = useState<boolean>(false);
  const [medTraining, setMedTraining] = useState<Date | null>(null);

  const transponderOk =
    transponder.match(ValidationRegex.Driver.Transponder) != null ||
    transponder === '';

  const medTrainingOk =
    medTraining === null ||
    (!isNaN(medTraining.getTime()) && medTraining.getFullYear() > 2000);

  const [{ isPending: driverUpdatePending }, updateDriverHandle] = useMutation(
    () =>
      updateDriverOperator({
        driverRequestOperator: {
          id: driverId,
          active: globalClearance,
          medTraining: medTraining,
          transponder: transponder === '' ? null : transponder,
        },
      })
  );

  const driverQueryConfig = getDriverOperator(
    { driverId },
    {
      transform: body => {
        return { [driverOperatorStoreKey]: { [body.id]: body } };
      },
      update: {
        [driverOperatorStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(driverOperatorStoreKey, driverId),
    }
  );

  const [{ isPending: driverPending, lastUpdated: driverUpdateTs }] =
    useRequest(driverQueryConfig);

  let driverEntity = useSelector((state: any) => {
    return (state.entities?.[driverOperatorStoreKey]?.[driverId] ??
      null) as DriverFull | null;
  });

  const [
    { isPending: driverClearanceUpdatePending },
    updateDriverClearancesHandle,
  ] = useMutation(() =>
    upsertClearancesForDriver({
      driverId,
      driverClearanceRequest: Object.values(clearances),
    })
  );

  const clearanceQueryConfig = getClearancesForDriver(
    { driverId },
    {
      transform: body => {
        return { [driverClearancesForDriverStoreKey]: { [driverId]: body } };
      },
      update: {
        [driverClearancesForDriverStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(driverClearancesForDriverStoreKey, driverId),
    }
  );

  const [{ isPending: clearancesPending, lastUpdated: clearanceUpdateTs }] =
    useRequest(clearanceQueryConfig);
  let clearanceEntities = useSelector((state: any) => {
    return (state.entities?.[driverClearancesForDriverStoreKey]?.[driverId] ??
      []) as DriverClearance[];
  });

  useEffect(() => {
    setClearances(
      Object.fromEntries(clearanceEntities.map(it => [it.plantId, it]))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearanceUpdateTs]);

  useEffect(() => {
    setGlobalClearance(driverEntity?.active ?? false);
    setTransponder(driverEntity?.transponder ?? '');
    setMedTraining(driverEntity?.medTraining ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUpdateTs]);

  const updateClearanceDataFor = (
    plantId: string,
    key: keyof DriverClearanceRequest,
    value: any
  ) => {
    setClearances({
      ...clearances,
      [plantId]: {
        ...(clearances[plantId] ?? {}),
        [key]: value,
        driverId,
        plantId,
      },
    });
  };

  if (
    driverPending ||
    driverUpdatePending ||
    clearancesPending ||
    driverClearanceUpdatePending ||
    driverEntity == null
  ) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={OperatorRouteMap.driverList}
        linkText={t('drivers')}
        heading={t('editDriver')}
      />
      <div className={formStyles.section}>
        <h2>{t('personalData')}</h2>
        <div className={formStyles.values}>
          <LabelValuePair label={t('name')} value={driverEntity.name} />
          <LabelValuePair label={t('forename')} value={driverEntity.forename} />
          <LabelValuePair
            label={t('adrExpiration')}
            value={
              driverEntity.adrExpiration != null
                ? format(driverEntity.adrExpiration, t('dateFormatDateOnly'))
                : '--'
            }
          />
          <LabelValuePair
            label={t('shippingAgent')}
            value={
              <ShippingAgentDisplay
                shippingAgentId={driverEntity.shippingAgentId}
              />
            }
          />
          <LabelValuePair
            label={t('language')}
            value={<LanguageDisplay languageId={driverEntity.language} />}
          />
        </div>
      </div>

      <div className={formStyles.section}>
        <h2>{t('clearances')}</h2>
        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          <TextField
            label={t('transponder')}
            className={formStyles.input}
            value={transponder}
            onChange={event => setTransponder(event.target.value)}
            error={!transponderOk}
          />
          <FormControl>
            <div
              className={formStyles.outlined}
              style={{ paddingLeft: '14px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    onChange={event => setGlobalClearance(event.target.checked)}
                  />
                }
                label={t('clearanceAll')}
                checked={globalClearance}
              />
            </div>
          </FormControl>
        </div>

        <div className={formStyles.inputContainer}>
          <DatePicker
            label={t('medicalTraining')}
            value={medTraining}
            onChange={newValue => {
              const newDate = sanitizeDateFromDatePicker(newValue);

              setMedTraining(isNaN(newDate.getTime()) ? null : newDate);
            }}
            renderInput={params => (
              <TextField {...params} className={formStyles.picker} />
            )}
            inputFormat={t('dateFormatDateOnly')}
            mask={t('dateFormatDateMask')}
          />
        </div>

        <DriverClearancesEdit
          driverId={driverId}
          clearances={clearances}
          onUpdateClearanceData={updateClearanceDataFor}
        />
      </div>

      <Link to={OperatorRouteMap.driverList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onCancel={() => cancelLink?.current?.click()}
        disableSave={!transponderOk || !medTrainingOk}
        onSave={() => {
          markListAsStale(driverOperatorListStoreKey);
          markEntityAsStale([driverOperatorStoreKey, [driverId]]);
          markEntityAsStale([driverClearancesForDriverStoreKey, [driverId]]);
          updateDriverHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
          updateDriverClearancesHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditDriver);
