// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DriverClearance,
    DriverClearanceFromJSON,
    DriverClearanceToJSON,
    DriverClearanceRequest,
    DriverClearanceRequestFromJSON,
    DriverClearanceRequestToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface GetClearancesForDriverRequest {
    driverId: string;
}

export interface UpsertClearancesForDriverRequest {
    driverId: string;
    driverClearanceRequest: Array<DriverClearanceRequest>;
}


/**
 * Lists the clearances for a driver (Fahrer)
 */
function getClearancesForDriverRaw<T>(requestParameters: GetClearancesForDriverRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DriverClearance>> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getClearancesForDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver-clearance/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverClearanceFromJSON), text);
    }

    return config;
}

/**
* Lists the clearances for a driver (Fahrer)
*/
export function getClearancesForDriver<T>(requestParameters: GetClearancesForDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DriverClearance>>): QueryConfig<T> {
    return getClearancesForDriverRaw(requestParameters, requestConfig);
}

/**
 * Upserts new and existing clearances for a driver (Fahrer)
 */
function upsertClearancesForDriverRaw<T>(requestParameters: UpsertClearancesForDriverRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling upsertClearancesForDriver.');
    }

    if (requestParameters.driverClearanceRequest === null || requestParameters.driverClearanceRequest === undefined) {
        throw new runtime.RequiredError('driverClearanceRequest','Required parameter requestParameters.driverClearanceRequest was null or undefined when calling upsertClearancesForDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver-clearance/{driverId}/upsert`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.driverClearanceRequest?.map(DriverClearanceRequestToJSON),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Upserts new and existing clearances for a driver (Fahrer)
*/
export function upsertClearancesForDriver<T>(requestParameters: UpsertClearancesForDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return upsertClearancesForDriverRaw(requestParameters, requestConfig);
}

