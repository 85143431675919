// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShippingAgentListEntry
 */
export interface ShippingAgentListEntry  {
    /**
     * The id of this customer
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentListEntry
     */
    customerId: string;
}

export function ShippingAgentListEntryFromJSON(json: any): ShippingAgentListEntry {
    return {
        'id': json['id'],
        'name': json['name'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'customerName': json['customerName'],
        'customerId': json['customerId'],
    };
}

export function ShippingAgentListEntryToJSON(value?: ShippingAgentListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'town': value.town,
        'telephone': value.telephone,
        'customerName': value.customerName,
        'customerId': value.customerId,
    };
}


