// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    Vehicle,
    VehicleFromJSON,
    VehicleToJSON,
    VehicleCreationResponse,
    VehicleCreationResponseFromJSON,
    VehicleCreationResponseToJSON,
    VehicleCustomerList,
    VehicleCustomerListFromJSON,
    VehicleCustomerListToJSON,
    VehicleRequest,
    VehicleRequestFromJSON,
    VehicleRequestToJSON,
} from '../models';

export interface CreateVehicleRequest {
    vehicleRequest: VehicleRequest;
}

export interface DeleteVehicleRequest {
    vehicleId: string;
}

export interface GetVehicleRequest {
    vehicleId: string;
}

export interface ListVehiclesCustomerRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListVehiclesCustomerSortDirEnum;
}

export interface UpdateVehicleRequest {
    vehicle: Vehicle;
}


/**
 * Creates a new Vehicle (Fahrzeug)
 */
function createVehicleRaw<T>(requestParameters: CreateVehicleRequest, requestConfig: runtime.TypedQueryConfig<T, VehicleCreationResponse> = {}): QueryConfig<T> {
    if (requestParameters.vehicleRequest === null || requestParameters.vehicleRequest === undefined) {
        throw new runtime.RequiredError('vehicleRequest','Required parameter requestParameters.vehicleRequest was null or undefined when calling createVehicle.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/vehicle/create`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || VehicleRequestToJSON(requestParameters.vehicleRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(VehicleCreationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Creates a new Vehicle (Fahrzeug)
*/
export function createVehicle<T>(requestParameters: CreateVehicleRequest, requestConfig?: runtime.TypedQueryConfig<T, VehicleCreationResponse>): QueryConfig<T> {
    return createVehicleRaw(requestParameters, requestConfig);
}

/**
 * Deletes a vehicle
 */
function deleteVehicleRaw<T>(requestParameters: DeleteVehicleRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
        throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling deleteVehicle.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/vehicle/get/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes a vehicle
*/
export function deleteVehicle<T>(requestParameters: DeleteVehicleRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteVehicleRaw(requestParameters, requestConfig);
}

/**
 * Returns a drivers details.
 */
function getVehicleRaw<T>(requestParameters: GetVehicleRequest, requestConfig: runtime.TypedQueryConfig<T, Vehicle> = {}): QueryConfig<T> {
    if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
        throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling getVehicle.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/vehicle/get/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(VehicleFromJSON(body), text);
    }

    return config;
}

/**
* Returns a drivers details.
*/
export function getVehicle<T>(requestParameters: GetVehicleRequest, requestConfig?: runtime.TypedQueryConfig<T, Vehicle>): QueryConfig<T> {
    return getVehicleRaw(requestParameters, requestConfig);
}

/**
 * Lists all vehicles
 */
function listVehiclesCustomerRaw<T>(requestParameters: ListVehiclesCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, VehicleCustomerList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/vehicle/customer/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(VehicleCustomerListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all vehicles
*/
export function listVehiclesCustomer<T>(requestParameters: ListVehiclesCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, VehicleCustomerList>): QueryConfig<T> {
    return listVehiclesCustomerRaw(requestParameters, requestConfig);
}

/**
 * Update an existing Vehicle (Fahrzeug)
 */
function updateVehicleRaw<T>(requestParameters: UpdateVehicleRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
        throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling updateVehicle.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/vehicle/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || VehicleToJSON(requestParameters.vehicle),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update an existing Vehicle (Fahrzeug)
*/
export function updateVehicle<T>(requestParameters: UpdateVehicleRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateVehicleRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListVehiclesCustomerSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
