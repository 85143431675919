// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountPasswordUpdateRequest
 */
export interface AccountPasswordUpdateRequest  {
    /**
     * 
     * @type {string}
     * @memberof AccountPasswordUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPasswordUpdateRequest
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPasswordUpdateRequest
     */
    permanent: boolean;
}

export function AccountPasswordUpdateRequestFromJSON(json: any): AccountPasswordUpdateRequest {
    return {
        'id': json['id'],
        'password': json['password'],
        'permanent': json['permanent'],
    };
}

export function AccountPasswordUpdateRequestToJSON(value?: AccountPasswordUpdateRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'password': value.password,
        'permanent': value.permanent,
    };
}


