// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Driver,
    DriverFromJSON,
    DriverToJSON,
    DriverOperatorData,
    DriverOperatorDataFromJSON,
    DriverOperatorDataToJSON,
} from './';

/**
 * @type DriverFull
 * @export
 */
export interface DriverFull extends Driver, DriverOperatorData {
}

export function DriverFullFromJSON(json: any): DriverFull {
    return {
        ...DriverFromJSON(json),
        ...DriverOperatorDataFromJSON(json),
    };
}

export function DriverFullToJSON(value?: DriverFull): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DriverToJSON(value),
        ...DriverOperatorDataToJSON(value),
    };
}
