// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerAllOf,
    CustomerAllOfFromJSON,
    CustomerAllOfToJSON,
    DriverRequest,
    DriverRequestFromJSON,
    DriverRequestToJSON,
} from './';

/**
 * @type Driver
 * @export
 */
export interface Driver extends CustomerAllOf, DriverRequest {
}

export function DriverFromJSON(json: any): Driver {
    return {
        ...CustomerAllOfFromJSON(json),
        ...DriverRequestFromJSON(json),
    };
}

export function DriverToJSON(value?: Driver): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerAllOfToJSON(value),
        ...DriverRequestToJSON(value),
    };
}
