import React, { useRef, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { Link } from 'react-router-dom';

import {
  createShippingAgent,
  CustomerCreationResponse,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as stalenessActions from '../../staleness/actions';
import { connect } from 'react-redux';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import AddressContact, {
  AddressData,
} from '../../common/components/AddressContact';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { useTranslation } from 'react-i18next';
import CustomerSelect from '../../common/components/CustomerSelect';
import {
  shippingAgentFilterStoreKey,
  shippingAgentListStoreKey,
} from '../../../store/store-keys';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import formStyles from '../../../components/form/formStyles.module.scss';

const mapStateToProps = () => ({});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const AddShippingAgent: React.FC<Props> = ({ markListAsStale }) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [creationError, setCreationError] = useState<ActionPromiseValue | null>(
    null
  );
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<AddressData>({
    name: '',
    country: '',
    email: '',
    fax: '',
    language: '',
    mobile: '',
    street: '',
    telephone: '',
    town: '',
    zipCode: '',
  });

  const [
    { isPending: shippingAgentCreationPending },
    createShippingAgentHandle,
  ] = useMutation((addressData: AddressData) =>
    createShippingAgent<CustomerCreationResponse>({
      shippingAgentRequest: {
        name: addressData.name,
        customerId: customerId ?? '',
        email: addressData.email !== '' ? addressData.email : undefined,
        fax: addressData.fax !== '' ? addressData.fax : undefined,
        mobile: addressData.mobile !== '' ? addressData.mobile : undefined,
        street: addressData.street,
        telephone:
          addressData.telephone !== '' ? addressData.telephone : undefined,
        town: addressData.town,
        zipCode: addressData.zipCode,
      },
    })
  );

  if (shippingAgentCreationPending) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.shippingAgentList}
        linkText={t('shippingAgents')}
        heading={t('addShippingAgent')}
      />
      <div className={formStyles.section}>
        <h2>{t('assignedCustomer')}</h2>
        <CustomerSelect
          selectedCustomer={customerId}
          onCustomerSelected={customerId => setCustomerId(customerId)}
        />
      </div>
      <AddressContact
        addressData={addressData}
        setAddressData={setAddressData}
        excludeCountryAndLanguage={true}
        onValidation={setAddressDataValid}
      />
      <Link to={CustomerRouteMap.shippingAgentList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onSave={() => {
          markListAsStale(shippingAgentListStoreKey);
          markListAsStale(shippingAgentFilterStoreKey);
          createShippingAgentHandle(addressData)?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            } else {
              cancelLink?.current?.click();
            }
          });
        }}
        disableSave={
          customerId == null || !addressDataValid || addressData.name === ''
        }
        onCancel={() => cancelLink?.current?.click()}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(AddShippingAgent);
