import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import commonReducer from '../features/common/reducer';
import stalenessReducer from '../features/staleness/reducer';
import { entitiesReducer, errorsReducer, queriesReducer } from 'redux-query';
// import pasReducer from '../features/pas/reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    common: commonReducer,
    staleness: stalenessReducer,
    // pas: pasReducer,
    router: connectRouter(history),
    entities: entitiesReducer,
    queries: queriesReducer,
    errors: errorsReducer,
  });

export default createRootReducer;
