// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperatorAccountAllOfPlants
 */
export interface OperatorAccountAllOfPlants  {
    /**
     * 
     * @type {string}
     * @memberof OperatorAccountAllOfPlants
     */
    plantId: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorAccountAllOfPlants
     */
    plantName: string;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorAccountAllOfPlants
     */
    permission: boolean;
}

export function OperatorAccountAllOfPlantsFromJSON(json: any): OperatorAccountAllOfPlants {
    return {
        'plantId': json['plantId'],
        'plantName': json['plantName'],
        'permission': json['permission'],
    };
}

export function OperatorAccountAllOfPlantsToJSON(value?: OperatorAccountAllOfPlants): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plantId': value.plantId,
        'plantName': value.plantName,
        'permission': value.permission,
    };
}


