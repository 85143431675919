// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerFullListEntryAllOf,
    CustomerFullListEntryAllOfFromJSON,
    CustomerFullListEntryAllOfToJSON,
    CustomerFullListEntryAllOfClearances,
    CustomerFullListEntryAllOfClearancesFromJSON,
    CustomerFullListEntryAllOfClearancesToJSON,
    CustomerListEntry,
    CustomerListEntryFromJSON,
    CustomerListEntryToJSON,
} from './';

/**
 * @type CustomerFullListEntry
 * @export
 */
export interface CustomerFullListEntry extends CustomerFullListEntryAllOf, CustomerListEntry {
}

export function CustomerFullListEntryFromJSON(json: any): CustomerFullListEntry {
    return {
        ...CustomerFullListEntryAllOfFromJSON(json),
        ...CustomerListEntryFromJSON(json),
    };
}

export function CustomerFullListEntryToJSON(value?: CustomerFullListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerFullListEntryAllOfToJSON(value),
        ...CustomerListEntryToJSON(value),
    };
}
