// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverCreationResponse
 */
export interface DriverCreationResponse  {
    /**
     * 
     * @type {string}
     * @memberof DriverCreationResponse
     */
    id: string;
}

export function DriverCreationResponseFromJSON(json: any): DriverCreationResponse {
    return {
        'id': json['id'],
    };
}

export function DriverCreationResponseToJSON(value?: DriverCreationResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}


