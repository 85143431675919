import React from 'react';
import formStyles from '../../../components/form/formStyles.module.scss';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import {
  DriverClearance,
  getClearancesForDriver,
  listPlants,
  Plant,
} from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import Loading from '../../../components/Loading';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Check, HorizontalRule } from '@mui/icons-material';

interface Props {
  driverId: string;
}

const DriverClearances: React.FC<Props> = ({ driverId }) => {
  const { t } = useTranslation();

  const plantQueryConfig = listPlants({
    transform: body => {
      return { plants: body };
    },
    update: {
      plants: (oldValue: any, newValue: any) => {
        return newValue;
      },
    },
  });

  const clearanceQueryConfig = getClearancesForDriver(
    { driverId },
    {
      transform: body => {
        return { driverClearancesFor: { [driverId]: body } };
      },
      update: {
        driverClearancesFor: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
    }
  );

  const [{ isPending: plantsPending }] = useRequest(plantQueryConfig);
  let plantEntities = useSelector((state: any) => {
    return (state.entities.plants ?? []) as Plant[];
  });

  const [{ isPending: clearancesPending }] = useRequest(clearanceQueryConfig);
  let clearanceEntities = useSelector((state: any) => {
    return (state.entities.driverClearancesFor?.[driverId] ??
      []) as DriverClearance[];
  });

  if (plantsPending || clearancesPending) {
    return <Loading />;
  }

  const clearancesPerPlant: { [plantId: string]: DriverClearance } =
    Object.fromEntries(clearanceEntities.map(it => [it.plantId, it]));

  return (
    <div className={formStyles.outlined}>
      <div className={`${formStyles.list} ${formStyles.driver}`}>
        <div className={formStyles.listLabels}>
          <Label>{t('property')}</Label>
          <Label>{t('clearance')}</Label>
          <Label>{t('clearedFor24hEntry')}</Label>
          <Label>{t('clearedForEntryWithoutAdr')}</Label>
          <Label>{t('plantTraining')}</Label>
        </div>
        {plantEntities.map((plant, index) => (
          <div className={formStyles.listItem} key={`${driverId}-${plant.id}`}>
            <div>{plant.name}</div>
            <div>
              {!!clearancesPerPlant[plant.id]?.active ? (
                <Check color="success" />
              ) : (
                <HorizontalRule />
              )}
            </div>
            <div>
              {!!clearancesPerPlant[plant.id]?.access24h ? (
                <Check color="success" />
              ) : (
                <HorizontalRule />
              )}
            </div>
            <div>
              {!!clearancesPerPlant[plant.id]?.accessWithoutAdr ? (
                <Check color="success" />
              ) : (
                <HorizontalRule />
              )}
            </div>
            <div>
              {clearancesPerPlant[plant.id]?.plantTraining == null
                ? '-'
                : format(
                    clearancesPerPlant[plant.id].plantTraining!,
                    t('dateFormatDateOnly')
                  )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriverClearances;
