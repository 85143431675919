import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/form/PageHeader';
import PageContainer from '../../../components/form/PageContainer';
import formStyles from '../../../components/form/formStyles.module.scss';
import { Button } from '@mui/material';
import LabelValuePair from '../../../components/LabelValuePair';
import EditButtonRow from '../../../components/form/EditButtonRow';
import Spacer from '../../../components/Spacer';
import InvitationPopUp from '../components/InvitationPopUp';
import PasswordPopUp from '../components/PasswordPopUp';
import {
  CustomerListEntry,
  deleteAccount,
  getAccount,
  Plant,
  updatePermissionCollector,
  updatePermissionOperator,
  UserAccount,
  UserAccountRoleEnum,
} from '../../../generated/api/src';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useRequest } from 'redux-query-react';
import { connect, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import CollectorPermissionEdit from '../components/CollectorPermissionEdit';
import OperatorPermissionsEdit from '../components/OperatorPermissionEdit';
import * as stalenessActions from '../../staleness/actions';
import {
  accountScmListStoreKey,
  accountScmStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import DeletePopUp from '../../../components/form/DeletePopUp';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';

interface ComponentProps {
  returnLinkTarget: string;
}

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditAccount: React.FC<Props> = ({
  returnLinkTarget,
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const { id: userId } = useParams<{ id: string }>();

  const [invitationPopUp, setInvitationPopUp] = useState(false);
  const [passwordPopUp, setPasswordPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);

  const [collectorPermissions, setCollectorPermissions] = useState<
    CustomerListEntry[]
  >([]);

  const [operatorPermissions, setOperatorPermissions] = useState<Plant[]>([]);

  const returnLink = useRef<HTMLAnchorElement | null>(null);

  const accountQueryConfig = getAccount(
    { userId },
    {
      transform: body => {
        return { [accountScmStoreKey]: { [body.id]: body } };
      },
      update: {
        [accountScmStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(accountScmStoreKey, userId),
    }
  );

  const [{ isPending: accountPending }, forceUpdate] =
    useRequest(accountQueryConfig);
  let accountEntity = useSelector((state: any) => {
    return (state.entities?.[accountScmStoreKey]?.[userId] ??
      null) as UserAccount | null;
  });

  const [
    { isPending: collectorPermissionUpdatePending },
    updateCollectorPermissionsHandle,
  ] = useMutation(() =>
    updatePermissionCollector({
      userId: userId,
      requestBody: collectorPermissions.map(it => it.id),
    })
  );

  const [
    { isPending: operatorPermissionUpdatePending },
    updateOperatorPermissionsHandle,
  ] = useMutation(() =>
    updatePermissionOperator({
      userId: userId,
      requestBody: operatorPermissions.map(it => it.id),
    })
  );

  const deleteAccountHandle = useMutation(() =>
    deleteAccount({
      userId,
    })
  )[1];

  if (
    accountPending ||
    accountEntity == null ||
    collectorPermissionUpdatePending ||
    operatorPermissionUpdatePending
  ) {
    return <Loading />;
  }

  let roleContent = <></>;
  switch (accountEntity.role) {
    case UserAccountRoleEnum.Collector:
      roleContent = (
        <>
          <h2>{t('assignedCollectors')}</h2>
          <CollectorPermissionEdit
            userId={userId}
            selectedCustomers={collectorPermissions}
            setSelectedCustomers={setCollectorPermissions}
          />
        </>
      );
      break;
    case UserAccountRoleEnum.Operator:
    case UserAccountRoleEnum.OperatorMaster:
      roleContent = (
        <>
          <h2>{t('assignedPlants')}</h2>
          <OperatorPermissionsEdit
            userId={userId}
            selectedPlants={operatorPermissions}
            setSelectedPlants={setOperatorPermissions}
          />
        </>
      );
      break;
  }

  const invalidateEntity = () => {
    markListAsStale(accountScmListStoreKey);
    // markListAsStale(accountOperatorListStoreKey);
    markEntityAsStale([accountScmStoreKey, [userId]]);
  };

  return (
    <PageContainer>
      <PageHeader
        linkUrl={returnLinkTarget}
        linkText={t('accounts')}
        heading={t('editAccount')}
      />
      <div className={formStyles.section}>
        <h2>{t('accountData')}</h2>
        <div className={formStyles.values}>
          <LabelValuePair label={t('email')} value={accountEntity.email} />
          <LabelValuePair
            label={t('role')}
            value={
              accountEntity.role === 'Operator'
                ? t('operator')
                : accountEntity.role === 'OperatorMaster'
                ? t('operatorMaster')
                : accountEntity.role === 'Scm'
                ? t('scm')
                : accountEntity.role === 'Collector'
                ? t('collector')
                : accountEntity.role === 'Admin'
                ? t('admin')
                : t('noRole')
            }
          />
        </div>
      </div>

      {roleContent}

      <Spacer />

      <div className={formStyles.buttonRow}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            setInvitationPopUp(true);
            setPasswordPopUp(false);
          }}
        >
          {t('sendInvitation')}
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            setPasswordPopUp(true);
            setInvitationPopUp(false);
          }}
        >
          {t('generatePassword')}
        </Button>
      </div>

      <Spacer />

      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onSave={() => {
          invalidateEntity();
          if (accountEntity?.role === UserAccountRoleEnum.Collector) {
            updateCollectorPermissionsHandle()?.then(resp => {
              if (resp.status > 299) {
                setCreationError(resp);
              }
              return forceUpdate();
            });
          }
          if (
            accountEntity?.role === UserAccountRoleEnum.Operator ||
            accountEntity?.role === UserAccountRoleEnum.OperatorMaster
          ) {
            updateOperatorPermissionsHandle()?.then(resp => {
              if (resp.status > 299) {
                setCreationError(resp);
              }
              return forceUpdate();
            });
          }
        }}
        onCancel={() => returnLink?.current?.click()}
        onDelete={() => setDeletePopUp(true)}
        allowDelete={true}
      />

      {invitationPopUp && (
        <InvitationPopUp
          email={accountEntity.email}
          setInvitationPopUp={setInvitationPopUp}
          userId={userId}
        />
      )}
      {passwordPopUp && (
        <PasswordPopUp setPasswordPopUp={setPasswordPopUp} userId={userId} />
      )}
      {deletePopUp && (
        <DeletePopUp
          setDeletePopUp={setDeletePopUp}
          onDelete={() => {
            invalidateEntity();
            deleteAccountHandle()?.then(() => returnLink?.current?.click());
          }}
        />
      )}
      <Link to={returnLinkTarget} ref={returnLink} />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditAccount);
