// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleCustomerListEntry
 */
export interface VehicleCustomerListEntry  {
    /**
     * 
     * @type {string}
     * @memberof VehicleCustomerListEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleCustomerListEntry
     */
    licencePlateTruck: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleCustomerListEntry
     */
    ggvsB3Truck?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleCustomerListEntry
     */
    licencePlateTrailer: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleCustomerListEntry
     */
    ggvsB3Trailer?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleCustomerListEntry
     */
    medOk?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleCustomerListEntry
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleCustomerListEntry
     */
    shippingAgentName?: string;
}

export function VehicleCustomerListEntryFromJSON(json: any): VehicleCustomerListEntry {
    return {
        'id': json['id'],
        'licencePlateTruck': json['licencePlateTruck'],
        'ggvsB3Truck': !exists(json, 'ggvsB3Truck') ? undefined : new Date(new Date(json['ggvsB3Truck']).getTime()+ new Date(json['ggvsB3Truck']).getTimezoneOffset()*60*1000),
        'licencePlateTrailer': json['licencePlateTrailer'],
        'ggvsB3Trailer': !exists(json, 'ggvsB3Trailer') ? undefined : new Date(new Date(json['ggvsB3Trailer']).getTime()+ new Date(json['ggvsB3Trailer']).getTimezoneOffset()*60*1000),
        'medOk': !exists(json, 'medOk') ? undefined : json['medOk'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'shippingAgentName': !exists(json, 'shippingAgentName') ? undefined : json['shippingAgentName'],
    };
}

export function VehicleCustomerListEntryToJSON(value?: VehicleCustomerListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'licencePlateTruck': value.licencePlateTruck,
        'ggvsB3Truck': value.ggvsB3Truck === undefined ? undefined : new Date(value.ggvsB3Truck.getTime() - value.ggvsB3Truck.getTimezoneOffset()*60*1000).toISOString(),
        'licencePlateTrailer': value.licencePlateTrailer,
        'ggvsB3Trailer': value.ggvsB3Trailer === undefined ? undefined : new Date(value.ggvsB3Trailer.getTime() - value.ggvsB3Trailer.getTimezoneOffset()*60*1000).toISOString(),
        'medOk': value.medOk,
        'productName': value.productName,
        'shippingAgentName': value.shippingAgentName,
    };
}


