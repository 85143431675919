// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAccount
 */
export interface UserAccount  {
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    role?: UserAccountRoleEnum;
}

export function UserAccountFromJSON(json: any): UserAccount {
    return {
        'email': json['email'],
        'id': json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function UserAccountToJSON(value?: UserAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'id': value.id,
        'role': value.role,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserAccountRoleEnum {
    Operator = 'Operator',
    OperatorMaster = 'OperatorMaster',
    Scm = 'Scm',
    Collector = 'Collector',
    Admin = 'Admin'
}


