import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';
import { TextField } from '@mui/material';
import ShippingAgentSelect from '../components/ShippingAgentSelect';
import * as stalenessActions from '../../staleness/actions';
import { connect } from 'react-redux';
import EditButtonRow from '../../../components/form/EditButtonRow';
import PageHeader from '../../../components/form/PageHeader';
import PageContainer from '../../../components/form/PageContainer';
import { Link } from 'react-router-dom';
import { useMutation } from 'redux-query-react';
import {
  createDriver,
  DriverCreationResponse,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import { DatePicker } from '@mui/lab';
import { driverCustomerListStoreKey } from '../../../store/store-keys';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import LanguageSelect from '../../common/components/LanguageSelect';
import { ValidationRegex } from '../../common/components/ValidationRegex';
import { sanitizeDateFromDatePicker } from '../../../util/SanitizeDateFromDatePickerHelper';

const mapStateToProps = () => ({});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const AddDriver: React.FC<Props> = ({ markListAsStale }) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] = useState<ActionPromiseValue | null>(
    null
  );

  const [forename, setForename] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [adrExpiration, setAdrExpiration] = useState<Date>(
    new Date(Date.now())
  );
  const [shippingAgentId, setShippingAgentId] = useState<string | null>(null);
  const [language, setLanguage] = useState<string>('');

  const forenameOk = forename.match(ValidationRegex.Driver.Forename) != null;
  const nameOk = name.match(ValidationRegex.Driver.Name) != null;
  const adExpirationOk =
    !isNaN(adrExpiration.getTime()) && adrExpiration.getFullYear() > 2000;

  const formOk =
    [forenameOk, nameOk, adExpirationOk].every(it => it) && language !== '';

  const [{ isPending: driverCreationPending }, createDriverHandle] =
    useMutation(() =>
      createDriver<DriverCreationResponse>({
        driverRequest: {
          name,
          forename,
          adrExpiration,
          // @ts-ignore
          shippingAgentId,
          language,
        },
      })
    );

  if (driverCreationPending) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.driverList}
        linkText={t('drivers')}
        heading={t('addDriver')}
      />
      <div className={formStyles.section}>
        <h2>{t('personalData')}</h2>
        <div className={`${formStyles.inputContainer} ${formStyles.joined}`}>
          <TextField
            label={t('name')}
            className={`${formStyles.input} ${formStyles.left}`}
            value={name}
            onChange={event => setName(event.target.value)}
            error={!nameOk && name !== ''}
            required
          />
          <TextField
            label={t('forename')}
            className={`${formStyles.input} ${formStyles.right}`}
            value={forename}
            onChange={event => setForename(event.target.value)}
            error={!forenameOk && forename !== ''}
            required
          />
        </div>

        <div className={formStyles.inputContainer}>
          <DatePicker
            label={t('adrExpiration')}
            value={adrExpiration}
            onChange={newValue =>
              setAdrExpiration(sanitizeDateFromDatePicker(newValue))
            }
            renderInput={params => (
              <TextField {...params} className={formStyles.picker} />
            )}
            inputFormat={t('dateFormatDateOnly')}
            mask={t('dateFormatDateMask')}
          />
        </div>

        <ShippingAgentSelect
          selectedAgent={shippingAgentId}
          onAgentSelected={agentId => setShippingAgentId(agentId)}
        />

        <LanguageSelect
          onLanguageSelected={languageId => setLanguage(languageId ?? '')}
          selectedLanguage={language}
          required={true}
        />
      </div>

      <Link to={CustomerRouteMap.driverList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onCancel={() => cancelLink?.current?.click()}
        onSave={() => {
          markListAsStale(driverCustomerListStoreKey);
          createDriverHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            } else {
              cancelLink?.current?.click();
            }
          });
        }}
        disableSave={!formOk}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(AddDriver);
