import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce, ListingParams } from '../../../components/list/List';
import { useRequest } from 'redux-query-react';
import {
  ListCustomersSortDirEnum,
  TypedQueryConfig,
} from '../../../generated/api/src';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QueryConfig } from 'redux-query';
import * as stalenessSelectors from '../../staleness/selectors';
import * as stalenessActions from '../../staleness/actions';
import { RootState } from 'typesafe-actions';

interface ComponentProps {
  /**
   * The store key, unter which to store the search results for this multi select
   */
  entitySearchResultStoreKey: string;

  /**
   * The entities, which are currently selected
   */
  selectedEntities: any[];
  onSelectedEntitiesChanges: (entities: any[] | any) => void;
  multiSelect: boolean;

  queryBuilder: (
    requestParameters: ListingParams,
    requestConfig?: TypedQueryConfig</*{[key: string]:T}*/ any, any>
  ) => QueryConfig<any>;

  getOptionLabel: (it: any) => string;
  additionalOption?: any;
  disabled?: boolean;
  loading?: boolean;
  displayLabel?: string;
  additionalFilter?: string[];
  className?: string;
  maxEntries?: number;
}

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsFresh: stalenessActions.markAsFresh,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const TypeaheadMultiEntitySelect: React.FC<Props> = ({
  entityIsStale,
  markListAsFresh,
  entitySearchResultStoreKey,
  onSelectedEntitiesChanges,
  selectedEntities,
  queryBuilder,
  getOptionLabel,
  multiSelect,
  additionalOption,
  disabled,
  loading,
  displayLabel,
  additionalFilter,
  className,
  maxEntries,
}) => {
  const { t } = useTranslation();
  const debounceTimerRef = useRef<null | NodeJS.Timer>(null);
  const [entitySearch, setEntitySearch] = useState<string>('');

  const queryKey = `${entitySearchResultStoreKey}-${
    additionalFilter != null ? `${additionalFilter}-` : ''
  }${entitySearch.length > 0 ? entitySearch : 'default'}`;
  const [{ isPending: requestPending, lastUpdated: lastUpdatedTs }] =
    useRequest(
      queryBuilder(
        {
          offset: 0,
          limit: maxEntries ?? 20,
          sortDir: ListCustomersSortDirEnum.Asc,
          sort: 'name',
          search:
            entitySearch !== ''
              ? [encodeURIComponent(`name=${entitySearch}`)]
              : undefined,
          filter: additionalFilter,
        },
        {
          transform: body => {
            return {
              [entitySearchResultStoreKey]: {
                [queryKey]: body?.entities ?? body,
              },
            };
          },
          update: {
            [entitySearchResultStoreKey]: (oldValue: any, newValue: any) => {
              return { ...oldValue, ...newValue };
            },
          },
          force: entityIsStale(entitySearchResultStoreKey, queryKey),
          queryKey,
        }
      )
    );

  useEffect(() => {
    markListAsFresh([entitySearchResultStoreKey, [queryKey]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitySearchResultStoreKey, lastUpdatedTs]);

  let availableEntities = useSelector((state: any) => {
    return state.entities?.[entitySearchResultStoreKey]?.[queryKey] ?? [];
  }) as any[];

  return (
    <Autocomplete
      key={`${entitySearchResultStoreKey}-autocomplete`}
      options={
        additionalOption !== undefined
          ? [additionalOption, ...availableEntities]
          : availableEntities
      }
      multiple={multiSelect}
      filterSelectedOptions
      loading={requestPending || !!loading}
      filterOptions={x => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      disableClearable={!multiSelect}
      onChange={(_, value) => {
        onSelectedEntitiesChanges(value);
        setEntitySearch('');
      }}
      disabled={disabled != null && disabled}
      value={selectedEntities}
      renderInput={params => (
        <TextField
          {...params}
          label={displayLabel}
          className={className}
          placeholder={t('search')}
          onChange={event =>
            debounce(
              () => {
                setEntitySearch(event.target.value ?? '');
              },
              debounceTimerRef,
              500
            )()
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {requestPending ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(TypeaheadMultiEntitySelect);
