import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import formStyles from '../../../components/form/formStyles.module.scss';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import {
  CustomerClearanceRequest,
  listPlants,
  Plant,
  UpsertCustomerClearanceRequest,
} from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import Loading from '../../../components/Loading';
import { useSelector } from 'react-redux';
import { ValidationRegex } from '../../common/components/ValidationRegex';

export interface ClearanceData {
  [plantId: string]: CustomerClearanceRequest;
}

export interface GlobaleClearanceData {
  maxWeight: number;
  active: boolean;
}

export function clearanceToClearanceReq(
  clearances: ClearanceData,
  customerId: string
): UpsertCustomerClearanceRequest {
  return {
    customerId: customerId,
    customerClearanceRequest: Object.entries(clearances).map(
      ([plantId, it]): CustomerClearanceRequest => {
        return {
          customerId,
          plantId,
          loadingClearance: it.loadingClearance ?? false,
          accessOnly: it.accessOnly ?? false,
          sapNumber: it.sapNumber ?? '',
        };
      }
    ),
  };
}

interface Props {
  globalClearanceData: GlobaleClearanceData;
  setGlobalClearanceData: React.Dispatch<
    React.SetStateAction<GlobaleClearanceData>
  >;

  clearanceData: ClearanceData;
  setClearanceData: React.Dispatch<React.SetStateAction<ClearanceData>>;

  onValidation?: (valid: boolean) => void;
}

const CollectorClearances: React.FC<Props> = ({
  clearanceData,
  setClearanceData,
  globalClearanceData,
  setGlobalClearanceData,
  onValidation,
}) => {
  const { t } = useTranslation();

  const validSapNumbers = Object.fromEntries(
    Object.entries(clearanceData).map(([key, val]) => [
      key,
      (val.sapNumber ?? '').match(
        ValidationRegex.CustomerClearance.SapNumber
      ) != null,
    ])
  );
  const formValid = Object.values(validSapNumbers).every(it => it);

  useEffect(() => {
    if (!!onValidation) {
      onValidation(formValid);
    }
  }, [formValid, onValidation]);

  const plantQueryConfig = listPlants({
    transform: body => {
      return { plants: body };
    },
    update: {
      plants: (oldValue: any, newValue: any) => {
        return newValue;
      },
    },
  });

  const [{ isPending: plantsPending }] = useRequest(plantQueryConfig);
  let plantEntities = useSelector((state: any) => {
    return (state.entities.plants ?? []) as Plant[];
  });

  if (plantsPending) {
    return <Loading />;
  }

  const updateClearanceDataFor = (
    plantId: string,
    key: keyof CustomerClearanceRequest,
    value: string | boolean
  ) => {
    setClearanceData({
      ...clearanceData,
      [plantId]: { ...(clearanceData[plantId] ?? {}), [key]: value },
    });
  };

  return (
    <>
      <h2>{t('clearances')}</h2>
      <div className={formStyles.section}>
        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          <FormControl>
            <InputLabel
              htmlFor="weight-input"
              className={formStyles.specialLabel}
            >
              {t('maxWeight')}
            </InputLabel>
            <OutlinedInput
              id="weight-input"
              label={t('maxWeight')}
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <span className={formStyles.kg}>kg</span>
                </InputAdornment>
              }
              onChange={event => {
                setGlobalClearanceData({
                  ...globalClearanceData,
                  maxWeight:
                    Number(event.target.value) > 0
                      ? Number(event.target.value)
                      : 0,
                });
              }}
              value={globalClearanceData.maxWeight}
              inputProps={{ min: 0 }}
              className={formStyles.input}
            />
          </FormControl>
          <FormControl>
            <div
              className={formStyles.outlined}
              style={{ paddingLeft: '14px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    onChange={event =>
                      setGlobalClearanceData({
                        ...globalClearanceData,
                        active: event.target.checked ?? false,
                      })
                    }
                  />
                }
                label={t('clearanceAll')}
                checked={globalClearanceData.active}
              />
            </div>
          </FormControl>
        </div>
        <div className={formStyles.outlined}>
          <div className={`${formStyles.list} ${formStyles.collector}`}>
            <div className={formStyles.listLabels}>
              <Label>{t('property')}</Label>
              <Label>{t('clearanceLoading')}</Label>
              <Label>{t('clearanceEntry')}</Label>
              <Label>{t('sapNumber')}</Label>
            </div>
            {plantEntities.map((plant, index) => (
              <div key={index}>
                <div className={formStyles.listItem}>
                  <div>{plant.name}</div>
                  <div>
                    <Checkbox
                      color="success"
                      onChange={event =>
                        updateClearanceDataFor(
                          plant.id,
                          'loadingClearance',
                          event.target.checked ?? false
                        )
                      }
                      checked={
                        clearanceData[plant.id]?.loadingClearance ?? false
                      }
                    />
                  </div>
                  <div>
                    <Checkbox
                      color="success"
                      onChange={event =>
                        updateClearanceDataFor(
                          plant.id,
                          'accessOnly',
                          event.target.checked ?? false
                        )
                      }
                      checked={clearanceData[plant.id]?.accessOnly ?? false}
                    />
                  </div>
                  <TextField
                    placeholder={t('sapNumber')}
                    className={formStyles.input}
                    onChange={event =>
                      updateClearanceDataFor(
                        plant.id,
                        'sapNumber',
                        event.target.value
                      )
                    }
                    error={!(validSapNumbers[plant.id] ?? true)}
                    value={clearanceData[plant.id]?.sapNumber ?? ''}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectorClearances;
