import React from 'react';
import { listAccountsScm, UserAccount } from '../../../generated/api/src';
import List from '../../../components/list/List';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import { accountScmListStoreKey } from '../../../store/store-keys';
import { RootRouteMap } from '../../../routes/root-route-map';

const AccountList: React.FC = () => {
  const { t } = useTranslation();

  const addAccountBtn = (
    <Link to={`${RootRouteMap.scm}add-account/`}>
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addAccount')}
      </Button>
    </Link>
  );

  return (
    <List
      hasPagination={true}
      hasSearchBar={true}
      hasSorting={false}
      addEntitySlot={addAccountBtn}
      editEntityLink={`${RootRouteMap.scm}edit-account`}
      columnLabels={[
        { prop: 'email', label: t('email') },
        { prop: 'role', label: t('role') },
      ]}
      columnLabelGrid="2.5fr 1.5fr"
      rowValueTransform={(obj: UserAccount) => [obj.email, obj.role ?? '-']}
      entityKey={accountScmListStoreKey}
      // @ts-ignore
      queryBuilder={listAccountsScm}
    />
  );
};

export default AccountList;
