import { Button, Snackbar } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PopUpStyles.module.scss';
import Loading from '../Loading';

interface Props {
  setDeletePopUp: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
}

const DeletePopUp: React.FC<Props> = ({ setDeletePopUp, onDelete }) => {
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeletePopUp(false);
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        {!loading ? (
          <>
            <h3 className={styles.heading}>{t('deleteEntry')}</h3>
            <div className={styles.buttons}>
              <Button color="secondary" onClick={() => setDeletePopUp(false)}>
                {t('cancel')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                style={{ marginLeft: '1rem' }}
                onClick={() => {
                  setLoading(true);
                  setDeleted(true);
                  onDelete();
                }}
              >
                {t('delete')}
              </Button>
            </div>
          </>
        ) : (
          <Loading />
        )}
        <Snackbar
          open={deleted}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={t('entryDeleted')}
        />
      </div>
    </div>
  );
};

export default DeletePopUp;
