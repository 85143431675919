export const collectorScmListStoreKey = 'listCollectorsScm';
export const accountScmListStoreKey = 'listAccountScm';
export const customerScmStoreKey = 'customerFull';
export const customerClearanceScmStoreKey = 'customerClearancesFor';
export const accountScmStoreKey = 'account';

export const shippingAgentListStoreKey = 'listShippingAgents';
export const driverCustomerListStoreKey = 'listDriversForCustomer';
export const vehicleCustomerListStoreKey = 'listVehiclesForCustomer';
export const customerCustomerViewListStoreKey = 'listCustomersCustomer';

export const driverOperatorListStoreKey = 'driverOperatorViewList';
export const vehicleOperatorListStoreKey = 'driverOperatorViewList';
export const driverOperatorStoreKey = 'driverOperator';
export const driverClearancesForDriverStoreKey = 'driverClearancesFor';
export const vehicleOperatorStoreKey = 'vehicleOperator';
export const accountOperatorListStoreKey = accountScmListStoreKey;

export const vehicleCustomerStoreKey = 'vehicle';
export const shippingAgentCustomerStoreKey = 'shippingAgent';
export const shippingAgentCustomerMaxWeightStoreKey = 'shippingAgentMaxWeight';
export const driverCustomerStoreKey = 'driver';
export const customerCustomerStoreKey = 'customer';

export const shippingAgentFilterStoreKey = 'shippingAgentFilterList';
export const shippingAgentSelectStoreKey = 'shippingAgentSelect';

export const customerFilterStoreKey = 'customerFilterList';
export const customerSelectStoreKey = 'customerSelect';
