import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import formStyles from '../../../components/form/formStyles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  maxWeight: number;
  weight: number;
  onSetWeight: (weight: number) => void;
  disabled?: boolean;
  onWeightError?: (error: boolean) => void;
}

const VehicleMaxWeightInput: React.FC<Props> = ({
  maxWeight,
  onSetWeight,
  weight,
  disabled,
  onWeightError,
}) => {
  const { t } = useTranslation();

  const hasError = weight > maxWeight && !disabled;
  return (
    <FormControl>
      <InputLabel
        htmlFor="weight-input"
        className={formStyles.specialLabel}
        error={hasError}
      >
        {t('totalWeight')}
      </InputLabel>
      <OutlinedInput
        id="weight-input"
        label={t('totalWeight')}
        type="number"
        endAdornment={
          <InputAdornment position="end">
            <span className={formStyles.kg}>kg</span>
          </InputAdornment>
        }
        onChange={event => {
          onSetWeight(Number(event.target.value));
          !!onWeightError && onWeightError(hasError);
        }}
        value={weight}
        inputProps={{ min: 0 }}
        className={formStyles.input}
        error={hasError}
        disabled={!!disabled}
      />
      <FormHelperText>
        <Trans i18nKey="maxWeightHelperText">
          Das Gewicht darf die maximal zugelassenen {{ maxWeight }}kg nicht
          überschreiten.
        </Trans>
      </FormHelperText>
    </FormControl>
  );
};

export default VehicleMaxWeightInput;
