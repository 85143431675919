import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import ShippingAgentSelect from '../components/ShippingAgentSelect';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import EditButtonRow from '../../../components/form/EditButtonRow';
import PageHeader from '../../../components/form/PageHeader';
import PageContainer from '../../../components/form/PageContainer';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useRequest } from 'redux-query-react';
import {
  deleteVehicle,
  getVehicle,
  updateVehicle,
  Vehicle,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import ProductSelect from '../components/ProductSelect';
import { DatePicker } from '@mui/lab';
import {
  vehicleCustomerListStoreKey,
  vehicleCustomerStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import DeletePopUp from '../../../components/form/DeletePopUp';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import { ValidationRegex } from '../../common/components/ValidationRegex';
import VehicleMaxWeightPlaceholder from '../components/VehicleMaxWeightPlaceholder';
import VehicleMaxWeightEdit from '../components/VehicleMaxWeightEdit';
import { sanitizeDateFromDatePicker } from '../../../util/SanitizeDateFromDatePickerHelper';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditVehicle: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const { id: vehicleId } = useParams<{ id: string }>();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);

  const [licencePlateTruck, setLicencePlateTruck] = useState<string>('');
  const [licencePlateTrailer, setLicencePlateTrailer] = useState<string>('');
  const [adrTruck, setAdrTruck] = useState<Date>(new Date());
  const [adrTrailer, setAdrTrailer] = useState<Date>(new Date());
  const [shippingAgentId, setShippingAgentId] = useState<string | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const [weight, setWeight] = useState<number>(0);
  const [medOk, setMedOk] = useState<boolean>(false);

  const [weightError, setWeightError] = useState<boolean>(false);

  const shippingAgentOk = shippingAgentId != null;
  const licencePlateTruckOk =
    licencePlateTruck.match(ValidationRegex.Vehicle.LicencePlateTruck) != null;
  const licencePlateTrailerOk =
    licencePlateTrailer.match(ValidationRegex.Vehicle.LicencePlateTrailer) !=
    null;
  const adrTruckOk =
    !isNaN(adrTruck.getTime()) && adrTruck.getFullYear() > 2000;
  const adrTrailerOk =
    !isNaN(adrTrailer.getTime()) && adrTrailer.getFullYear() > 2000;

  const deleteVehicleHandle = useMutation(() =>
    deleteVehicle({
      vehicleId: vehicleId,
    })
  )[1];

  const [{ isPending: vehicleUpdatePending }, updateVehicleHandle] =
    useMutation(() =>
      updateVehicle({
        vehicle: {
          id: vehicleId,
          shippingAgentId: shippingAgentId!,
          product: productId!,
          licencePlateTruck,
          licencePlateTrailer,
          ggvsB3Truck: adrTruck,
          ggvsB3Trailer: adrTrailer,
          medOk,
          weight,
        },
      })
    );

  const vehicleQueryConfig = getVehicle(
    { vehicleId },
    {
      transform: body => {
        return { [vehicleCustomerStoreKey]: { [body.id]: body } };
      },
      update: {
        [vehicleCustomerStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(vehicleCustomerStoreKey, vehicleId),
    }
  );

  const [{ isPending: vehiclePending, lastUpdated: vehicleUpdateTs }] =
    useRequest(vehicleQueryConfig);

  let vehicleEntity = useSelector((state: any) => {
    return (state.entities?.[vehicleCustomerStoreKey]?.[vehicleId] ??
      null) as Vehicle | null;
  });

  useEffect(() => {
    setShippingAgentId(vehicleEntity?.shippingAgentId ?? null);
    setProductId(vehicleEntity?.product ?? null);
    setWeight(vehicleEntity?.weight ?? 0);
    setAdrTrailer(
      vehicleEntity?.ggvsB3Trailer
        ? vehicleEntity.ggvsB3Trailer
        : new Date(Date.now())
    );
    setAdrTruck(
      vehicleEntity?.ggvsB3Truck
        ? vehicleEntity.ggvsB3Truck
        : new Date(Date.now())
    );
    setLicencePlateTrailer(vehicleEntity?.licencePlateTrailer ?? '');
    setLicencePlateTruck(vehicleEntity?.licencePlateTruck ?? '');
    setMedOk(vehicleEntity?.medOk ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleUpdateTs]);

  if (vehicleUpdatePending || vehiclePending || vehicleEntity === null) {
    return <Loading />;
  }

  const invalidateEntity = () => {
    markListAsStale(vehicleCustomerListStoreKey);
    markEntityAsStale([vehicleCustomerStoreKey, [vehicleId]]);
  };

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.vehicleList}
        linkText={t('vehicles')}
        heading={t('editVehicle')}
      />
      <div className={formStyles.section}>
        <h2>{t('vehicleInfo')}</h2>
        <div className={`${formStyles.inputContainer} ${formStyles.joined}`}>
          <TextField
            label={t('licencePlateTruck')}
            className={`${formStyles.input} ${formStyles.left}`}
            value={licencePlateTruck}
            onChange={event => setLicencePlateTruck(event.target.value)}
            error={!licencePlateTruckOk && licencePlateTruck !== ''}
            required
          />
          <DatePicker
            label={t('adrExpirationTruck')}
            value={adrTruck}
            onChange={newValue =>
              setAdrTruck(sanitizeDateFromDatePicker(newValue))
            }
            renderInput={params => (
              <TextField
                {...params}
                className={`${formStyles.picker} ${formStyles.right}`}
              />
            )}
            inputFormat={t('dateFormatDateOnly')}
            mask={t('dateFormatDateMask')}
          />
        </div>

        <div className={`${formStyles.inputContainer} ${formStyles.joined}`}>
          <TextField
            label={t('licencePlateTrailer')}
            className={`${formStyles.input} ${formStyles.left}`}
            value={licencePlateTrailer}
            onChange={event => setLicencePlateTrailer(event.target.value)}
            error={!licencePlateTrailerOk && licencePlateTrailer !== ''}
            required
          />
          <DatePicker
            label={t('adrExpirationTrailer')}
            value={adrTrailer}
            onChange={newValue =>
              setAdrTrailer(sanitizeDateFromDatePicker(newValue))
            }
            renderInput={params => (
              <TextField
                {...params}
                className={`${formStyles.picker} ${formStyles.right}`}
              />
            )}
            inputFormat={t('dateFormatDateOnly')}
            mask={t('dateFormatDateMask')}
          />
        </div>

        <div className={formStyles.inputContainer}>
          <ShippingAgentSelect
            selectedAgent={shippingAgentId}
            onAgentSelected={agentId => setShippingAgentId(agentId)}
          />
        </div>

        <div className={formStyles.inputContainer}>
          <ProductSelect
            defaultOptionText={t('selectProduct')}
            selectedProduct={productId}
            onProductSelected={productId => setProductId(productId)}
          />
        </div>

        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          {shippingAgentId == null ? (
            <VehicleMaxWeightPlaceholder weight={weight} />
          ) : (
            <VehicleMaxWeightEdit
              onSetWeight={setWeight}
              weight={weight}
              shippingAgentId={shippingAgentId}
              onWeightError={setWeightError}
            />
          )}

          <FormControl>
            <div
              className={formStyles.outlined}
              style={{ paddingLeft: '14px', height: '54px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    onChange={event => setMedOk(event.target.checked)}
                  />
                }
                label={t('medicalClearance')}
                checked={medOk}
              />
            </div>
          </FormControl>
        </div>
      </div>
      <Link to={CustomerRouteMap.vehicleList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onCancel={() => cancelLink?.current?.click()}
        disableSave={
          !(
            licencePlateTruckOk &&
            licencePlateTrailerOk &&
            !weightError &&
            adrTruckOk &&
            shippingAgentOk &&
            adrTrailerOk
          )
        }
        onSave={() => {
          invalidateEntity();
          updateVehicleHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
        onDelete={() => setShowDeletePopup(true)}
        allowDelete={true}
      />
      {showDeletePopup && (
        <DeletePopUp
          setDeletePopUp={setShowDeletePopup}
          onDelete={() => {
            invalidateEntity();
            deleteVehicleHandle()?.then(() => cancelLink?.current?.click());
          }}
        />
      )}
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditVehicle);
