import React from 'react';
import Loading from '../../../components/Loading';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { ListLanguageQuery, ListLanguageSelector } from './LanguageSelect';

interface Props {
  languageId: string;
}

const LanguageDisplay: React.FC<Props> = ({ languageId }) => {
  const [{ isPending }] = useRequest(ListLanguageQuery);
  let listData = useSelector(ListLanguageSelector);

  if (isPending) {
    return <Loading />;
  }

  const languageName = listData.find(it => it.id === languageId)?.name ?? '-';
  return <div>{languageName}</div>;
};

export default LanguageDisplay;
