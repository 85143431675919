// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverClearanceRequest
 */
export interface DriverClearanceRequest  {
    /**
     * 
     * @type {string}
     * @memberof DriverClearanceRequest
     */
    driverId: string;
    /**
     * 
     * @type {string}
     * @memberof DriverClearanceRequest
     */
    plantId: string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverClearanceRequest
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DriverClearanceRequest
     */
    accessWithoutAdr?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DriverClearanceRequest
     */
    access24h?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DriverClearanceRequest
     */
    plantTraining?: Date|null;
}

export function DriverClearanceRequestFromJSON(json: any): DriverClearanceRequest {
    return {
        'driverId': json['driverId'],
        'plantId': json['plantId'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'accessWithoutAdr': !exists(json, 'accessWithoutAdr') ? undefined : json['accessWithoutAdr'],
        'access24h': !exists(json, 'access24h') ? undefined : json['access24h'],
        'plantTraining': !exists(json, 'plantTraining') ? undefined : new Date(new Date(json['plantTraining']).getTime()+ new Date(json['plantTraining']).getTimezoneOffset()*60*1000),
    };
}

export function DriverClearanceRequestToJSON(value?: DriverClearanceRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'driverId': value.driverId,
        'plantId': value.plantId,
        'active': value.active,
        'accessWithoutAdr': value.accessWithoutAdr,
        'access24h': value.access24h,
        'plantTraining': value.plantTraining === undefined ? undefined : value.plantTraining == null ? null : new Date(value.plantTraining.getTime() - value.plantTraining.getTimezoneOffset()*60*1000).toISOString(),
    };
}


