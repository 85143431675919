import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import formStyles from '../../../components/form/formStyles.module.scss';
import { useRequest } from 'redux-query-react';
import { listPlants, Plant } from '../../../generated/api/src';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedEntities: any[];
  onSelectedEntitiesChanges: (entities: any[]) => void;
}

const PlantMultiSelect: React.FC<Props> = ({
  onSelectedEntitiesChanges,
  selectedEntities,
}) => {
  const { t } = useTranslation();
  const [{ isPending: requestPending }] = useRequest(
    listPlants({
      transform: body => {
        return { plants: body };
      },
      update: {
        plants: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
    })
  );

  let availableEntities = useSelector((state: any) => {
    return state.entities?.plants ?? [];
  }) as any[];

  return (
    <Autocomplete
      options={Object.values(availableEntities)}
      multiple
      filterSelectedOptions
      loading={requestPending}
      // filterOptions={x => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: Plant) => `${option.name} (${option.id})`}
      onChange={(_, value) => onSelectedEntitiesChanges(value)}
      value={selectedEntities}
      renderInput={params => (
        <TextField
          {...params}
          className={formStyles.input}
          placeholder={t('search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {requestPending ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default PlantMultiSelect;
