// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerRequest
 */
export interface CustomerRequest  {
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    town: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    language?: string;
}

export function CustomerRequestFromJSON(json: any): CustomerRequest {
    return {
        'name': json['name'],
        'street': json['street'],
        'zipCode': json['zipCode'],
        'town': json['town'],
        'country': json['country'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function CustomerRequestToJSON(value?: CustomerRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'street': value.street,
        'zipCode': value.zipCode,
        'town': value.town,
        'country': value.country,
        'mobile': value.mobile,
        'telephone': value.telephone,
        'fax': value.fax,
        'email': value.email,
        'language': value.language,
    };
}


