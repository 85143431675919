// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleRequest
 */
export interface VehicleRequest  {
    /**
     * 
     * @type {string}
     * @memberof VehicleRequest
     */
    licencePlateTruck: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleRequest
     */
    ggvsB3Truck: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequest
     */
    licencePlateTrailer: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleRequest
     */
    ggvsB3Trailer: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleRequest
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequest
     */
    product: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequest
     */
    shippingAgentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleRequest
     */
    medOk: boolean;
}

export function VehicleRequestFromJSON(json: any): VehicleRequest {
    return {
        'licencePlateTruck': json['licencePlateTruck'],
        'ggvsB3Truck': new Date(new Date(json['ggvsB3Truck']).getTime()+ new Date(json['ggvsB3Truck']).getTimezoneOffset()*60*1000),
        'licencePlateTrailer': json['licencePlateTrailer'],
        'ggvsB3Trailer': new Date(new Date(json['ggvsB3Trailer']).getTime()+ new Date(json['ggvsB3Trailer']).getTimezoneOffset()*60*1000),
        'weight': json['weight'],
        'product': json['product'],
        'shippingAgentId': json['shippingAgentId'],
        'medOk': json['medOk'],
    };
}

export function VehicleRequestToJSON(value?: VehicleRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licencePlateTruck': value.licencePlateTruck,
        'ggvsB3Truck': new Date(value.ggvsB3Truck.getTime() - value.ggvsB3Truck.getTimezoneOffset()*60*1000).toISOString(),
        'licencePlateTrailer': value.licencePlateTrailer,
        'ggvsB3Trailer': new Date(value.ggvsB3Trailer.getTime() - value.ggvsB3Trailer.getTimezoneOffset()*60*1000).toISOString(),
        'weight': value.weight,
        'product': value.product,
        'shippingAgentId': value.shippingAgentId,
        'medOk': value.medOk,
    };
}


