// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerAllOf,
    CustomerAllOfFromJSON,
    CustomerAllOfToJSON,
    DriverClearanceRequest,
    DriverClearanceRequestFromJSON,
    DriverClearanceRequestToJSON,
} from './';

/**
 * @type DriverClearance
 * @export
 */
export interface DriverClearance extends CustomerAllOf, DriverClearanceRequest {
}

export function DriverClearanceFromJSON(json: any): DriverClearance {
    return {
        ...CustomerAllOfFromJSON(json),
        ...DriverClearanceRequestFromJSON(json),
    };
}

export function DriverClearanceToJSON(value?: DriverClearance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerAllOfToJSON(value),
        ...DriverClearanceRequestToJSON(value),
    };
}
