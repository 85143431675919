import React, { useEffect } from 'react';
import { listPermissionOperator, Plant } from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import PlantMultiSelect from './PlantMultiSelect';

interface Props {
  userId: string;
  selectedPlants: Plant[];
  setSelectedPlants: (entities: Plant[]) => void;
}

const OperatorPermissionsEdit: React.FC<Props> = ({
  userId,
  selectedPlants,
  setSelectedPlants,
}) => {
  const accountQueryConfig = listPermissionOperator(
    { userId },
    {
      transform: body => {
        return { operatorPermissions: { [userId]: body } };
      },
      update: {
        operatorPermissions: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: true,
    }
  );

  const [{ isPending: permissionsPending, lastUpdated: permissionsUpdatedTs }] =
    useRequest(accountQueryConfig);

  let permissionEntities = useSelector((state: any) => {
    return (state.entities?.operatorPermissions?.[userId] ?? null) as
      | Plant[]
      | null;
  });

  useEffect(() => {
    if (permissionEntities == null) {
      return;
    }

    setSelectedPlants(permissionEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsUpdatedTs]);

  if (permissionEntities === null || permissionsPending) {
    return <Loading />;
  }

  return (
    <PlantMultiSelect
      selectedEntities={selectedPlants}
      onSelectedEntitiesChanges={setSelectedPlants}
    />
  );
};

export default OperatorPermissionsEdit;
