// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccountCreationRequest,
    AccountCreationRequestFromJSON,
    AccountCreationRequestToJSON,
    OperatorAccountCreationRequestAllOf,
    OperatorAccountCreationRequestAllOfFromJSON,
    OperatorAccountCreationRequestAllOfToJSON,
} from './';

/**
 * @type OperatorAccountCreationRequest
 * @export
 */
export interface OperatorAccountCreationRequest extends AccountCreationRequest, OperatorAccountCreationRequestAllOf {
}

export function OperatorAccountCreationRequestFromJSON(json: any): OperatorAccountCreationRequest {
    return {
        ...AccountCreationRequestFromJSON(json),
        ...OperatorAccountCreationRequestAllOfFromJSON(json),
    };
}

export function OperatorAccountCreationRequestToJSON(value?: OperatorAccountCreationRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AccountCreationRequestToJSON(value),
        ...OperatorAccountCreationRequestAllOfToJSON(value),
    };
}
