import { StalenessState } from './reducer';

export const isCategoryStale = (state: StalenessState, key: string): boolean =>
  Object.values(state[key]).some(value => value === true);

export const isStale = (
  state: StalenessState,
  categoryKey: string,
  subKey: string
): boolean => {
  if (state[categoryKey] == null) {
    return false;
  }

  return (state[categoryKey] as { [key: string]: boolean })[subKey];
};
