// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleOperatorData
 */
export interface VehicleOperatorData  {
    /**
     * 
     * @type {string}
     * @memberof VehicleOperatorData
     */
    transponder?: string|null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleOperatorData
     */
    active?: boolean;
}

export function VehicleOperatorDataFromJSON(json: any): VehicleOperatorData {
    return {
        'transponder': !exists(json, 'transponder') ? undefined : json['transponder'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function VehicleOperatorDataToJSON(value?: VehicleOperatorData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transponder': value.transponder,
        'active': value.active,
    };
}


