import React from 'react';

const RoleError: React.FC = () => {
  return (
    <>
      <h2>You have encountered a misconfiguration</h2>
      <span>There is no role assigned to your account.</span>
      <span>
        This is not your fault however, please contact Westfalen and we'll fix
        this error as soon as possible.
      </span>
    </>
  );
};

export default RoleError;
