// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Vehicle,
    VehicleFromJSON,
    VehicleToJSON,
    VehicleOperatorData,
    VehicleOperatorDataFromJSON,
    VehicleOperatorDataToJSON,
} from './';

/**
 * @type VehicleFull
 * @export
 */
export interface VehicleFull extends Vehicle, VehicleOperatorData {
}

export function VehicleFullFromJSON(json: any): VehicleFull {
    return {
        ...VehicleFromJSON(json),
        ...VehicleOperatorDataFromJSON(json),
    };
}

export function VehicleFullToJSON(value?: VehicleFull): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...VehicleToJSON(value),
        ...VehicleOperatorDataToJSON(value),
    };
}
