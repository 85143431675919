import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003169',
    },
    secondary: {
      main: '#eb6617',
    },
    error: {
      main: '#e4001f',
    },
    success: {
      main: '#87d32e',
    },
    text: {
      primary: '#003169',
      secondary: 'rgba(#003169,0.5)',
      disabled: 'rgba(#003169,0.5)',
    },
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 600,
    allVariants: {
      color: '#003169',
    },
  },
});

export default theme;
