// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerListEntry,
    CustomerListEntryFromJSON,
    CustomerListEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerList
 */
export interface CustomerList  {
    /**
     * 
     * @type {Array<CustomerListEntry>}
     * @memberof CustomerList
     */
    entities: Array<CustomerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof CustomerList
     */
    numTotal: number;
}

export function CustomerListFromJSON(json: any): CustomerList {
    return {
        'entities': (json['entities'] as Array<any>).map(CustomerListEntryFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function CustomerListToJSON(value?: CustomerList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(CustomerListEntryToJSON),
        'numTotal': value.numTotal,
    };
}


