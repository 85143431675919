// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    Plant,
    PlantFromJSON,
    PlantToJSON,
    PlantList,
    PlantListFromJSON,
    PlantListToJSON,
} from '../models';

export interface GetPlantRequest {
    plantId: string;
}


/**
 * Returns a plants details.
 */
function getPlantRaw<T>(requestParameters: GetPlantRequest, requestConfig: runtime.TypedQueryConfig<T, Plant> = {}): QueryConfig<T> {
    if (requestParameters.plantId === null || requestParameters.plantId === undefined) {
        throw new runtime.RequiredError('plantId','Required parameter requestParameters.plantId was null or undefined when calling getPlant.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/plant/get/{plantId}`.replace(`{${"plantId"}}`, encodeURIComponent(String(requestParameters.plantId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PlantFromJSON(body), text);
    }

    return config;
}

/**
* Returns a plants details.
*/
export function getPlant<T>(requestParameters: GetPlantRequest, requestConfig?: runtime.TypedQueryConfig<T, Plant>): QueryConfig<T> {
    return getPlantRaw(requestParameters, requestConfig);
}

/**
 * Returns all plants details.
 */
function listPlantsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, PlantList> = {}): QueryConfig<T> {
    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/plant/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PlantListFromJSON(body), text);
    }

    return config;
}

/**
* Returns all plants details.
*/
export function listPlants<T>( requestConfig?: runtime.TypedQueryConfig<T, PlantList>): QueryConfig<T> {
    return listPlantsRaw( requestConfig);
}

