import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as commonActions from '../features/common/actions';

const mapStateToProps = () => ({});

const dispatchProps = {
  signedIn: commonActions.signedInAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AuthGuard: React.FC<Props> = ({ signedIn }) => {
  const authState = useRef<AuthState>();

  useEffect(() => {
    return onAuthUIStateChange(nextAuthState => {
      if (nextAuthState === AuthState.SignedIn) {
        // prevent triggering signed in actions multiple times
        // (workaround for amplify bug)
        if (nextAuthState !== authState.current) {
          signedIn();
        }
      }
      authState.current = nextAuthState;
    });
  }, [signedIn]);

  return <></>;
};

export default connect(mapStateToProps, dispatchProps)(AuthGuard);
