import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { listLanguages, Language } from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';

interface Props {
  onLanguageSelected: (languageId: string | null) => void;
  selectedLanguage: string | null;
  required?: boolean;
}

export const ListLanguageQuery = listLanguages({
  transform: body => {
    return { languages: body };
  },
  update: {
    languages: (oldValue: any, newValue: any) => {
      return newValue;
    },
  },
});

export const ListLanguageSelector = (state: any) => {
  return (state.entities.languages ?? []) as Language[];
};

const LanguageSelect: React.FC<Props> = ({
  onLanguageSelected,
  selectedLanguage,
  required,
}) => {
  const { t } = useTranslation();

  const actualLanguage =
    selectedLanguage == null || selectedLanguage === '' ? '' : selectedLanguage;

  const [{ isPending }] = useRequest(ListLanguageQuery);
  let listData = useSelector(ListLanguageSelector);

  const handleChangeLanguage = (id: string | null) => {
    if (id === '') {
      onLanguageSelected(null);
    } else {
      onLanguageSelected(id);
    }
  };

  if (isPending || listData == null) {
    return <div className={formStyles.fakeItem} />;
  }

  return (
    <FormControl className={formStyles.input}>
      <InputLabel id="language-select">{`${t('language')}${
        !!required ? ' *' : ''
      }`}</InputLabel>
      <Select
        id="language-select"
        value={actualLanguage}
        label={t('language')}
        onChange={event => {
          handleChangeLanguage(event.target.value);
        }}
        required={required}
      >
        {listData.map(it => {
          return (
            <MenuItem value={it.id} key={`language-select-${it.id}`}>
              {it.name ?? '-'}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
