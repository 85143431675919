// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverOperatorListEntry,
    DriverOperatorListEntryFromJSON,
    DriverOperatorListEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverOperatorList
 */
export interface DriverOperatorList  {
    /**
     * 
     * @type {Array<DriverOperatorListEntry>}
     * @memberof DriverOperatorList
     */
    entities: Array<DriverOperatorListEntry>;
    /**
     * 
     * @type {number}
     * @memberof DriverOperatorList
     */
    numTotal: number;
}

export function DriverOperatorListFromJSON(json: any): DriverOperatorList {
    return {
        'entities': (json['entities'] as Array<any>).map(DriverOperatorListEntryFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function DriverOperatorListToJSON(value?: DriverOperatorList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(DriverOperatorListEntryToJSON),
        'numTotal': value.numTotal,
    };
}


