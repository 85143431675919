// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShippingAgentMaxWeight
 */
export interface ShippingAgentMaxWeight  {
    /**
     * 
     * @type {string}
     * @memberof ShippingAgentMaxWeight
     */
    shippingAgentId: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingAgentMaxWeight
     */
    maxWeight: number;
}

export function ShippingAgentMaxWeightFromJSON(json: any): ShippingAgentMaxWeight {
    return {
        'shippingAgentId': json['shippingAgentId'],
        'maxWeight': json['maxWeight'],
    };
}

export function ShippingAgentMaxWeightToJSON(value?: ShippingAgentMaxWeight): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'shippingAgentId': value.shippingAgentId,
        'maxWeight': value.maxWeight,
    };
}


