// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverCustomerListEntry,
    DriverCustomerListEntryFromJSON,
    DriverCustomerListEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverCustomerList
 */
export interface DriverCustomerList  {
    /**
     * 
     * @type {Array<DriverCustomerListEntry>}
     * @memberof DriverCustomerList
     */
    entities: Array<DriverCustomerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof DriverCustomerList
     */
    numTotal: number;
}

export function DriverCustomerListFromJSON(json: any): DriverCustomerList {
    return {
        'entities': (json['entities'] as Array<any>).map(DriverCustomerListEntryFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function DriverCustomerListToJSON(value?: DriverCustomerList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(DriverCustomerListEntryToJSON),
        'numTotal': value.numTotal,
    };
}


