import React, { useState } from 'react';
import { ListingParams } from '../../../components/list/List';
import { TypedQueryConfig } from '../../../generated/api/src';
import { QueryConfig } from 'redux-query';
import TypeaheadMultiEntitySelect from './TypeaheadMultiEntitySelect';

interface Props {
  entitySearchResultKey: string;

  onSelectedEntityIdChanged: (id: string) => void;

  queryBuilder: (
    requestParameters: ListingParams,
    requestConfig?: TypedQueryConfig</*{[key: string]:T}*/ any, any>
  ) => QueryConfig<any>;

  getOptionLabel: (it: any) => string;
  defaultValue: any;
  defaultValueText: string;
  displayLabel?: string;
  className?: string;
  maxEntries?: number;
}

const TypeaheadEntityFilter: React.FC<Props> = ({
  entitySearchResultKey,
  onSelectedEntityIdChanged,
  getOptionLabel,
  queryBuilder,
  defaultValue,
  defaultValueText,
  displayLabel,
  className,
  maxEntries,
}) => {
  const [selectedEntity, setSelectedEntity] = useState<any>(defaultValue);

  return (
    <TypeaheadMultiEntitySelect
      entitySearchResultStoreKey={entitySearchResultKey}
      selectedEntities={selectedEntity}
      onSelectedEntitiesChanges={entities => {
        setSelectedEntity(entities);
        if (entities == null) {
          return;
        }
        onSelectedEntityIdChanged(
          entities === defaultValue ? defaultValue : entities.id
        );
      }}
      multiSelect={false}
      queryBuilder={queryBuilder}
      getOptionLabel={it =>
        it === defaultValue ? defaultValueText : getOptionLabel(it)
      }
      additionalOption={defaultValue}
      displayLabel={displayLabel}
      className={className}
      maxEntries={maxEntries}
    />
  );
};

export default TypeaheadEntityFilter;
