// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Plant
 */
export interface Plant  {
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    email?: string;
    /**
     * Column \'Code\'
     * @type {string}
     * @memberof Plant
     */
    code?: string;
    /**
     * Column \'Betriebsleiter\'
     * @type {string}
     * @memberof Plant
     */
    plantChief?: string;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    plantLocationId: string;
}

export function PlantFromJSON(json: any): Plant {
    return {
        'id': json['id'],
        'name': json['name'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'plantChief': !exists(json, 'plantChief') ? undefined : json['plantChief'],
        'plantLocationId': json['plantLocationId'],
    };
}

export function PlantToJSON(value?: Plant): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'mobile': value.mobile,
        'telephone': value.telephone,
        'fax': value.fax,
        'email': value.email,
        'code': value.code,
        'plantChief': value.plantChief,
        'plantLocationId': value.plantLocationId,
    };
}


