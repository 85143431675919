import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import NoMatch from '../components/NoMatch';
import VersionInfo from '../components/VersionInfo';
import * as commonActions from '../features/common/actions';
import ScmApp from '../features/scm/ScmApp';
import CustomerApp from '../features/customer/CustomerApp';
import OperatorApp from '../features/operator/OperatorApp';
import { UserRole } from '../features/common/types';
import RoleError from '../components/RoleError';
import AdminApp from '../features/admin/AdminApp';
import { RootRouteMap } from './root-route-map';

const mapStateToProps = (_: RootState) => ({});

interface ComponentProps {
  userRole: UserRole;
}

const dispatchProps = {
  signOut: commonActions.signOutAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const Routes: React.FC<Props> = ({ signOut, userRole }) => {
  let appEntrypoint = '/role-error/';
  switch (userRole) {
    case UserRole.PlantOperator:
    case UserRole.PlantOperatorMaster:
      appEntrypoint = RootRouteMap.plantOperator;
      break;
    case UserRole.Customer:
      appEntrypoint = RootRouteMap.customer;
      break;
    case UserRole.Manager:
      appEntrypoint = RootRouteMap.scm;
      break;
    case UserRole.Admin:
      appEntrypoint = RootRouteMap.admin;
      break;
  }

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={appEntrypoint} />} />
      {userRole === UserRole.Manager || userRole === UserRole.Admin ? (
        <Route path={RootRouteMap.scm} component={ScmApp} />
      ) : (
        <Route
          path={RootRouteMap.scm}
          render={() => <Redirect to={appEntrypoint} />}
        />
      )}

      {userRole === UserRole.PlantOperator ||
      userRole === UserRole.PlantOperatorMaster ||
      userRole === UserRole.Admin ? (
        <Route path={RootRouteMap.plantOperator} component={OperatorApp} />
      ) : (
        <Route
          path={RootRouteMap.plantOperator}
          render={() => <Redirect to={appEntrypoint} />}
        />
      )}

      {userRole === UserRole.Customer ||
      userRole === UserRole.Manager ||
      userRole === UserRole.Admin ? (
        <Route path={RootRouteMap.customer} component={CustomerApp} />
      ) : (
        <Route
          path={RootRouteMap.customer}
          render={() => <Redirect to={appEntrypoint} />}
        />
      )}

      {userRole === UserRole.Admin ? (
        <Route path={RootRouteMap.admin} component={AdminApp} />
      ) : (
        <Route
          path={RootRouteMap.admin}
          render={() => <Redirect to={appEntrypoint} />}
        />
      )}
      <Route
        exact
        path="/signout/"
        render={() => {
          signOut();
          return null;
        }}
      />
      <Route exact path="/version/" component={VersionInfo} />
      <Route exact path="/role-error/" component={RoleError} />
      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default connect(mapStateToProps, dispatchProps)(Routes);
