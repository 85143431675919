// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountCreationRequest
 */
export interface AccountCreationRequest  {
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCreationRequest
     */
    sendInvitation: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    accountType: AccountCreationRequestAccountTypeEnum;
}

export function AccountCreationRequestFromJSON(json: any): AccountCreationRequest {
    return {
        'email': json['email'],
        'sendInvitation': json['sendInvitation'],
        'accountType': json['accountType'],
    };
}

export function AccountCreationRequestToJSON(value?: AccountCreationRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'sendInvitation': value.sendInvitation,
        'accountType': value.accountType,
    };
}

/**
* @export
* @enum {string}
*/
export enum AccountCreationRequestAccountTypeEnum {
    Collector = 'Collector',
    Operator = 'Operator',
    Scm = 'Scm'
}


