// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OperatorAccountAllOf,
    OperatorAccountAllOfFromJSON,
    OperatorAccountAllOfToJSON,
    OperatorAccountAllOfPlants,
    OperatorAccountAllOfPlantsFromJSON,
    OperatorAccountAllOfPlantsToJSON,
    UserAccount,
    UserAccountFromJSON,
    UserAccountToJSON,
} from './';

/**
 * @type OperatorAccount
 * @export
 */
export interface OperatorAccount extends OperatorAccountAllOf, UserAccount {
}

export function OperatorAccountFromJSON(json: any): OperatorAccount {
    return {
        ...OperatorAccountAllOfFromJSON(json),
        ...UserAccountFromJSON(json),
    };
}

export function OperatorAccountToJSON(value?: OperatorAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...OperatorAccountAllOfToJSON(value),
        ...UserAccountToJSON(value),
    };
}
