import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import EditButtonRow from '../../../components/form/EditButtonRow';
import PageHeader from '../../../components/form/PageHeader';
import PageContainer from '../../../components/form/PageContainer';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useRequest } from 'redux-query-react';
import {
  getVehicleOperator,
  updateVehicleOperator,
  VehicleFull,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import ProductDisplay from '../components/ProductDisplay';
import ShippingAgentDisplay from '../components/ShippingAgentDisplay';
import {
  vehicleOperatorListStoreKey,
  vehicleOperatorStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import { OperatorRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import LabelValuePair from '../../../components/LabelValuePair';
import { format } from 'date-fns';
import { ValidationRegex } from '../../common/components/ValidationRegex';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditVehicle: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const { id: vehicleId } = useParams<{ id: string }>();
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);

  const [active, setActive] = useState<boolean>(false);
  const [transponder, setTransponder] = useState<string>('');

  const transponderOk =
    transponder.match(ValidationRegex.Vehicle.Transponder) != null ||
    transponder === '';

  const vehicleQueryConfig = getVehicleOperator(
    { vehicleId },
    {
      transform: body => {
        return { [vehicleOperatorStoreKey]: { [body.id]: body } };
      },
      update: {
        [vehicleOperatorStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(vehicleOperatorStoreKey, vehicleId),
    }
  );

  const [{ isPending: vehiclePending, lastUpdated: vehicleUpdateTs }] =
    useRequest(vehicleQueryConfig);

  let vehicleEntity = useSelector((state: any) => {
    return (state.entities?.[vehicleOperatorStoreKey]?.[vehicleId] ??
      null) as VehicleFull | null;
  });

  const [{ isPending: vehicleUpdatePending }, updateVehicleHandle] =
    useMutation(() =>
      updateVehicleOperator({
        vehicleRequestOperator: {
          id: vehicleId,
          active,
          transponder: transponder === '' ? null : transponder,
        },
      })
    );

  useEffect(() => {
    setActive(vehicleEntity?.active ?? false);
    setTransponder(vehicleEntity?.transponder ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleUpdateTs]);

  if (vehiclePending || vehicleUpdatePending || vehicleEntity === null) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={OperatorRouteMap.vehicleList}
        linkText={t('vehicles')}
        heading={t('editVehicle')}
      />
      <div className={formStyles.section}>
        <h2>{t('vehicleInfo')}</h2>
        <div className={formStyles.values}>
          <LabelValuePair
            label={t('licencePlateTruck')}
            value={vehicleEntity.licencePlateTruck}
          />
          <LabelValuePair
            label={t('ggsvb3Truck')}
            value={
              vehicleEntity.ggvsB3Truck != null
                ? format(vehicleEntity.ggvsB3Truck, t('dateFormatDateOnly'))
                : '--'
            }
          />
          <LabelValuePair
            label={t('licencePlateTrailer')}
            value={vehicleEntity.licencePlateTrailer}
          />
          <LabelValuePair
            label={t('ggsvb3Trailer')}
            value={
              vehicleEntity.ggvsB3Trailer != null
                ? format(vehicleEntity.ggvsB3Trailer, t('dateFormatDateOnly'))
                : '--'
            }
          />
          <LabelValuePair
            label={t('shippingAgent')}
            value={
              <ShippingAgentDisplay
                shippingAgentId={vehicleEntity.shippingAgentId}
              />
            }
          />
          <LabelValuePair
            label={t('product')}
            value={<ProductDisplay productId={vehicleEntity.product} />}
          />
        </div>
      </div>

      <div className={formStyles.section}>
        <h2>{t('clearances')}</h2>
        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          <FormControl>
            <TextField
              id="search-input"
              label={t('transponder')}
              onChange={event => setTransponder(event.target.value)}
              value={transponder}
              error={!transponderOk}
            />
          </FormControl>
          <FormControl>
            <div
              className={formStyles.outlined}
              style={{ paddingLeft: '14px' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    onChange={event => setActive(event.target.checked)}
                  />
                }
                label={t('clearanceAll')}
                checked={active}
              />
            </div>
          </FormControl>
        </div>
      </div>
      <Link to={OperatorRouteMap.vehicleList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onCancel={() => cancelLink?.current?.click()}
        disableSave={!transponderOk}
        onSave={() => {
          markListAsStale(vehicleOperatorListStoreKey);
          markEntityAsStale([vehicleOperatorStoreKey, [vehicleId]]);
          updateVehicleHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditVehicle);
