import {
  listShippingAgents,
  ShippingAgentListEntry,
} from '../../../generated/api/src';
import TypeaheadEntityFilter from './TypeaheadEntityFilter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shippingAgentFilterStoreKey } from '../../../store/store-keys';

interface Props {
  onShippingAgentIdChanged: (id: string) => void;
  defaultValue: any;
}

const ShippingAgentFilter: React.FC<Props> = ({
  onShippingAgentIdChanged,
  defaultValue,
}) => {
  const { t } = useTranslation();

  return (
    <TypeaheadEntityFilter
      entitySearchResultKey={shippingAgentFilterStoreKey}
      onSelectedEntityIdChanged={onShippingAgentIdChanged}
      defaultValue={defaultValue}
      defaultValueText={t('selectAllShippingAgents')}
      // @ts-ignore
      queryBuilder={listShippingAgents}
      getOptionLabel={(obj: ShippingAgentListEntry) =>
        `${obj.name} (${obj.town ?? '-'}, ${obj.customerName})`
      }
      maxEntries={200}
    />
  );
};

export default ShippingAgentFilter;
