import React from 'react';
import { Customer, listCustomers } from '../../../generated/api/src';
import {
  customerFilterStoreKey,
  customerSelectStoreKey,
} from '../../../store/store-keys';
import { useTranslation } from 'react-i18next';
import TypeaheadEntitySelect from './TypeaheadEntitySelect';
import formStyles from '../../../components/form/formStyles.module.scss';

interface Props {
  onCustomerSelected: (customerId: string | null) => void;
  selectedCustomer: string | null;
}

const CustomerSelect: React.FC<Props> = ({
  onCustomerSelected,
  selectedCustomer,
}) => {
  const { t } = useTranslation();

  return (
    <div className={formStyles.inputContainer}>
      <TypeaheadEntitySelect
        entitySearchResultStoreKey={customerFilterStoreKey}
        selectedEntitySearchStoreKey={customerSelectStoreKey}
        selectedEntityId={selectedCustomer}
        onSelectedEntityIdChanged={onCustomerSelected}
        // @ts-ignore
        queryBuilder={listCustomers}
        getOptionLabel={(it: Customer) => `${it.name} (${it.id})`}
        displayLabel={t('customer')}
        key={customerSelectStoreKey}
        className={formStyles.input}
      />
    </div>
  );
};

export default CustomerSelect;
