import React from 'react';
import { getShippingAgentMaxWeight } from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import { shippingAgentCustomerMaxWeightStoreKey } from '../../../store/store-keys';
import { connect, useSelector } from 'react-redux';
import VehicleMaxWeightInput from './VehicleMaxWeightInput';
import formStyles from '../../../components/form/formStyles.module.scss';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';

interface ComponentProps {
  shippingAgentId: string;
  weight: number;
  onSetWeight: (weight: number) => void;
  onWeightError: (error: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const VehicleMaxWeightEdit: React.FC<Props> = ({
  shippingAgentId,
  onSetWeight,
  weight,
  entityIsStale,
  onWeightError,
}) => {
  const [{ isPending: weightPending }] = useRequest(
    getShippingAgentMaxWeight(
      { shippingAgentId },
      {
        transform: body => {
          return {
            [shippingAgentCustomerMaxWeightStoreKey]: {
              [body.shippingAgentId]: body,
            },
          };
        },
        update: {
          [shippingAgentCustomerMaxWeightStoreKey]: (
            oldValue: any,
            newValue: any
          ) => {
            return { ...oldValue, ...newValue };
          },
        },
        force: entityIsStale(
          shippingAgentCustomerMaxWeightStoreKey,
          shippingAgentId
        ),
      }
    )
  );

  const maxWeight = useSelector((state: any) => {
    return (state.entities?.[shippingAgentCustomerMaxWeightStoreKey]?.[
      shippingAgentId
    ]?.maxWeight ?? null) as number | null;
  });

  if (weightPending || maxWeight == null) {
    return <div className={formStyles.fakeItem} />;
  }

  return (
    <VehicleMaxWeightInput
      maxWeight={maxWeight}
      weight={weight}
      onSetWeight={onSetWeight}
      onWeightError={onWeightError}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(VehicleMaxWeightEdit);
