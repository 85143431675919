import React from 'react';
import formStyles from '../../../components/form/formStyles.module.scss';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import {
  DriverClearanceRequest,
  listPlants,
  Plant,
} from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import Loading from '../../../components/Loading';
import { useSelector } from 'react-redux';
import { Checkbox, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { sanitizeDateFromDatePicker } from '../../../util/SanitizeDateFromDatePickerHelper';

export interface DriverClearanceData {
  [plantId: string]: DriverClearanceRequest;
}

interface Props {
  driverId: string;
  clearances: DriverClearanceData;
  onUpdateClearanceData: (
    plantId: string,
    attr: keyof DriverClearanceRequest,
    val: any
  ) => void;
}

const DriverClearancesEdit: React.FC<Props> = ({
  driverId,
  onUpdateClearanceData,
  clearances,
}) => {
  const { t } = useTranslation();

  const plantQueryConfig = listPlants({
    transform: body => {
      return { plants: body };
    },
    update: {
      plants: (oldValue: any, newValue: any) => {
        return newValue;
      },
    },
  });

  const [{ isPending: plantsPending }] = useRequest(plantQueryConfig);
  let plantEntities = useSelector((state: any) => {
    return (state.entities.plants ?? []) as Plant[];
  });

  if (plantsPending) {
    return <Loading />;
  }

  const grid = '2fr 1fr 1fr 1fr 2.5fr';
  return (
    <div className={formStyles.outlined}>
      <div className={formStyles.list}>
        <div
          className={formStyles.listLabels}
          style={{ gridTemplateColumns: grid }}
        >
          <Label>{t('property')}</Label>
          <Label>{t('clearance')}</Label>
          <Label>{t('clearedFor24hEntry')}</Label>
          <Label>{t('clearedForEntryWithoutAdr')}</Label>
          <Label>{t('plantTraining')}</Label>
        </div>
        {plantEntities.map((plant, index) => (
          // <ListItem
          //   values={[
          //     plant.name,
          //     !!clearancesPerPlant[plant.id]?.active,
          //     !!clearancesPerPlant[plant.id]?.access24h,
          //     !!clearancesPerPlant[plant.id]?.accessWithoutAdr,
          //     clearancesPerPlant[plant.id]?.plantTraining == null
          //       ? ''
          //       : String(clearancesPerPlant[plant.id]?.plantTraining),
          //   ]}
          //   grid={grid}
          // />
          <div key={index}>
            <div
              className={formStyles.listItem}
              style={{ gridTemplateColumns: grid }}
            >
              <div>{plant.name}</div>
              <div>
                <Checkbox
                  color="success"
                  onChange={event =>
                    onUpdateClearanceData(
                      plant.id,
                      'active',
                      event.target.checked ?? false
                    )
                  }
                  checked={!!clearances[plant.id]?.active}
                />
              </div>
              <div>
                <Checkbox
                  color="success"
                  onChange={event =>
                    onUpdateClearanceData(
                      plant.id,
                      'access24h',
                      event.target.checked ?? false
                    )
                  }
                  checked={!!clearances[plant.id]?.access24h}
                />
              </div>
              <div>
                <Checkbox
                  color="success"
                  onChange={event =>
                    onUpdateClearanceData(
                      plant.id,
                      'accessWithoutAdr',
                      event.target.checked ?? false
                    )
                  }
                  checked={!!clearances[plant.id]?.accessWithoutAdr}
                />
              </div>
              <DatePicker
                clearable={true}
                value={
                  clearances[plant.id]?.plantTraining == null
                    ? null
                    : clearances[plant.id]?.plantTraining
                }
                onChange={newValue => {
                  onUpdateClearanceData(
                    plant.id,
                    'plantTraining',
                    newValue !== undefined
                      ? sanitizeDateFromDatePicker(newValue)
                      : newValue
                  );
                }}
                renderInput={params => (
                  <TextField {...params} className={formStyles.picker} />
                )}
                inputFormat={t('dateFormatDateOnly')}
                mask={t('dateFormatDateMask')}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriverClearancesEdit;
