// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    Product,
    ProductFromJSON,
    ProductToJSON,
    ProductList,
    ProductListFromJSON,
    ProductListToJSON,
} from '../models';

export interface GetProductRequest {
    productId: string;
}

export interface ListProductsRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListProductsSortDirEnum;
}


/**
 * Returns a product
 */
function getProductRaw<T>(requestParameters: GetProductRequest, requestConfig: runtime.TypedQueryConfig<T, Product> = {}): QueryConfig<T> {
    if (requestParameters.productId === null || requestParameters.productId === undefined) {
        throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProduct.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ProductFromJSON(body), text);
    }

    return config;
}

/**
* Returns a product
*/
export function getProduct<T>(requestParameters: GetProductRequest, requestConfig?: runtime.TypedQueryConfig<T, Product>): QueryConfig<T> {
    return getProductRaw(requestParameters, requestConfig);
}

/**
 * Lists all products
 */
function listProductsRaw<T>(requestParameters: ListProductsRequest, requestConfig: runtime.TypedQueryConfig<T, ProductList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/product/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ProductListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all products
*/
export function listProducts<T>(requestParameters: ListProductsRequest, requestConfig?: runtime.TypedQueryConfig<T, ProductList>): QueryConfig<T> {
    return listProductsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListProductsSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
