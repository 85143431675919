import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import formStyles from '../../../components/form/formStyles.module.scss';
import styles from './AddressContact.module.scss';
import { useTranslation } from 'react-i18next';
import LanguageSelect from './LanguageSelect';
import { ValidationRegex } from './ValidationRegex';

export interface AddressData {
  name: string;
  street: string;
  zipCode: string;
  town: string;
  country: string;
  language: string;

  telephone: string;
  mobile: string;
  email: string;
  fax: string;
}

interface Props {
  addressData: AddressData;
  setAddressData: React.Dispatch<React.SetStateAction<AddressData>>;
  excludeCountryAndLanguage?: boolean;
  onValidation?: (valid: boolean) => void;
}

const AddressContact: React.FC<Props> = ({
  addressData,
  setAddressData,
  excludeCountryAndLanguage,
  onValidation,
}) => {
  const { t } = useTranslation();
  const includeCountryAndLanguage = !excludeCountryAndLanguage ?? true;

  const countryOk =
    (addressData.country ?? '').match(
      ValidationRegex.AddressData.CountryIso3166Alpha2
    ) != null || !!excludeCountryAndLanguage;
  const nameOk =
    (addressData.name ?? '').match(ValidationRegex.AddressData.Name) != null;
  const zipOk =
    (addressData.zipCode ?? '').match(ValidationRegex.AddressData.ZipCode) !=
    null;
  const townOk =
    (addressData.town ?? '').match(ValidationRegex.AddressData.Town) != null;
  const streetOk =
    (addressData.street ?? '').match(ValidationRegex.AddressData.Street) !=
    null;
  const emailOk =
    (addressData.email ?? '').match(ValidationRegex.AddressData.Email) != null;

  const faxOk =
    (addressData.fax ?? '').match(ValidationRegex.AddressData.Fax) != null;
  const phoneOk =
    (addressData.telephone ?? '').match(ValidationRegex.AddressData.Phone) !=
    null;
  const mobileOk =
    (addressData.mobile ?? '').match(ValidationRegex.AddressData.Mobile) !=
    null;

  const formValid =
    [
      countryOk,
      nameOk,
      zipOk,
      townOk,
      streetOk,
      faxOk,
      phoneOk,
      mobileOk,
      emailOk,
    ].every(it => it) &&
    [
      addressData.name,
      addressData.street,
      addressData.town,
      addressData.zipCode,
      ...(excludeCountryAndLanguage
        ? []
        : [addressData.country, addressData.language]),
    ].every(it => it !== '');

  useEffect(() => {
    if (!!onValidation) {
      onValidation(formValid);
    }
  }, [formValid, onValidation]);

  const onAddressDataChange = (
    key: keyof typeof addressData,
    value: string
  ) => {
    switch (key) {
      case 'name':
        setAddressData({ ...addressData, name: value });
        break;
      default:
        setAddressData({ ...addressData, [key]: value ?? '' });
        break;
    }
  };

  return (
    <>
      <h2>{t('addressContactData')}</h2>

      <div className={formStyles.section}>
        <div className={formStyles.inputContainer}>
          <TextField
            label={t('nameOrCompany')}
            className={formStyles.input}
            required={true}
            value={addressData.name}
            onChange={event => onAddressDataChange('name', event.target.value)}
            error={!nameOk}
          />
        </div>

        <div className={formStyles.inputContainer}>
          <TextField
            label={t('street')}
            className={formStyles.input}
            value={addressData.street}
            onChange={event =>
              onAddressDataChange('street', event.target.value)
            }
            error={!streetOk && addressData.street !== ''}
            required
          />
        </div>
        <div className={`${formStyles.inputContainer} ${styles.city}`}>
          <TextField
            label={t('zipCode')}
            value={addressData.zipCode}
            className={`${formStyles.input} ${styles.left}`}
            onChange={event =>
              onAddressDataChange('zipCode', event.target.value)
            }
            error={!zipOk && addressData.zipCode !== ''}
            required
          />
          <TextField
            label={t('city')}
            value={addressData.town}
            className={`${formStyles.input} ${styles.right}`}
            onChange={event => onAddressDataChange('town', event.target.value)}
            error={!townOk && addressData.town !== ''}
            required
          />
        </div>
        {includeCountryAndLanguage ? (
          <div className={`${formStyles.inputContainer} ${styles.country}`}>
            <TextField
              label={t('country')}
              value={addressData.country}
              className={`${formStyles.input} ${styles.left}`}
              onChange={event =>
                onAddressDataChange('country', event.target.value)
              }
              error={!countryOk && addressData.country !== ''}
              required
            />
            <div className={styles.right}>
              <LanguageSelect
                onLanguageSelected={lang =>
                  onAddressDataChange('language', lang ?? '')
                }
                selectedLanguage={addressData.language}
                required
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={formStyles.section}>
        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          <TextField
            label={t('phone')}
            className={formStyles.input}
            value={addressData.telephone}
            onChange={event =>
              onAddressDataChange('telephone', event.target.value)
            }
            error={!phoneOk}
          />
          <TextField
            label={t('mobile')}
            className={formStyles.input}
            value={addressData.mobile}
            onChange={event =>
              onAddressDataChange('mobile', event.target.value)
            }
            error={!mobileOk}
          />
        </div>
        <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
          <TextField
            label={t('email')}
            className={formStyles.input}
            value={addressData.email}
            onChange={event => onAddressDataChange('email', event.target.value)}
            error={!emailOk}
          />
          <TextField
            label={t('fax')}
            className={formStyles.input}
            value={addressData.fax}
            onChange={event => onAddressDataChange('fax', event.target.value)}
            error={!faxOk}
          />
        </div>
      </div>
    </>
  );
};

export default AddressContact;
