import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../components/list/Tabs';
import DriverList from '../components/DriverList';
import VehicleList from '../components/VehicleList';
import AccountList from '../components/AccountList';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import * as commonSelectors from '../../common/selectors';
import { UserRole } from '../../common/types';
import { OperatorRouteMap } from '../../../routes/root-route-map';
import { useHistory, useRouteMatch } from 'react-router-dom';

const RouteToTabMapping = {
  [OperatorRouteMap.driverList]: 0,
  [OperatorRouteMap.vehicleList]: 1,
  [OperatorRouteMap.accountList]: 2,
};

const TabToRouteMapping = Object.fromEntries(
  Object.entries(RouteToTabMapping).map(it => [it[1], it[0]])
);

const mapStateToProps = (state: RootState) => ({
  userRole: commonSelectors.role(state.common),
});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Home: React.FC<Props> = ({ userRole }) => {
  const { t } = useTranslation();

  const canEditAccounts =
    userRole === UserRole.PlantOperatorMaster || userRole === UserRole.Admin;

  const history = useHistory();
  const onChangeTab = (tab: number) => {
    history.push({ pathname: `${TabToRouteMapping[tab] ?? ''}` });
  };

  const match = useRouteMatch({
    path: Object.keys(RouteToTabMapping),
    exact: true,
  });

  const currentTab =
    match?.path != null ? RouteToTabMapping[match?.path] ?? 0 : 0;

  const allTabs = [t('drivers'), t('vehicles')];
  if (canEditAccounts) {
    allTabs.push(t('operators'));
  }

  if (currentTab === RouteToTabMapping[OperatorRouteMap.driverList]) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <DriverList />
      </>
    );
  } else if (currentTab === RouteToTabMapping[OperatorRouteMap.vehicleList]) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <VehicleList />
      </>
    );
  } else if (
    currentTab === RouteToTabMapping[OperatorRouteMap.accountList] &&
    canEditAccounts
  ) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <AccountList />
      </>
    );
  }
  return <span>index error</span>;
};

export default connect(mapStateToProps, dispatchProps)(Home);
