import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../../components/list/List';
import format from 'date-fns/format';
import {
  DriverOperatorListEntry,
  listDriversOperator,
} from '../../../generated/api/src';
import { driverOperatorListStoreKey } from '../../../store/store-keys';
import CustomerFilter from '../../common/components/CustomerFilter';
import ShippingAgentFilter from '../../common/components/ShippingAgentFilter';
import { RootRouteMap } from '../../../routes/root-route-map';
import formStyles from '../../../components/form/formStyles.module.scss';

const DriverList: React.FC = () => {
  const { t } = useTranslation();

  const [driverShippingAgentId, setDriverShippingAgentId] =
    useState<string>('default');
  const [driverCustomerId, setDriverCustomerId] = useState<string>('default');

  return (
    <>
      <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
        <CustomerFilter
          onCustomerIdChanged={setDriverCustomerId}
          defaultValue={'default'}
        />
        <ShippingAgentFilter
          onShippingAgentIdChanged={setDriverShippingAgentId}
          defaultValue={'default'}
        />
      </div>
      <List
        hasPagination={true}
        hasSearchBar={true}
        editEntityLink={`${RootRouteMap.plantOperator}edit-driver`}
        columnLabels={[
          { prop: 'name', label: t('name') },
          { prop: 'forename', label: t('forename') },
          { prop: 'adrExpiration', label: t('adrExpiration') },
          { prop: 'shippingAgentName', label: t('shippingAgent') },
          { prop: 'transponder', label: t('transponder') },
          { prop: 'active', label: t('clearance') },
        ]}
        columnLabelGrid={'1.75fr 1.75fr 1.75fr 1.75fr 1.75fr 0.75fr'}
        rowValueTransform={(obj: DriverOperatorListEntry) => [
          obj.name,
          obj.forename ?? '-',
          obj.adrExpiration
            ? format(obj.adrExpiration, t('dateFormatDateOnly'))
            : '-',
          obj.shippingAgentName,
          obj.transponder ?? '-',
          !!obj.active,
        ]}
        entityKey={driverOperatorListStoreKey}
        // @ts-ignore
        queryBuilder={listDriversOperator}
        key={'driverOperatorList'}
        additionalFilterProps={{
          ...(driverCustomerId !== 'default'
            ? { customerId: driverCustomerId }
            : {}),
          ...(driverShippingAgentId !== 'default'
            ? { shippingAgentId: driverShippingAgentId }
            : {}),
        }}
        searchProps={['name', 'forename', 'shippingAgentName', 'transponder']}
      />
    </>
  );
};

export default DriverList;
