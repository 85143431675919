import { createReducer } from 'typesafe-actions';
import {
  markAsFresh,
  markAsStale,
  markCategoryAsFresh,
  markCategoryAsStale,
} from './actions';
import { signOutAsync } from '../common/actions';

interface State {
  [key: string]: {
    [key: string]: boolean;
  };
}

const initialState = {} as State;

export const stalenessReducer = createReducer(initialState)
  .handleAction(markAsStale, (state, action) => ({
    ...state,
    [action.payload[0]]: {
      ...(state[action.payload[0]] ?? {}),
      ...Object.fromEntries(action.payload[1].map(it => [it, true])),
    },
  }))
  .handleAction(markAsFresh, (state, action) => ({
    ...state,
    [action.payload[0]]: {
      ...(state[action.payload[0]] ?? {}),
      ...Object.fromEntries(action.payload[1].map(it => [it, false])),
    },
  }))
  .handleAction(markCategoryAsStale, (state, action) => ({
    ...state,
    [action.payload]: Object.fromEntries(
      Object.entries(state[action.payload] ?? {}).map(it => [it[0], true])
    ),
  }))
  .handleAction(markCategoryAsFresh, (state, action) => ({
    ...state,
    [action.payload]: Object.fromEntries(
      Object.entries(state[action.payload] ?? {}).map(it => [it[0], false])
    ),
  }))
  .handleAction(signOutAsync.success, (state, action) => ({
    ...initialState,
  }));

export default stalenessReducer;
export type StalenessState = ReturnType<typeof stalenessReducer>;
