// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleCustomerListEntry,
    VehicleCustomerListEntryFromJSON,
    VehicleCustomerListEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleCustomerList
 */
export interface VehicleCustomerList  {
    /**
     * 
     * @type {Array<VehicleCustomerListEntry>}
     * @memberof VehicleCustomerList
     */
    entities: Array<VehicleCustomerListEntry>;
    /**
     * 
     * @type {number}
     * @memberof VehicleCustomerList
     */
    numTotal: number;
}

export function VehicleCustomerListFromJSON(json: any): VehicleCustomerList {
    return {
        'entities': (json['entities'] as Array<any>).map(VehicleCustomerListEntryFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function VehicleCustomerListToJSON(value?: VehicleCustomerList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(VehicleCustomerListEntryToJSON),
        'numTotal': value.numTotal,
    };
}


