// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    ShippingAgent,
    ShippingAgentFromJSON,
    ShippingAgentToJSON,
    ShippingAgentCreationResponse,
    ShippingAgentCreationResponseFromJSON,
    ShippingAgentCreationResponseToJSON,
    ShippingAgentList,
    ShippingAgentListFromJSON,
    ShippingAgentListToJSON,
    ShippingAgentMaxWeight,
    ShippingAgentMaxWeightFromJSON,
    ShippingAgentMaxWeightToJSON,
    ShippingAgentRequest,
    ShippingAgentRequestFromJSON,
    ShippingAgentRequestToJSON,
} from '../models';

export interface CreateShippingAgentRequest {
    shippingAgentRequest: ShippingAgentRequest;
}

export interface GetShippingAgentRequest {
    shippingAgentId: string;
}

export interface GetShippingAgentMaxWeightRequest {
    shippingAgentId: string;
}

export interface ListShippingAgentsRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListShippingAgentsSortDirEnum;
}

export interface UpdateShippingAgentRequest {
    shippingAgent: ShippingAgent;
}


/**
 * Creates a new shipping agent (Spediteur)
 */
function createShippingAgentRaw<T>(requestParameters: CreateShippingAgentRequest, requestConfig: runtime.TypedQueryConfig<T, ShippingAgentCreationResponse> = {}): QueryConfig<T> {
    if (requestParameters.shippingAgentRequest === null || requestParameters.shippingAgentRequest === undefined) {
        throw new runtime.RequiredError('shippingAgentRequest','Required parameter requestParameters.shippingAgentRequest was null or undefined when calling createShippingAgent.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/shippingAgent/create`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ShippingAgentRequestToJSON(requestParameters.shippingAgentRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ShippingAgentCreationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Creates a new shipping agent (Spediteur)
*/
export function createShippingAgent<T>(requestParameters: CreateShippingAgentRequest, requestConfig?: runtime.TypedQueryConfig<T, ShippingAgentCreationResponse>): QueryConfig<T> {
    return createShippingAgentRaw(requestParameters, requestConfig);
}

/**
 * Returns a shipping agents detail data.
 */
function getShippingAgentRaw<T>(requestParameters: GetShippingAgentRequest, requestConfig: runtime.TypedQueryConfig<T, ShippingAgent> = {}): QueryConfig<T> {
    if (requestParameters.shippingAgentId === null || requestParameters.shippingAgentId === undefined) {
        throw new runtime.RequiredError('shippingAgentId','Required parameter requestParameters.shippingAgentId was null or undefined when calling getShippingAgent.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/shippingAgent/get/{shippingAgentId}`.replace(`{${"shippingAgentId"}}`, encodeURIComponent(String(requestParameters.shippingAgentId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ShippingAgentFromJSON(body), text);
    }

    return config;
}

/**
* Returns a shipping agents detail data.
*/
export function getShippingAgent<T>(requestParameters: GetShippingAgentRequest, requestConfig?: runtime.TypedQueryConfig<T, ShippingAgent>): QueryConfig<T> {
    return getShippingAgentRaw(requestParameters, requestConfig);
}

/**
 * Returns the maximum vehicle weight of the customer attached to the given shipping agent
 */
function getShippingAgentMaxWeightRaw<T>(requestParameters: GetShippingAgentMaxWeightRequest, requestConfig: runtime.TypedQueryConfig<T, ShippingAgentMaxWeight> = {}): QueryConfig<T> {
    if (requestParameters.shippingAgentId === null || requestParameters.shippingAgentId === undefined) {
        throw new runtime.RequiredError('shippingAgentId','Required parameter requestParameters.shippingAgentId was null or undefined when calling getShippingAgentMaxWeight.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/shippingAgent/get/{shippingAgentId}/max-weight`.replace(`{${"shippingAgentId"}}`, encodeURIComponent(String(requestParameters.shippingAgentId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ShippingAgentMaxWeightFromJSON(body), text);
    }

    return config;
}

/**
* Returns the maximum vehicle weight of the customer attached to the given shipping agent
*/
export function getShippingAgentMaxWeight<T>(requestParameters: GetShippingAgentMaxWeightRequest, requestConfig?: runtime.TypedQueryConfig<T, ShippingAgentMaxWeight>): QueryConfig<T> {
    return getShippingAgentMaxWeightRaw(requestParameters, requestConfig);
}

/**
 * Returns all shipping agents
 */
function listShippingAgentsRaw<T>(requestParameters: ListShippingAgentsRequest, requestConfig: runtime.TypedQueryConfig<T, ShippingAgentList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/shippingAgent/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ShippingAgentListFromJSON(body), text);
    }

    return config;
}

/**
* Returns all shipping agents
*/
export function listShippingAgents<T>(requestParameters: ListShippingAgentsRequest, requestConfig?: runtime.TypedQueryConfig<T, ShippingAgentList>): QueryConfig<T> {
    return listShippingAgentsRaw(requestParameters, requestConfig);
}

/**
 * Update an existing Shipping Agent (Spediteur)
 */
function updateShippingAgentRaw<T>(requestParameters: UpdateShippingAgentRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.shippingAgent === null || requestParameters.shippingAgent === undefined) {
        throw new runtime.RequiredError('shippingAgent','Required parameter requestParameters.shippingAgent was null or undefined when calling updateShippingAgent.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/shippingAgent/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ShippingAgentToJSON(requestParameters.shippingAgent),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update an existing Shipping Agent (Spediteur)
*/
export function updateShippingAgent<T>(requestParameters: UpdateShippingAgentRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateShippingAgentRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListShippingAgentsSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
