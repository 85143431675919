// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
    CustomerList,
    CustomerListFromJSON,
    CustomerListToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface GetCustomerRequest {
    customerId: string;
}

export interface ListCustomersRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListCustomersSortDirEnum;
}

export interface UpdateCustomerRequest {
    customer: Customer;
}


/**
 * Returns a customers details.
 */
function getCustomerRaw<T>(requestParameters: GetCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, Customer> = {}): QueryConfig<T> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
        throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomer.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/get/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerFromJSON(body), text);
    }

    return config;
}

/**
* Returns a customers details.
*/
export function getCustomer<T>(requestParameters: GetCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, Customer>): QueryConfig<T> {
    return getCustomerRaw(requestParameters, requestConfig);
}

/**
 * Lists all customers (Abholer)
 */
function listCustomersRaw<T>(requestParameters: ListCustomersRequest, requestConfig: runtime.TypedQueryConfig<T, CustomerList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all customers (Abholer)
*/
export function listCustomers<T>(requestParameters: ListCustomersRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomerList>): QueryConfig<T> {
    return listCustomersRaw(requestParameters, requestConfig);
}

/**
 * Update a customer
 */
function updateCustomerRaw<T>(requestParameters: UpdateCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.customer === null || requestParameters.customer === undefined) {
        throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling updateCustomer.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerToJSON(requestParameters.customer),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update a customer
*/
export function updateCustomer<T>(requestParameters: UpdateCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateCustomerRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListCustomersSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
