import React from 'react';
import AddressContact, {
  AddressData,
} from '../../common/components/AddressContact';
import CollectorClearances, {
  ClearanceData,
  GlobaleClearanceData,
} from './CollectorClearances';

interface Props {
  addressData: AddressData;
  setAddressData: React.Dispatch<React.SetStateAction<AddressData>>;
  clearanceData: ClearanceData;
  setClearanceData: React.Dispatch<React.SetStateAction<ClearanceData>>;
  globalClearanceData: GlobaleClearanceData;
  setGlobalClearanceData: React.Dispatch<
    React.SetStateAction<GlobaleClearanceData>
  >;
  onValidation?: (valid: boolean) => void;
}

const CollectorForm: React.FC<Props> = ({
  addressData,
  setAddressData,
  clearanceData,
  setClearanceData,
  globalClearanceData,
  setGlobalClearanceData,
  onValidation,
}) => {
  return (
    <>
      <AddressContact
        addressData={addressData}
        setAddressData={setAddressData}
        onValidation={onValidation}
      />
      <CollectorClearances
        clearanceData={clearanceData}
        setClearanceData={setClearanceData}
        globalClearanceData={globalClearanceData}
        setGlobalClearanceData={setGlobalClearanceData}
        onValidation={onValidation}
      />
    </>
  );
};

export default CollectorForm;
