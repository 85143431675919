import { Button, OutlinedInput, Snackbar } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../components/form/PopUpStyles.module.scss';
import {
  CheckRounded,
  CopyAllRounded,
  SettingsSuggestRounded,
} from '@mui/icons-material';
import { useMutation } from 'redux-query-react';
import { setUserPassword } from '../../../generated/api/src';
import Loading from '../../../components/Loading';

function generatePassword(): string {
  let candidate = '';

  do {
    const availableCharacters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+-*_@§$%&/()[]{}';
    const pwLen = 64;
    const randomVals = new Uint8Array(pwLen);
    crypto.getRandomValues(randomVals);
    candidate = Array(...randomVals)
      .map(it => availableCharacters[Number(it) % availableCharacters.length])
      .join('');
    // this condition assure that we fulfill cognitos password requirements
  } while (
    !(
      candidate.match(/[a-zA-Z]/) != null &&
      candidate.match(/\d/) != null &&
      // eslint-disable-next-line no-useless-escape
      candidate.match(/[+\-*_@§\$%&\/\(\)\[\]\{\}]/) != null
    )
  );

  return candidate;
}

interface Props {
  setPasswordPopUp: Dispatch<SetStateAction<boolean>>;
  userId: string;
}

const PasswordPopUp: React.FC<Props> = ({ setPasswordPopUp, userId }) => {
  const [popupState, setPopupState] = useState<'info' | 'generate' | 'loading'>(
    'info'
  );
  const [copied, setCopied] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState<string>('');
  const { t } = useTranslation();

  const copyToClipboard = (e: React.MouseEvent<SVGSVGElement>) => {
    if (e.currentTarget) {
      navigator.clipboard.writeText(generatedPassword);
      setCopied(true);
    }
  };
  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopied(false);
  };

  const updatePasswordHandle = useMutation(() =>
    setUserPassword({
      accountPasswordUpdateRequest: {
        id: userId,
        password: generatedPassword,
        permanent: false,
      },
    })
  )[1];

  useEffect(() => {
    setGeneratedPassword(generatePassword());
  }, [setGeneratedPassword]);

  let content = <Loading />;
  switch (popupState) {
    case 'info':
      content = (
        <>
          <h3 className={styles.heading}>{t('generatePassword')}</h3>
          <p className={styles.body}>{t('generatePasswordBody')}</p>
          <div className={styles.buttons}>
            <Button color="secondary" onClick={() => setPasswordPopUp(false)}>
              {t('cancel')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginLeft: '1rem' }}
              startIcon={<SettingsSuggestRounded />}
              onClick={() => setPopupState('generate')}
            >
              {t('generate')}
            </Button>
          </div>
        </>
      );
      break;
    case 'generate':
      content = (
        <>
          <h3 className={styles.heading}>{t('generatePassword')}</h3>
          <OutlinedInput
            className={styles.input}
            value={generatedPassword}
            readOnly
            endAdornment={
              copied ? (
                <CheckRounded />
              ) : (
                <CopyAllRounded onClick={e => copyToClipboard(e)} />
              )
            }
          />
          <Snackbar
            open={copied}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={t('copiedToClipboard')}
          />
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setPasswordPopUp(false);
                setPopupState('info');
              }}
            >
              {t('abort')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              style={{ marginLeft: '1rem' }}
              onClick={() => {
                setPopupState('loading');
                updatePasswordHandle()?.then(() => {
                  setPopupState('info');
                  setPasswordPopUp(false);
                });
              }}
            >
              {t('apply')}
            </Button>
          </div>
        </>
      );
      break;
    case 'loading':
      content = <Loading />;
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>{content}</div>
    </div>
  );
};

export default PasswordPopUp;
