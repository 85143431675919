// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectorAccountCreationRequestAllOf
 */
export interface CollectorAccountCreationRequestAllOf  {
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectorAccountCreationRequestAllOf
     */
    customers: Array<string>;
}

export function CollectorAccountCreationRequestAllOfFromJSON(json: any): CollectorAccountCreationRequestAllOf {
    return {
        'customers': json['customers'],
    };
}

export function CollectorAccountCreationRequestAllOfToJSON(value?: CollectorAccountCreationRequestAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'customers': value.customers,
    };
}


