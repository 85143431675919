import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  listVehiclesCustomer,
  VehicleCustomerListEntry,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import format from 'date-fns/format';
import { vehicleCustomerListStoreKey } from '../../../store/store-keys';
import CustomerFilter from '../../common/components/CustomerFilter';
import ShippingAgentFilter from '../../common/components/ShippingAgentFilter';
import { RootRouteMap } from '../../../routes/root-route-map';
import formStyles from '../../../components/form/formStyles.module.scss';

const VehicleList: React.FC = () => {
  const { t } = useTranslation();

  const [vehicleShippingAgentId, setVehicleShippingAgentId] =
    useState<string>('default');
  const [vehicleCustomerId, setVehicleCustomerId] = useState<string>('default');

  const addVehicleBtn = (
    <Link to={`${RootRouteMap.customer}add-vehicle/`}>
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addVehicle')}
      </Button>
    </Link>
  );

  return (
    <>
      <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
        <CustomerFilter
          onCustomerIdChanged={setVehicleCustomerId}
          defaultValue={'default'}
        />
        <ShippingAgentFilter
          onShippingAgentIdChanged={setVehicleShippingAgentId}
          defaultValue={'default'}
        />
      </div>
      <List
        hasPagination={true}
        hasSearchBar={true}
        addEntitySlot={addVehicleBtn}
        editEntityLink={`${RootRouteMap.customer}edit-vehicle`}
        columnLabels={[
          { prop: 'licencePlateTruck', label: t('licencePlateTruckListLabel') },
          { prop: 'ggvsB3Truck', label: t('adrExpirationTruckListLabel') },
          {
            prop: 'licencePlateTrailer',
            label: t('licencePlateTrailerListLabel'),
          },
          { prop: 'ggvsB3Trailer', label: t('adrExpirationTrailerListLabel') },
          { prop: 'shippingAgentName', label: t('shippingAgent') },
          { prop: 'productName', label: t('product') },
          { prop: 'medOk', label: t('medicalClearanceListLabel') },
        ]}
        columnLabelGrid={'1fr 1fr 1fr 1fr 1.5fr 1fr 0.5fr'}
        rowValueTransform={(obj: VehicleCustomerListEntry) => [
          obj.licencePlateTruck,
          obj.ggvsB3Truck
            ? format(obj.ggvsB3Truck, t('dateFormatDateOnly'))
            : '-',
          obj.licencePlateTrailer,
          obj.ggvsB3Trailer
            ? format(obj.ggvsB3Trailer, t('dateFormatDateOnly'))
            : '-',
          obj.shippingAgentName,
          obj.productName,
          obj.medOk,
        ]}
        entityKey={vehicleCustomerListStoreKey}
        // @ts-ignore
        queryBuilder={listVehiclesCustomer}
        key={'vehicleCustomerList'}
        additionalFilterProps={{
          ...(vehicleCustomerId !== 'default'
            ? { customerId: vehicleCustomerId }
            : {}),
          ...(vehicleShippingAgentId !== 'default'
            ? { shippingAgentId: vehicleShippingAgentId }
            : {}),
        }}
        searchProps={[
          'licencePlateTrailer',
          'licencePlateTruck',
          'shippingAgentName',
        ]}
      />
    </>
  );
};

export default VehicleList;
