// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatorAccount,
    OperatorAccountFromJSON,
    OperatorAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperatorAccountList
 */
export interface OperatorAccountList  {
    /**
     * 
     * @type {Array<OperatorAccount>}
     * @memberof OperatorAccountList
     */
    entities: Array<OperatorAccount>;
    /**
     * 
     * @type {number}
     * @memberof OperatorAccountList
     */
    numTotal: number;
}

export function OperatorAccountListFromJSON(json: any): OperatorAccountList {
    return {
        'entities': (json['entities'] as Array<any>).map(OperatorAccountFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function OperatorAccountListToJSON(value?: OperatorAccountList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(OperatorAccountToJSON),
        'numTotal': value.numTotal,
    };
}


