// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VehicleOperatorListEntry,
    VehicleOperatorListEntryFromJSON,
    VehicleOperatorListEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleOperatorList
 */
export interface VehicleOperatorList  {
    /**
     * 
     * @type {Array<VehicleOperatorListEntry>}
     * @memberof VehicleOperatorList
     */
    entities: Array<VehicleOperatorListEntry>;
    /**
     * 
     * @type {number}
     * @memberof VehicleOperatorList
     */
    numTotal: number;
}

export function VehicleOperatorListFromJSON(json: any): VehicleOperatorList {
    return {
        'entities': (json['entities'] as Array<any>).map(VehicleOperatorListEntryFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function VehicleOperatorListToJSON(value?: VehicleOperatorList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(VehicleOperatorListEntryToJSON),
        'numTotal': value.numTotal,
    };
}


