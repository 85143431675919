import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './PageHeader.module.scss';

interface Props {
  linkUrl: string;
  linkText: string;
  heading: string;
}
const PageHeader: React.FC<Props> = ({ linkUrl, linkText, heading }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link to={linkUrl} className={styles.link}>
        <ArrowBack />
        {t('all') + ' ' + linkText}
      </Link>
      <h1>{heading}</h1>
    </div>
  );
};

export default PageHeader;
