import React from 'react';

const Loading: React.FC = () => {
  return (
    <svg height="100" width="100" viewBox="0 0 100 100">
      <circle cx="30" cy="30" r="5" fill="#eb6617" opacity="0.7">
        <animate
          attributeName="cy"
          values="70;40;70"
          dur="1s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="50" cy="50" r="5" fill="#eb6617" opacity="0.7">
        <animate
          attributeName="cy"
          values="70;40;70"
          dur="1.1s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="70" cy="70" r="5" fill="#eb6617" opacity="0.7">
        <animate
          attributeName="cy"
          values="70;40;70"
          dur="1.2s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default Loading;
