import React from 'react';
import {
  CustomerFullListEntry,
  listCustomersFull,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import { useTranslation } from 'react-i18next';
import { collectorScmListStoreKey } from '../../../store/store-keys';

const CollectorList: React.FC = () => {
  const { t } = useTranslation();

  const addCustomerBtn = (
    <Link to="/scm/add-collector/">
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addCollector')}
      </Button>
    </Link>
  );

  return (
    <List
      hasPagination={true}
      hasSearchBar={true}
      addEntitySlot={addCustomerBtn}
      editEntityLink="/scm/edit-collector"
      columnLabels={[
        { prop: 'name', label: t('name') },
        { prop: 'town', label: t('city') },
        { prop: 'active', label: t('clearanceAllListLabel') },
        { prop: 'clearanceProperty', label: t('clearanceProperty') },
      ]}
      columnLabelGrid="2.5fr 1.75fr 0.75fr 5fr"
      columnValueGrid="2.5fr 1.75fr 0.75fr 0.25fr 0.7fr 0.25fr 1fr 0.25fr 0.55fr 0.25fr 0.75fr 0.25fr 0.75fr"
      rowValueTransform={(obj: CustomerFullListEntry) => [
        obj.name,
        obj.town ? obj.town : '-',
        obj.active,
        obj.clearances[0]?.clearance,
        obj.clearances[0]?.plantName,
        obj.clearances[1]?.clearance,
        obj.clearances[1]?.plantName,
        obj.clearances[2]?.clearance,
        obj.clearances[2]?.plantName,
        obj.clearances[3]?.clearance,
        obj.clearances[3]?.plantName,
        obj.clearances[4]?.clearance,
        obj.clearances[4]?.plantName,
      ]}
      entityKey={collectorScmListStoreKey}
      // @ts-ignore
      queryBuilder={listCustomersFull}
    />
  );
};

export default CollectorList;
