import { Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../../../components/form/PopUpStyles.module.scss';
import Send from '@mui/icons-material/SendRounded';
import {
  AutoAwesomeRounded,
  ErrorRounded,
  MailRounded,
} from '@mui/icons-material';
import { useMutation } from 'redux-query-react';
import { resendUserInvitation } from '../../../generated/api/src';
import Loading from '../../../components/Loading';

interface Props {
  email: string;
  setInvitationPopUp: Dispatch<SetStateAction<boolean>>;
  userId: string;
}

const InvitationPopUp: React.FC<Props> = ({
  email,
  setInvitationPopUp,
  userId,
}) => {
  const [popupState, setPopupState] = useState<
    'info' | 'send' | 'loading' | 'errorGeneric' | 'errorInvalid'
  >('info');

  const { t } = useTranslation();

  const resendInviteHandle = useMutation(() =>
    resendUserInvitation({
      accountInvitationResendRequest: {
        id: userId,
      },
    })
  )[1];

  let content = <></>;
  switch (popupState) {
    case 'info':
      content = (
        <>
          <h3 className={styles.heading}>{t('sendInvitation')}</h3>
          <p className={styles.body}>
            <Trans i18nKey="sendInvitationBody">
              E-Mail mit Link zur Registrierung an <strong>{{ email }}</strong>{' '}
              verschicken?
            </Trans>
          </p>
          <div className={styles.buttons}>
            <Button color="secondary" onClick={() => setInvitationPopUp(false)}>
              {t('cancel')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginLeft: '1rem' }}
              startIcon={<Send />}
              onClick={() => {
                setPopupState('loading');
                resendInviteHandle()?.then(response => {
                  if (response.status === 200 || response.status === 204) {
                    setPopupState('send');
                  } else if (
                    (response.body?.error || '').endsWith(
                      'status is not FORCE_CHANGE_PASSWORD'
                    )
                  ) {
                    setPopupState('errorInvalid');
                  } else {
                    setPopupState('errorGeneric');
                  }
                });
              }}
            >
              {t('send')}
            </Button>
          </div>
        </>
      );
      break;
    case 'send':
      content = (
        <>
          <div className={styles.icons}>
            <AutoAwesomeRounded />
            <MailRounded />
          </div>
          <p className={styles.body}>{t('emailSend')}</p>
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setPopupState('info');
                setInvitationPopUp(false);
              }}
            >
              {t('close')}
            </Button>
          </div>
        </>
      );
      break;
    case 'loading':
      content = <Loading />;
      break;
    case 'errorInvalid':
      content = (
        <>
          <div className={styles.icons}>
            <ErrorRounded />
          </div>
          <p className={styles.body}>{t('resendInvitationInvalid')}</p>
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setPopupState('info');
                setInvitationPopUp(false);
              }}
            >
              {t('close')}
            </Button>
          </div>
        </>
      );
      break;
    case 'errorGeneric':
      content = (
        <>
          <div className={styles.icons}>
            <ErrorRounded />
          </div>
          <p className={styles.body}>{t('resendInvitationFailed')}</p>
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setPopupState('info');
                setInvitationPopUp(false);
              }}
            >
              {t('close')}
            </Button>
          </div>
        </>
      );
      break;
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>{content}</div>
    </div>
  );
};

export default InvitationPopUp;
