import React, { useEffect, useState } from 'react';
import { I18n } from 'aws-amplify';
import { Hub, HubCallback } from '@aws-amplify/core';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {
  Translations,
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from '@aws-amplify/ui-components';
import AuthAlert from './components/AuthAlert';
import Container from '@mui/material/Container';
import styles from './CustomAuthenticator.module.scss';
import logo from '../../assets/images/westfalen_logo.svg';
import Spacer from '../../components/Spacer';

const passwordTips =
  'Ihr Passwort muss mindestens 8 Zeichen lang sein, Klein- und Großbuchstaben, sowie Zahlen und Sonderzeichen enthalten.';
I18n.putVocabulariesForLanguage('de-DE', {
  [Translations.USERNAME_LABEL]: 'Nutzername',
  [Translations.USERNAME_PLACEHOLDER]: 'Ihre E-Mail-Adresse',
  [Translations.PASSWORD_LABEL]: 'Passwort',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ihr Passwort',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
  [Translations.SIGN_IN_ACTION]: 'Anmelden',
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
  [Translations.SEND_CODE]: 'Code senden',
  [Translations.RESET_YOUR_PASSWORD]: 'Ihr Passwort zurücksetzen',
  [Translations.CODE_LABEL]: 'Einmalcode',
  [Translations.CODE_PLACEHOLDER]: 'Ihr Einmalcode...',
  [Translations.NEW_PASSWORD_LABEL]: 'Passwort',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Ihr neues Passwort...',
  [Translations.SUBMIT]: 'Bestätigen',
  [Translations.CHANGE_PASSWORD]: 'Passwort ändern',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Ändern',
  'Password does not conform to policy: Password not long enough': passwordTips,
  'Password does not conform to policy: Password must have lowercase characters':
    passwordTips,
  'Password does not conform to policy: Password must have uppercase characters':
    passwordTips,
  'Password does not conform to policy: Password must have symbol characters':
    passwordTips,
  'Password does not conform to policy: Password must have numeric characters':
    passwordTips,
  "Password does not conform to policy: 1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
    passwordTips,
});
I18n.setLanguage('de-DE');

const CustomAuthenticator: React.FC = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState('');

  const handleToastErrors: HubCallback = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      setAlertMessage(payload.message);
    }
  };

  useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  });

  return (
    <Container>
      <div className={styles.container}>
        <img className={styles.logo} src={logo} alt="Logo der Westfalen AG" />
        <Spacer />
        <AmplifyAuthenticator hideToast>
          <AmplifySignIn headerText="PAS" slot="sign-in" hideSignUp />
          {children}
        </AmplifyAuthenticator>
        {alertMessage && <AuthAlert alert={alertMessage} />}
      </div>
    </Container>
  );
};

export default CustomAuthenticator;
