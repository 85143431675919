// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleOperatorListEntryAllOf
 */
export interface VehicleOperatorListEntryAllOf  {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleOperatorListEntryAllOf
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleOperatorListEntryAllOf
     */
    transponder?: string|null;
}

export function VehicleOperatorListEntryAllOfFromJSON(json: any): VehicleOperatorListEntryAllOf {
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'transponder': !exists(json, 'transponder') ? undefined : json['transponder'],
    };
}

export function VehicleOperatorListEntryAllOfToJSON(value?: VehicleOperatorListEntryAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active': value.active,
        'transponder': value.transponder,
    };
}


