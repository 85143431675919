// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccountCreationRequest,
    AccountCreationRequestFromJSON,
    AccountCreationRequestToJSON,
} from './';

/**
 * @type ScmAccountCreationRequest
 * @export
 */
export interface ScmAccountCreationRequest extends AccountCreationRequest {
}

export function ScmAccountCreationRequestFromJSON(json: any): ScmAccountCreationRequest {
    return {
        ...AccountCreationRequestFromJSON(json),
    };
}

export function ScmAccountCreationRequestToJSON(value?: ScmAccountCreationRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AccountCreationRequestToJSON(value),
    };
}
