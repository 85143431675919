// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerFullListEntryAllOfClearances
 */
export interface CustomerFullListEntryAllOfClearances  {
    /**
     * 
     * @type {string}
     * @memberof CustomerFullListEntryAllOfClearances
     */
    plantName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFullListEntryAllOfClearances
     */
    clearance: boolean;
}

export function CustomerFullListEntryAllOfClearancesFromJSON(json: any): CustomerFullListEntryAllOfClearances {
    return {
        'plantName': json['plantName'],
        'clearance': json['clearance'],
    };
}

export function CustomerFullListEntryAllOfClearancesToJSON(value?: CustomerFullListEntryAllOfClearances): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plantName': value.plantName,
        'clearance': value.clearance,
    };
}


