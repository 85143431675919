import { Container } from '@mui/material';
import Amplify from 'aws-amplify';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import AuthGuard from './components/AuthGuard';
import Error from './components/Error';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import * as commonActions from './features/common/actions';
import * as commonSelectors from './features/common/selectors';
import Routes from './routes';
import { history } from './store';
import AmplifyConfig from './util/amplifyConfig';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ScmBar from './components/ScmBar';
import CustomAuthenticator from './features/authentication/CustomAuthenticator';
import ChangelogPopUp from './components/ChangelogPopUp';

Amplify.configure(AmplifyConfig);

const mapStateToProps = (state: RootState) => ({
  user: commonSelectors.user(state.common),
  id: commonSelectors.id(state.common),
  role: commonSelectors.role(state.common),
  userError: commonSelectors.userError(state.common),
  assumedRole: commonSelectors.assumedRole(state.common),
});

const dispatchProps = {
  signOut: commonActions.signOutAsync.request,
  setAssumedRole: commonActions.assumeRoleAsync.request,
  setLanguage: commonActions.setLanguage,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
export const getQueries = (state: any) => state.queries;

const App: React.FC<Props> = ({
  user,
  role,
  id,
  userError,
  signOut,
  setLanguage,
  setAssumedRole,
  assumedRole,
}) => (
  <ConnectedRouter history={history}>
    <ReduxQueryProvider queriesSelector={getQueries}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Suspense fallback={<Loading />}>
          <AuthGuard />
          {userError ? (
            <>
              <Navbar
                user={user}
                onSignOut={signOut}
                onLanguageSelect={setLanguage}
              />
              <Error error={userError.message} />
            </>
          ) : !!user && !!id ? (
            <>
              <Navbar
                user={user}
                userRole={role}
                onSignOut={signOut}
                onLanguageSelect={setLanguage}
                onAssumeRole={setAssumedRole}
              />
              <ScmBar userRole={assumedRole?.role ?? role} />
              <div
                style={{
                  marginLeft: 'calc(100vw - 100%)',
                  marginRight: '0',
                  marginTop: '2.5rem',
                  // white:space 'pre-line' allows us to use \n in translations
                  whiteSpace: 'pre-line',
                }}
              >
                <Container>
                  <Routes userRole={role} />
                </Container>
                <ChangelogPopUp userId={id} />
              </div>
            </>
          ) : (
            <CustomAuthenticator />
          )}
        </Suspense>
      </LocalizationProvider>
    </ReduxQueryProvider>
  </ConnectedRouter>
);

export default connect(mapStateToProps, dispatchProps)(App);
