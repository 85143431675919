const LicencePlateRegex = /^\S(?:.{0,18}\S)?$/gm;

export const ValidationRegex = {
  Vehicle: {
    LicencePlateTruck: LicencePlateRegex,
    LicencePlateTrailer: LicencePlateRegex,
    Transponder: /^\S(?:.{0,8}\S)?$/,
  },
  Driver: {
    Forename: /^\S(?:.{0,78}\S)?$/,
    Name: /^\S(?:.{0,78}\S)?$/,
    Transponder: /^\S(?:.{0,8}\S)?$/,
  },
  AddressData: {
    CountryIso3166Alpha2: /^[a-zA-Z]{2}$/,
    ZipCode: /^\S(?:.{0,78}\S)?$/,
    Town: /^\S(?:.{0,78}\S)?$/,
    Phone: /^\S(?:.{0,78}\S)?$|^$/,
    Mobile: /^\S(?:.{0,78}\S)?$|^$/,
    Fax: /^\S(?:.{0,78}\S)?$|^$/,
    Name: /^\S(?:.{0,78}\S)?$|^$/,
    Street: /^\S(?:.{0,78}\S)?$/,
    // for email: see https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    Email:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$|^$/gm,
  },
  CustomerClearance: {
    SapNumber: /^\S(?:.{0,18}\S)?$|^$/,
  },
};
