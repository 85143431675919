// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerClearanceRequest
 */
export interface CustomerClearanceRequest  {
    /**
     * 
     * @type {string}
     * @memberof CustomerClearanceRequest
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerClearanceRequest
     */
    plantId: string;
    /**
     * SQL: Nur_Einfahrt
     * @type {boolean}
     * @memberof CustomerClearanceRequest
     */
    accessOnly: boolean;
    /**
     * SQL: Verladefreigabe
     * @type {boolean}
     * @memberof CustomerClearanceRequest
     */
    loadingClearance: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerClearanceRequest
     */
    sapNumber: string;
}

export function CustomerClearanceRequestFromJSON(json: any): CustomerClearanceRequest {
    return {
        'customerId': json['customerId'],
        'plantId': json['plantId'],
        'accessOnly': json['accessOnly'],
        'loadingClearance': json['loadingClearance'],
        'sapNumber': json['sapNumber'],
    };
}

export function CustomerClearanceRequestToJSON(value?: CustomerClearanceRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'customerId': value.customerId,
        'plantId': value.plantId,
        'accessOnly': value.accessOnly,
        'loadingClearance': value.loadingClearance,
        'sapNumber': value.sapNumber,
    };
}


