// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Product
 */
export interface Product  {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
}

export function ProductFromJSON(json: any): Product {
    return {
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ProductToJSON(value?: Product): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
    };
}


