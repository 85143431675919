import React, { useState } from 'react';
import styles from './ChangelogPopUp.module.scss';
import IconClose from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import horse from '../assets/images/horse.svg';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const VERSIONS: Record<string, (t: TFunction) => JSX.Element> = {
  '2021.12.14': (t: TFunction) => {
    return (
      <Trans i18nKey={'changelog-2021-12-14'} t={t}>
        <h1>Title</h1>
        Introtext
        <ul>
          <li>fix input dates</li>
          <li>operator: nullable dates</li>
          <li>load more entities</li>
          <li>fix for broken entity save</li>
        </ul>
        update outro
        <h3>greetings</h3>
      </Trans>
    );
  },
  '2021.12.22': (t: TFunction) => {
    return (
      <Trans i18nKey={'changelog-2021-12-22'} t={t}>
        <h1>Title</h1>
        Introtext
        <h3>greetings</h3>
      </Trans>
    );
  },
  '2022.01.11': (t: TFunction) => {
    return (
      <Trans i18nKey={'changelog-2022-01-11'} t={t}>
        <h1>Title</h1>
        Introtext
        <h3>greetings</h3>
      </Trans>
    );
  },
  '2022.04.01': (t: TFunction) => {
    return (
      <Trans i18nKey={'changelog-2022-04-01'} t={t}>
        <h1>Title</h1>
        Introtext
        <h3>greetings</h3>
      </Trans>
    );
  },
};

const ChangelogPopUp: React.FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation();

  const localStoreKey = `pas-last-seen-version-${userId}`;
  const newestVersion = Object.keys(VERSIONS).sort().reverse()[0];
  const newestChangelog = VERSIONS[newestVersion](t);
  const [close, setClose] = useState(
    localStorage.getItem(localStoreKey) === newestVersion
  );

  React.useEffect(() => {
    if (close) {
      localStorage.setItem(localStoreKey, newestVersion);
    }
  });

  return (
    <div
      className={classNames(styles.background, { [styles.displayNone]: close })}
    >
      <div className={styles.container}>
        <div className={styles.close}>
          <IconButton
            onClick={() => {
              setClose(true);
            }}
          >
            <IconClose />
          </IconButton>
        </div>

        <div className={styles.content}>
          <span className={styles.illu}>
            <img src={horse} alt="" />
          </span>
          <div className={styles.text}>{newestChangelog}</div>
        </div>
      </div>
    </div>
  );
};

export default ChangelogPopUp;
