import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/form/PageHeader';
import PageContainer from '../../../components/form/PageContainer';
import formStyles from '../../../components/form/formStyles.module.scss';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import EditButtonRow from '../../../components/form/EditButtonRow';
import {
  AccountCreationRequestAccountTypeEnum,
  createUserAccount,
  Customer,
  listCustomers,
  Plant,
} from '../../../generated/api/src';
import TypeaheadMultiEntitySelect from '../../common/components/TypeaheadMultiEntitySelect';
import { useMutation } from 'redux-query-react';
import Loading from '../../../components/Loading';
import { Link } from 'react-router-dom';
import PlantMultiSelect from '../components/PlantMultiSelect';
import * as stalenessActions from '../../staleness/actions';
import { connect } from 'react-redux';
import {
  accountScmListStoreKey,
  customerFilterStoreKey,
} from '../../../store/store-keys';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import Spacer from '../../../components/Spacer';
import classnames from 'classnames';

interface ComponentProps {
  allowedRoles: AccountCreationRequestAccountTypeEnum[];
  returnLinkTarget: string;
}

const mapStateToProps = () => ({});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const AddAccount: React.FC<Props> = ({
  allowedRoles,
  returnLinkTarget,
  markListAsStale,
}) => {
  const { t } = useTranslation();
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState(false);
  const [role, setRole] = useState<AccountCreationRequestAccountTypeEnum>(
    allowedRoles[0]
  );
  const [sendInvitationMail, setSendInvitationMail] = useState(true);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [selectedPlants, setSelectedPlants] = useState<Plant[]>([]);
  const [operatorIsAdmin, setOperatorIsAdmin] = useState<boolean>(false);

  const returnLink = useRef<HTMLAnchorElement | null>(null);

  const emailValidation = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      // see see https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
      new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ).test(e.currentTarget.value)
    ) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const [{ isPending: accountCreationPending }, createAccountHandle] =
    useMutation(() =>
      createUserAccount({
        scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest:
          {
            email,
            accountType: role,
            sendInvitation: sendInvitationMail,
            ...(role === AccountCreationRequestAccountTypeEnum.Collector
              ? { customers: selectedCustomers.map(it => it.id) }
              : {}),
            ...(role === AccountCreationRequestAccountTypeEnum.Operator
              ? {
                  plants: selectedPlants.map(it => it.id),
                  shouldBeAdmin: operatorIsAdmin,
                }
              : {}),
          },
      })
    );

  let additionalSettings = <></>;
  if (role === AccountCreationRequestAccountTypeEnum.Collector) {
    additionalSettings = (
      <>
        <h2>{t('assignedCollectors')}</h2>
        <div className={formStyles.inputContainer}>
          <TypeaheadMultiEntitySelect
            entitySearchResultStoreKey={'accountCreationCustomerSearch'}
            onSelectedEntitiesChanges={setSelectedCustomers}
            selectedEntities={selectedCustomers}
            // @ts-ignore
            queryBuilder={listCustomers}
            getOptionLabel={option => `${option.name} (${option.id})`}
            multiSelect={true}
          />
        </div>
      </>
    );
  } else if (role === AccountCreationRequestAccountTypeEnum.Operator) {
    additionalSettings = (
      <>
        <h2>{t('assignedPlants')}</h2>
        <div className={formStyles.inputContainer}>
          <PlantMultiSelect
            selectedEntities={selectedPlants}
            onSelectedEntitiesChanges={setSelectedPlants}
          />
        </div>
      </>
    );
  }

  const content = () => {
    return (
      <>
        <div className={formStyles.section}>
          <h2>{t('accountData')}</h2>
          <div className={formStyles.inputContainer}>
            <TextField
              label={t('email')}
              className={formStyles.input}
              value={email}
              onChange={e => setEmail(e.target.value)}
              onBlur={e => emailValidation(e)}
              error={emailError}
              helperText={emailError ? t('invalidEmail') : null}
              autoFocus
            />
          </div>
          <div
            className={classnames(formStyles.inputContainer, {
              [formStyles.double]:
                role === AccountCreationRequestAccountTypeEnum.Operator,
            })}
          >
            <FormControl className={formStyles.input}>
              <InputLabel
                id="role-select-label"
                className={formStyles.specialLabel}
              >
                {t('role')}
              </InputLabel>
              <Select
                id="role-select"
                labelId="role-select-label"
                label={t('role')}
                value={role}
                onChange={event => {
                  setRole(
                    event.target.value as AccountCreationRequestAccountTypeEnum
                  );
                }}
              >
                {allowedRoles.map(it => (
                  <MenuItem value={it} key={`role-select-${it}`}>
                    {it === 'Collector'
                      ? t('collector')
                      : it === 'Operator'
                      ? t('operator')
                      : t('Scm')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {role === AccountCreationRequestAccountTypeEnum.Operator && (
              <FormControl>
                <div
                  className={formStyles.outlined}
                  style={{ paddingLeft: '14px' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="success"
                        onChange={event =>
                          setOperatorIsAdmin(event.target.checked)
                        }
                      />
                    }
                    label={t('admin')}
                    checked={operatorIsAdmin}
                  />
                </div>
              </FormControl>
            )}
          </div>
        </div>

        {additionalSettings}

        <Spacer />

        <FormControl>
          <div
            className={formStyles.outlined}
            style={{
              paddingLeft: '14px',
              height: '56px',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  onChange={event =>
                    setSendInvitationMail(event.target.checked)
                  }
                />
              }
              label={t('sendInvitationMail')}
              checked={sendInvitationMail}
            />
          </div>
        </FormControl>

        <RequestErrorSnackbar
          error={creationError}
          onClose={() => setCreationError(null)}
        />
        <EditButtonRow
          onCancel={() => returnLink.current?.click()}
          onSave={() => {
            markListAsStale(accountScmListStoreKey);
            markListAsStale(customerFilterStoreKey);
            createAccountHandle()?.then(resp => {
              if (resp.status > 299) {
                setCreationError(resp);
              } else {
                returnLink?.current?.click();
              }
            });
          }}
          disableSave={!email || emailError || !role}
        />
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader
        linkUrl={returnLinkTarget}
        linkText={t('accounts')}
        heading={t('addAccount')}
      />
      {accountCreationPending ? <Loading /> : content()}

      <Link to={returnLinkTarget} ref={returnLink} />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(AddAccount);
