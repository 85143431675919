// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatorAccountAllOfPlants,
    OperatorAccountAllOfPlantsFromJSON,
    OperatorAccountAllOfPlantsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperatorAccountAllOf
 */
export interface OperatorAccountAllOf  {
    /**
     * 
     * @type {Array<OperatorAccountAllOfPlants>}
     * @memberof OperatorAccountAllOf
     */
    plants: Array<OperatorAccountAllOfPlants>;
}

export function OperatorAccountAllOfFromJSON(json: any): OperatorAccountAllOf {
    return {
        'plants': (json['plants'] as Array<any>).map(OperatorAccountAllOfPlantsFromJSON),
    };
}

export function OperatorAccountAllOfToJSON(value?: OperatorAccountAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plants': (value.plants as Array<any>).map(OperatorAccountAllOfPlantsToJSON),
    };
}


