import React from 'react';
import styles from './AuthAlert.module.scss';
import Warning from '@mui/icons-material/Warning';

type Props = {
  alert?: string;
};

const AuthAlert: React.FC<Props> = ({ alert }) => {
  return (
    <div className={styles.container}>
      <Warning />
      {alert}
    </div>
  );
};

export default AuthAlert;
