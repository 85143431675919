import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import EditCollector from '../features/scm/screens/EditCollector';
import Home from '../features/scm/screens/Home';
import AddCollector from '../features/scm/screens/AddCollector';
import AddAccount from '../features/scm/screens/AddAccount';
import EditAccount from '../features/scm/screens/EditAccount';
import { AccountCreationRequestAccountTypeEnum } from '../generated/api/src';
import { ScmRouteMap } from './root-route-map';

const Routes: React.FC = () => {
  const path = useRouteMatch().path;

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}collectors`} />}
      />
      <Route exact path={Object.values(ScmRouteMap)} component={Home} />

      <Route exact path={`${path}add-collector`} component={AddCollector} />
      <Route
        exact
        path={`${path}edit-collector/:id`}
        component={EditCollector}
      />
      <Route
        exact
        path={`${path}add-account`}
        component={() => (
          <AddAccount
            allowedRoles={[
              AccountCreationRequestAccountTypeEnum.Collector,
              AccountCreationRequestAccountTypeEnum.Scm,
            ]}
            returnLinkTarget={ScmRouteMap.accountList}
          />
        )}
      />
      <Route
        exact
        path={`${path}edit-account/:id`}
        component={() => (
          <EditAccount returnLinkTarget={ScmRouteMap.accountList} />
        )}
      />
    </Switch>
  );
};

export default Routes;
