import React from 'react';
import VehicleMaxWeightInput from './VehicleMaxWeightInput';

interface Props {
  weight: number;
}

const VehicleMaxWeightPlaceholder: React.FC<Props> = ({ weight }) => {
  return (
    <VehicleMaxWeightInput
      maxWeight={0}
      weight={weight}
      onSetWeight={() => {}}
      disabled={true}
    />
  );
};

export default VehicleMaxWeightPlaceholder;
