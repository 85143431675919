import { createAction, createAsyncAction } from 'typesafe-actions';
import { UserData } from './types';
import { actionTypes } from 'redux-query';

export const signedInAsync = createAsyncAction(
  'SIGNED_IN_REQUEST',
  'SIGNED_IN_SUCCESS',
  'SIGNED_IN_FAILURE'
)<undefined, UserData, Error>();

export const assumeRoleAsync = createAsyncAction(
  'ASSUME_ROLE_REQUEST',
  'ASSUME_ROLE_SUCCESS',
  'ASSUME_ROLE_FAILURE'
)<UserData | null, UserData | null, Error>();

export const signOutAsync = createAsyncAction(
  'SIGN_OUT_REQUEST',
  'SIGN_OUT_SUCCESS',
  'SIGN_OUT_FAILURE'
)<undefined, undefined, Error>();

export const setLanguage = createAction('SET_LANGUAGE')<string>();

export const clearRequestCache = createAction(actionTypes.RESET)<void>();
