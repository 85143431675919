import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import { Link, useParams } from 'react-router-dom';

import {
  Customer,
  getCustomer,
  updateCustomer,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import AddressContact, {
  AddressData,
} from '../../common/components/AddressContact';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { useTranslation } from 'react-i18next';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import {
  customerCustomerStoreKey,
  customerCustomerViewListStoreKey,
  customerFilterStoreKey,
  customerSelectStoreKey,
} from '../../../store/store-keys';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditCollector: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const { id: customerId } = useParams<{ id: string }>();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<AddressData>({
    country: '',
    email: '',
    fax: '',
    language: '',
    mobile: '',
    street: '',
    telephone: '',
    town: '',
    zipCode: '',
    name: '',
  });

  const [{ isPending: customerUpdatePending }, updateCustomerHandle] =
    useMutation((addr: AddressData, customerId: string) =>
      updateCustomer({
        customer: {
          id: customerId,
          name: addr.name,
          country: addr.country,
          email: addr.email !== '' ? addr.email : undefined,
          fax: addr.fax !== '' ? addr.fax : undefined,
          language: addr.language !== '' ? addr.language : undefined,
          mobile: addr.mobile !== '' ? addr.mobile : undefined,
          street: addr.street,
          telephone: addr.telephone !== '' ? addr.telephone : undefined,
          town: addr.town,
          zipCode: addr.zipCode,
        },
      })
    );

  const customerQueryConfig = getCustomer(
    { customerId },
    {
      transform: body => {
        return { [customerCustomerStoreKey]: { [body.id]: body } };
      },
      update: {
        [customerCustomerStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(customerCustomerStoreKey, customerId),
    }
  );

  const [{ isPending: customerPending, lastUpdated: customerUpdateTs }] =
    useRequest(customerQueryConfig);

  let customerEntity = useSelector((state: any) => {
    return (state.entities?.[customerCustomerStoreKey]?.[customerId] ??
      null) as Customer | null;
  });

  useEffect(() => {
    setAddressData({
      country: customerEntity?.country ?? '',
      email: customerEntity?.email ?? '',
      fax: customerEntity?.fax ?? '',
      language: customerEntity?.language ?? '',
      mobile: customerEntity?.mobile ?? '',
      name: customerEntity?.name ?? '',
      street: customerEntity?.street ?? '',
      telephone: customerEntity?.telephone ?? '',
      town: customerEntity?.town ?? '',
      zipCode: customerEntity?.zipCode ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUpdateTs]);

  if (customerPending || customerUpdatePending || customerEntity === null) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.collectorList}
        linkText={t('collectors')}
        heading={t('editCollector')}
      />
      <AddressContact
        addressData={addressData}
        setAddressData={setAddressData}
        onValidation={setAddressDataValid}
      />
      <Link to={CustomerRouteMap.collectorList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onSave={() => {
          markListAsStale(customerCustomerViewListStoreKey);
          markEntityAsStale([customerCustomerStoreKey, [customerId]]);
          markListAsStale(customerFilterStoreKey);
          markEntityAsStale([customerSelectStoreKey, [customerId]]);
          updateCustomerHandle(addressData, customerId)?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
        disableSave={!addressDataValid}
        onCancel={() => cancelLink?.current?.click()}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditCollector);
