import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  listShippingAgents,
  ShippingAgentListEntry,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import { shippingAgentListStoreKey } from '../../../store/store-keys';
import CustomerFilter from '../../common/components/CustomerFilter';
import { RootRouteMap } from '../../../routes/root-route-map';
import formStyles from '../../../components/form/formStyles.module.scss';

const ShippingAgentList: React.FC = () => {
  const { t } = useTranslation();

  const [shippingAgentCustomerId, setShippingAgentCustomerId] =
    useState<string>('default');

  const addShippingAgentBtn = (
    <Link to={`${RootRouteMap.customer}add-shipping-agent/`}>
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addShippingAgent')}
      </Button>
    </Link>
  );

  return (
    <>
      <div className={formStyles.inputContainer}>
        <CustomerFilter
          onCustomerIdChanged={setShippingAgentCustomerId}
          defaultValue={'default'}
        />
      </div>
      {
        <List
          hasPagination={true}
          hasSearchBar={true}
          addEntitySlot={addShippingAgentBtn}
          editEntityLink={`${RootRouteMap.customer}edit-shipping-agent`}
          columnLabels={[
            { prop: 'name', label: t('name') },
            { prop: 'town', label: t('city') },
            { prop: 'telephone', label: t('phone') },
            { prop: 'customerName', label: t('customer') },
          ]}
          columnLabelGrid={'2.5fr 1.75fr 1.75fr 1.75fr'}
          rowValueTransform={(obj: ShippingAgentListEntry) => [
            obj.name,
            obj.town ? obj.town : '-',
            obj.telephone ? obj.telephone : '-',
            obj.customerName,
          ]}
          entityKey={shippingAgentListStoreKey}
          // @ts-ignore
          queryBuilder={listShippingAgents}
          key={'shippingAgentsList'}
          additionalFilterProps={{
            ...(shippingAgentCustomerId !== 'default'
              ? { customerId: shippingAgentCustomerId }
              : {}),
          }}
        />
      }
    </>
  );
};

export default ShippingAgentList;
