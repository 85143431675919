import {
  HttpMethods,
  NetworkHandler,
  NetworkOptions,
  ResponseBody,
  ResponseHeaders,
  ResponseStatus,
  ResponseText,
  Url,
} from 'redux-query';

import API from '@aws-amplify/api';
import { RootState } from 'typesafe-actions';
import { UserRole } from '../features/common/types';

interface ApiGatewayResponseHack {
  data: object;
  status: number;
  statusText: string;
  headers: ResponseHeaders;
}

class PasApiService {
  private _stateGetter: (() => RootState) | null;

  constructor() {
    this._stateGetter = null;
  }

  public setStoreGetter(getter: () => RootState) {
    this._stateGetter = getter;
  }

  public awsApiGatewayNetworkInterface(
    url: Url,
    method: HttpMethods,
    networkOptions: NetworkOptions
  ): NetworkHandler {
    let aborted = false;
    return {
      abort: () => {
        aborted = true;
      },
      execute: (
        callback: (
          error: any,
          status: ResponseStatus,
          responseBody?: ResponseBody,
          responseText?: ResponseText,
          responseHeaders?: ResponseHeaders
        ) => void
      ) => {
        // Remove the server chosen by the generator
        url = url.replace('https://cloud-api.net/pas-staging', '');

        let body = {};
        const headers = {
          ...networkOptions.headers,
        };

        if (this._stateGetter) {
          const state = this._stateGetter().common;
          if (
            state.user?.role === UserRole.Admin &&
            state.assumedRole != null
          ) {
            headers['X-Assume-Role'] = String(state.assumedRole.role);
            headers['X-Assume-Email'] = String(state.assumedRole.name);
          }
        }

        let queryStringParameters = {};

        // todo: for some godforsaken reason, query params are unified with the body.
        //  If they are used at the same time, we are effed right now.
        if (method === 'GET' && networkOptions.body) {
          queryStringParameters = networkOptions.body;
        } else if (method === 'POST' && networkOptions.body) {
          body = networkOptions.body;
        }

        const apiName = 'PasBackend';
        let responsePromise: Promise<any> | null = null;
        switch (method) {
          case 'GET':
            responsePromise = API.get(apiName, url, {
              headers,
              queryStringParameters,
              response: true,
            });
            break;
          case 'POST':
            responsePromise = API.post(apiName, url, {
              headers,
              body,
              response: true,
            });
            break;
          case 'DELETE':
            responsePromise = API.del(apiName, url, {
              headers,
              queryStringParameters,
              body,
              response: true,
            });
            break;
          default:
            throw new Error('Unconfigured HTTP Method');
        }

        return responsePromise
          .then((response: ApiGatewayResponseHack) => {
            if (aborted) {
              return;
            }
            callback(
              null,
              response.status as number,
              response.data,
              response.statusText,
              response.headers
            );
          })
          .catch(error => {
            let status = 500;
            let text = '';
            let headers = {};
            let body = {};

            if (error.response) {
              status = error.response.status;
              text = error.response.statusText;
              body = error.response.data;
              headers = error.response.headers;
            }

            callback(error, status, body, text, headers);
          });
      },
    };
  }
}

export default new PasApiService();
