import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../components/list/Tabs';
import CollectorList from '../components/CollectorList';
import AccountList from '../components/AccountList';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ScmRouteMap } from '../../../routes/root-route-map';

const RouteToTabMapping = {
  [ScmRouteMap.collectorList]: 0,
  [ScmRouteMap.accountList]: 1,
};

const TabToRouteMapping = Object.fromEntries(
  Object.entries(RouteToTabMapping).map(it => [it[1], it[0]])
);

const Home: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const onChangeTab = (tab: number) => {
    history.push({ pathname: `${TabToRouteMapping[tab] ?? ''}` });
  };

  const match = useRouteMatch({
    path: Object.keys(RouteToTabMapping),
    exact: true,
  });

  const currentTab =
    match?.path != null ? RouteToTabMapping[match?.path] ?? 0 : 0;

  if (currentTab === RouteToTabMapping[ScmRouteMap.collectorList]) {
    return (
      <>
        <Tabs
          tabs={[t('collectors'), t('accounts')]}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <CollectorList />
      </>
    );
  } else if (currentTab === RouteToTabMapping[ScmRouteMap.accountList]) {
    return (
      <>
        <Tabs
          tabs={[t('collectors'), t('accounts')]}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <AccountList />
      </>
    );
  } else {
    return <div>index error</div>;
  }
};

export default Home;
