// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerAllOf,
    CustomerAllOfFromJSON,
    CustomerAllOfToJSON,
    CustomerFullRequest,
    CustomerFullRequestFromJSON,
    CustomerFullRequestToJSON,
} from './';

/**
 * @type CustomerFull
 * @export
 */
export interface CustomerFull extends CustomerAllOf, CustomerFullRequest {
}

export function CustomerFullFromJSON(json: any): CustomerFull {
    return {
        ...CustomerAllOfFromJSON(json),
        ...CustomerFullRequestFromJSON(json),
    };
}

export function CustomerFullToJSON(value?: CustomerFull): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerAllOfToJSON(value),
        ...CustomerFullRequestToJSON(value),
    };
}
