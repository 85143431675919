import classnames from 'classnames';
import React from 'react';
import styles from './Tabs.module.scss';

interface Props {
  tabs: string[];
  currentTab: number;
  setCurrentTab: (i: number) => void;
}

const Tabs: React.FC<Props> = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <div className={styles.container}>
      {tabs.map((tab, i) => (
        <button
          className={classnames(styles.button, {
            [styles.active]: currentTab === i,
          })}
          onClick={() => setCurrentTab(i)}
          key={tab}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
