import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserData, UserRole } from '../features/common/types';

export function getUser(): Observable<UserData> {
  return from(Auth.currentSession()).pipe(
    map(session => session.getIdToken()),
    map(idToken => {
      let role = UserRole.Unauthorized;
      const roles = idToken?.payload?.['cognito:groups'] as string[] | null;
      if (roles == null) {
        role = UserRole.Unauthorized;
      } else if (roles.includes('admin')) {
        role = UserRole.Admin;
      } else if (roles.includes('customer')) {
        role = UserRole.Customer;
      } else if (roles.includes('plant_supervisor')) {
        role = UserRole.PlantOperator;
      } else if (roles.includes('plant_supervisor_master')) {
        role = UserRole.PlantOperatorMaster;
      } else if (roles.includes('scm')) {
        role = UserRole.Manager;
      }

      return {
        name: idToken?.payload?.email,
        id: idToken?.payload?.['cognito:username'],
        role,
      };
    })
  );
}
