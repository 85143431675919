// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CustomerCreationResponse,
    CustomerCreationResponseFromJSON,
    CustomerCreationResponseToJSON,
    CustomerFull,
    CustomerFullFromJSON,
    CustomerFullToJSON,
    CustomerFullList,
    CustomerFullListFromJSON,
    CustomerFullListToJSON,
    CustomerFullRequest,
    CustomerFullRequestFromJSON,
    CustomerFullRequestToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface CreateCustomerFullRequest {
    customerFullRequest: CustomerFullRequest;
}

export interface DeleteCustomerFullRequest {
    customerId: string;
}

export interface GetCustomerFullRequest {
    customerId: string;
}

export interface ListCustomersFullRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListCustomersFullSortDirEnum;
}

export interface UpdateCustomerFullRequest {
    customerFull: CustomerFull;
}


/**
 * Creates a new customer (Abholer)
 */
function createCustomerFullRaw<T>(requestParameters: CreateCustomerFullRequest, requestConfig: runtime.TypedQueryConfig<T, CustomerCreationResponse> = {}): QueryConfig<T> {
    if (requestParameters.customerFullRequest === null || requestParameters.customerFullRequest === undefined) {
        throw new runtime.RequiredError('customerFullRequest','Required parameter requestParameters.customerFullRequest was null or undefined when calling createCustomerFull.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/full/create`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerFullRequestToJSON(requestParameters.customerFullRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerCreationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Creates a new customer (Abholer)
*/
export function createCustomerFull<T>(requestParameters: CreateCustomerFullRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomerCreationResponse>): QueryConfig<T> {
    return createCustomerFullRaw(requestParameters, requestConfig);
}

/**
 * Deletes the given customer
 */
function deleteCustomerFullRaw<T>(requestParameters: DeleteCustomerFullRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
        throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomerFull.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/full/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes the given customer
*/
export function deleteCustomerFull<T>(requestParameters: DeleteCustomerFullRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteCustomerFullRaw(requestParameters, requestConfig);
}

/**
 * Returns a customers details.
 */
function getCustomerFullRaw<T>(requestParameters: GetCustomerFullRequest, requestConfig: runtime.TypedQueryConfig<T, CustomerFull> = {}): QueryConfig<T> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
        throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerFull.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/full/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerFullFromJSON(body), text);
    }

    return config;
}

/**
* Returns a customers details.
*/
export function getCustomerFull<T>(requestParameters: GetCustomerFullRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomerFull>): QueryConfig<T> {
    return getCustomerFullRaw(requestParameters, requestConfig);
}

/**
 * Lists all customers (Abholer)
 */
function listCustomersFullRaw<T>(requestParameters: ListCustomersFullRequest, requestConfig: runtime.TypedQueryConfig<T, CustomerFullList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/full/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerFullListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all customers (Abholer)
*/
export function listCustomersFull<T>(requestParameters: ListCustomersFullRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomerFullList>): QueryConfig<T> {
    return listCustomersFullRaw(requestParameters, requestConfig);
}

/**
 * Creates a new customer (Abholer)
 */
function updateCustomerFullRaw<T>(requestParameters: UpdateCustomerFullRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.customerFull === null || requestParameters.customerFull === undefined) {
        throw new runtime.RequiredError('customerFull','Required parameter requestParameters.customerFull was null or undefined when calling updateCustomerFull.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer/full/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CustomerFullToJSON(requestParameters.customerFull),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Creates a new customer (Abholer)
*/
export function updateCustomerFull<T>(requestParameters: UpdateCustomerFullRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateCustomerFullRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListCustomersFullSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
