import { UserRole } from '../features/common/types';
import React from 'react';
import { useLocation } from 'react-router';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootRouteMap } from '../routes/root-route-map';

interface Props {
  userRole?: UserRole;
}

const ScmBar: React.FC<Props> = ({ userRole }) => {
  const { t } = useTranslation();
  const location = useLocation();

  if (
    userRole !== UserRole.Manager ||
    !location.pathname.startsWith(RootRouteMap.customer)
  ) {
    return <></>;
  }

  return (
    <Link to={`${RootRouteMap.scm}`}>
      <Button variant="outlined" color="secondary">
        {t('backToScmView')}
      </Button>
    </Link>
  );
};

export default ScmBar;
