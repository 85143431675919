import React from 'react';
import styles from '../../../components/form/PageHeader.module.scss';
import { Link } from 'react-router-dom';
import Spacer from '../../../components/Spacer';
import { RootRouteMap } from '../../../routes/root-route-map';

const Home: React.FC = () => {
  return (
    <>
      <Link to={RootRouteMap.scm} className={styles.link}>
        <h3>SCM</h3>
      </Link>
      <Spacer />
      <Link to={RootRouteMap.customer} className={styles.link}>
        <h3>Customer</h3>
      </Link>
      <Spacer />
      <Link to={RootRouteMap.plantOperator} className={styles.link}>
        <h3>Operator</h3>
      </Link>
    </>
  );
};

export default Home;
