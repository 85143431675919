import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  // Drawer,
  // IconButton,
  // List,
  // ListItem,
  // ListItemIcon,
  Toolbar,
  Typography,
} from '@mui/material';
// import IconLanguage from '@mui/icons-material/Language';
// import IconLogout from '@mui/icons-material/LogoutRounded';
// import IconMenu from '@mui/icons-material/MenuRounded';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import westfalenLogo from '../assets/images/westfalen_logo.svg';
// import useWindowWidth from '../hooks/useWindowWidth';
import styles from './Navbar.module.scss';
import { UserData, UserRole } from '../features/common/types';

interface Props {
  user?: string;
  userRole?: UserRole;
  onSignOut?: () => void;
  onLanguageSelect?: (language: string) => void;
  onAssumeRole?: (userData: UserData | null) => void;
}

const Navbar: React.FC<Props> = ({
  user,
  userRole,
  onSignOut,
  onLanguageSelect,
  onAssumeRole,
}) => {
  const { t } = useTranslation();
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [assumeEmail, setAssumeEmail] = useState('');
  const [assumeRole, setAssumeRole] = useState<null | UserRole>(null);
  const [hideAdminInterface, setHideAdminInterface] = useState<boolean>(false);
  // let { width } = useWindowWidth();

  const handleChooseRole = (event: SelectChangeEvent) => {
    const val = event?.target?.value;
    if (val == null) {
      setAssumeRole(null);
      return;
    }

    let valEnum: UserRole | null = Number(val);
    switch (valEnum) {
      case UserRole.Admin:
        setAssumeRole(null);
        break;
      case UserRole.PlantOperator:
      case UserRole.Manager:
      case UserRole.Unauthorized:
      case UserRole.Customer:
        setAssumeRole(valEnum);
        break;
      default:
        setAssumeRole(null);
        return;
    }
  };

  const handleAssumeRole = () => {
    if (!onAssumeRole) {
      return;
    }
    onAssumeRole(
      assumeRole == null
        ? null
        : { role: assumeRole, name: assumeEmail, id: 'ASSUMED-ROLE' }
    );
  };

  const logo = () => {
    return (
      <img
        className={styles.logo}
        src={westfalenLogo}
        alt="Logo der Westfalen AG"
      />
    );
  };

  const userInfo = () => {
    return (
      <div className={styles.user}>
        <Typography variant="button" component="span">
          {t('welcome', { user })}
        </Typography>
      </div>
    );
  };

  // if (width < 600) {
  //   return (
  //     <>
  //       <Toolbar className={styles.navbar}>
  //         <IconButton
  //           edge="start"
  //           className={styles.menuButton}
  //           color="inherit"
  //           aria-label="menu"
  //           onClick={() => setOpenDrawer(true)}
  //         >
  //           <IconMenu />
  //         </IconButton>

  //         {logo()}
  //       </Toolbar>

  //       <Drawer
  //         anchor="left"
  //         open={openDrawer}
  //         onClose={() => setOpenDrawer(false)}
  //         className={styles.drawer}
  //       >
  //         <div className={styles.drawerHeader}></div>
  //         <List className={styles.drawerMenu}>
  //           <ListItem button>
  //             <ListItemIcon>
  //               <IconLanguage color="primary" />
  //             </ListItemIcon>
  //             <Button
  //               onClick={() => {
  //                 if (onLanguageSelect) onLanguageSelect('de');
  //               }}
  //             >
  //               Deutsch
  //             </Button>
  //           </ListItem>
  //           <ListItem button>
  //             <ListItemIcon>
  //               <IconLanguage color="primary" />
  //             </ListItemIcon>
  //             <Button
  //               onClick={() => {
  //                 if (onLanguageSelect) onLanguageSelect('en');
  //               }}
  //             >
  //               English
  //             </Button>
  //           </ListItem>
  //         </List>
  //         <List>
  //           <ListItem button>
  //             <ListItemIcon>
  //               <IconLogout color="secondary" />
  //             </ListItemIcon>
  //             <Button onClick={onSignOut} color="secondary">
  //               Logout
  //             </Button>
  //           </ListItem>
  //         </List>
  //       </Drawer>

  //       {userInfo()}
  //     </>
  //   );
  // }
  return (
    <>
      <Toolbar className={styles.navbar}>
        {logo()}
        {userRole === UserRole.Admin && !hideAdminInterface && (
          <div>
            <Select
              value={String(assumeRole ?? UserRole.Admin)}
              onChange={handleChooseRole}
            >
              <MenuItem value={UserRole.Admin} key="role-admin">
                Admin
              </MenuItem>
              <MenuItem value={UserRole.Customer} key="role-customer">
                Customer
              </MenuItem>
              <MenuItem value={UserRole.PlantOperator} key="role-operator">
                Operator
              </MenuItem>
              <MenuItem value={UserRole.Manager} key="role-scm">
                SCM
              </MenuItem>
            </Select>
            <TextField
              value={assumeEmail}
              onChange={event => setAssumeEmail(event.target.value)}
            />
            <Button onClick={handleAssumeRole}>GO</Button>
            <Button onClick={() => setHideAdminInterface(true)}>X</Button>
          </div>
        )}

        <div className={styles.tools}>
          <div className={styles.menu}>
            <ButtonGroup color={'secondary'} variant={'text'}>
              <Button
                onClick={() => {
                  if (onLanguageSelect) onLanguageSelect('de');
                }}
              >
                DE
              </Button>
              <Button
                onClick={() => {
                  if (onLanguageSelect) onLanguageSelect('en');
                }}
              >
                EN
              </Button>
              <Button
                onClick={() => {
                  if (onLanguageSelect) onLanguageSelect('fr');
                }}
              >
                FR
              </Button>
            </ButtonGroup>
          </div>

          <Button
            onClick={onSignOut}
            color="secondary"
            className={styles.logout}
          >
            {t('logout')}
          </Button>
        </div>
      </Toolbar>

      {userInfo()}
    </>
  );
};

export default Navbar;
