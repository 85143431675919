import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import { Link, useParams } from 'react-router-dom';

import {
  CustomerCreationResponse,
  getShippingAgent,
  ShippingAgent,
  updateShippingAgent,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import AddressContact, {
  AddressData,
} from '../../common/components/AddressContact';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { useTranslation } from 'react-i18next';
import {
  shippingAgentCustomerMaxWeightStoreKey,
  shippingAgentCustomerStoreKey,
  shippingAgentFilterStoreKey,
  shippingAgentListStoreKey,
  shippingAgentSelectStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditShippingAgent: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false);

  const { id: shippingAgentId } = useParams<{ id: string }>();

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [addressData, setAddressData] = useState<AddressData>({
    name: '',
    country: '',
    email: '',
    fax: '',
    language: '',
    mobile: '',
    street: '',
    telephone: '',
    town: '',
    zipCode: '',
  });

  const [{ isPending: shippingAgentUpdatePending }, updateShippingAgentHandle] =
    useMutation((addressData: AddressData) =>
      updateShippingAgent<CustomerCreationResponse>({
        shippingAgent: {
          id: shippingAgentId,
          customerId: customerId ?? '',
          name: addressData.name,
          email: addressData.email !== '' ? addressData.email : undefined,
          fax: addressData.fax !== '' ? addressData.fax : undefined,
          mobile: addressData.mobile !== '' ? addressData.mobile : undefined,
          street: addressData.street,
          telephone:
            addressData.telephone !== '' ? addressData.telephone : undefined,
          town: addressData.town,
          zipCode: addressData.zipCode,
        },
      })
    );

  const shippingAgentQueryConfig = getShippingAgent(
    { shippingAgentId },
    {
      transform: body => {
        return { [shippingAgentCustomerStoreKey]: { [body.id]: body } };
      },
      update: {
        [shippingAgentCustomerStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(shippingAgentCustomerStoreKey, shippingAgentId),
    }
  );

  const [{ isPending: agentPending, lastUpdated: agentUpdateTs }] = useRequest(
    shippingAgentQueryConfig
  );

  let agentEntity = useSelector((state: any) => {
    return (state.entities?.[shippingAgentCustomerStoreKey]?.[
      shippingAgentId
    ] ?? null) as ShippingAgent | null;
  });

  useEffect(() => {
    setAddressData({
      email: agentEntity?.email ?? '',
      fax: agentEntity?.fax ?? '',
      mobile: agentEntity?.mobile ?? '',
      name: agentEntity?.name ?? '',
      street: agentEntity?.street ?? '',
      telephone: agentEntity?.telephone ?? '',
      town: agentEntity?.town ?? '',
      zipCode: agentEntity?.zipCode ?? '',
      language: '',
      country: '',
    });
    setCustomerId(agentEntity?.customerId ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentUpdateTs]);

  if (agentPending || agentEntity == null || shippingAgentUpdatePending) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.shippingAgentList}
        linkText={t('shippingAgents')}
        heading={t('editShippingAgent')}
      />
      <AddressContact
        addressData={addressData}
        setAddressData={setAddressData}
        excludeCountryAndLanguage={true}
        onValidation={setAddressDataValid}
      />
      <Link to={CustomerRouteMap.shippingAgentList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onSave={() => {
          markListAsStale(shippingAgentListStoreKey);
          markListAsStale(shippingAgentFilterStoreKey);
          markEntityAsStale([shippingAgentCustomerStoreKey, [shippingAgentId]]);
          markEntityAsStale([shippingAgentSelectStoreKey, [shippingAgentId]]);
          markEntityAsStale([
            shippingAgentCustomerMaxWeightStoreKey,
            [shippingAgentId],
          ]);
          updateShippingAgentHandle(addressData)?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
        disableSave={customerId == null || !addressDataValid}
        onCancel={() => cancelLink?.current?.click()}
      />
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditShippingAgent);
