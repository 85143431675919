import React from 'react';
import {
  listAccountsOperator,
  OperatorAccount,
  UserAccountRoleEnum,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import { accountOperatorListStoreKey } from '../../../store/store-keys';
import { RootRouteMap } from '../../../routes/root-route-map';

const AccountList: React.FC = () => {
  const { t } = useTranslation();

  const addAccountBtn = (
    <Link to={`${RootRouteMap.plantOperator}add-account/`}>
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addOperator')}
      </Button>
    </Link>
  );

  return (
    <List
      hasPagination={false}
      hasSearchBar={true}
      hasSorting={false}
      addEntitySlot={addAccountBtn}
      editEntityLink={`${RootRouteMap.plantOperator}edit-account`}
      columnLabels={[
        { prop: 'email', label: t('email') },
        { prop: 'permissions', label: t('permissions') },
        { prop: 'role', label: t('isAdmin') },
      ]}
      columnLabelGrid="2fr 5fr 0.5fr"
      columnValueGrid="2fr 0.25fr 0.7fr 0.25fr 1fr 0.25fr 0.55fr 0.25fr 0.75fr 0.25fr 0.75fr 0.5fr"
      rowValueTransform={(obj: OperatorAccount) => [
        obj.email,
        obj.plants[0].permission ?? '-',
        obj.plants[0].plantName,
        obj.plants[1].permission ?? '-',
        obj.plants[1].plantName,
        obj.plants[2].permission ?? '-',
        obj.plants[2].plantName,
        obj.plants[3].permission ?? '-',
        obj.plants[3].plantName,
        obj.plants[4].permission ?? '-',
        obj.plants[4].plantName,
        obj.role === UserAccountRoleEnum.OperatorMaster,
      ]}
      entityKey={accountOperatorListStoreKey}
      // @ts-ignore
      queryBuilder={listAccountsOperator}
    />
  );
};

export default AccountList;
