import React from 'react';
import { ActionPromiseValue } from 'redux-query';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  error: ActionPromiseValue<any> | null;
  onClose: () => void;
}

const RequestErrorSnackbar: React.FC<Props> = ({ error, onClose }) => {
  const { t } = useTranslation();

  let severity: 'error' | 'success' = 'error';
  let translateKey = 'errorUnknownError';
  const errorMessage: string = error?.body?.error ?? '';
  if (error === null || error.status < 300) {
    translateKey = 'errorSuccess';
    severity = 'success';
  } else if (error.status === 404) {
    translateKey = 'errorNotFound';
  } else if (error.status === 403 || error.status === 401) {
    translateKey = 'errorPermissions';
  } else if (error.status === 400) {
    translateKey = 'errorParameters';
    if (errorMessage.match(/maximal weight was exceeded$/)) {
      translateKey = 'errorVehicleWeight';
    } else if (errorMessage.startsWith('TransponderAlreadyUsed')) {
      translateKey = 'errorTransponderUsed';
    } else if (errorMessage.startsWith('TrailerLicencePlateAlreadyUsed')) {
      translateKey = 'errorTrailerLicensePlateUsed';
    }
  }

  return (
    <Snackbar open={!!error} autoHideDuration={15000} onClose={onClose}>
      <Alert severity={severity}>{t(translateKey)}</Alert>
    </Snackbar>
  );
};

export default RequestErrorSnackbar;
