// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    AccountInvitationResendRequest,
    AccountInvitationResendRequestFromJSON,
    AccountInvitationResendRequestToJSON,
    AccountPasswordUpdateRequest,
    AccountPasswordUpdateRequestFromJSON,
    AccountPasswordUpdateRequestToJSON,
    CollectorAccountCreationRequest,
    CollectorAccountCreationRequestFromJSON,
    CollectorAccountCreationRequestToJSON,
    CustomerListEntry,
    CustomerListEntryFromJSON,
    CustomerListEntryToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    OperatorAccountCreationRequest,
    OperatorAccountCreationRequestFromJSON,
    OperatorAccountCreationRequestToJSON,
    OperatorAccountList,
    OperatorAccountListFromJSON,
    OperatorAccountListToJSON,
    Plant,
    PlantFromJSON,
    PlantToJSON,
    ScmAccountCreationRequest,
    ScmAccountCreationRequestFromJSON,
    ScmAccountCreationRequestToJSON,
    UserAccount,
    UserAccountFromJSON,
    UserAccountToJSON,
    UserAccountList,
    UserAccountListFromJSON,
    UserAccountListToJSON,
AccountCreationRequestAccountTypeEnum,} from '../models';

export interface CreateUserAccountRequest {
    scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest: ScmAccountCreationRequest | OperatorAccountCreationRequest | CollectorAccountCreationRequest;
}

export interface DeleteAccountRequest {
    userId: string;
}

export interface GetAccountRequest {
    userId: string;
}

export interface ListAccountsOperatorRequest {
    search?: Array<string>;
}

export interface ListAccountsScmRequest {
    search?: Array<string>;
    offset?: number;
    limit?: number;
}

export interface ListPermissionCollectorRequest {
    userId: string;
}

export interface ListPermissionOperatorRequest {
    userId: string;
}

export interface ResendUserInvitationRequest {
    accountInvitationResendRequest: AccountInvitationResendRequest;
}

export interface SetUserPasswordRequest {
    accountPasswordUpdateRequest: AccountPasswordUpdateRequest;
}

export interface UpdatePermissionCollectorRequest {
    userId: string;
    requestBody: Array<string>;
}

export interface UpdatePermissionOperatorRequest {
    userId: string;
    requestBody: Array<string>;
}


/**
 * Create a new user account
 */
function createUserAccountRaw<T>(requestParameters: CreateUserAccountRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest === null || requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest === undefined) {
        throw new runtime.RequiredError('scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest','Required parameter requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest was null or undefined when calling createUserAccount.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/create-account`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest.accountType === AccountCreationRequestAccountTypeEnum.Collector &&  CollectorAccountCreationRequestToJSON(requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest as CollectorAccountCreationRequest) || requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest.accountType === AccountCreationRequestAccountTypeEnum.Operator &&  OperatorAccountCreationRequestToJSON(requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest as OperatorAccountCreationRequest) || requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest.accountType === AccountCreationRequestAccountTypeEnum.Scm &&  ScmAccountCreationRequestToJSON(requestParameters.scmAccountCreationRequestOperatorAccountCreationRequestCollectorAccountCreationRequest as ScmAccountCreationRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Create a new user account
*/
export function createUserAccount<T>(requestParameters: CreateUserAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return createUserAccountRaw(requestParameters, requestConfig);
}

/**
 * Deletes an account
 */
function deleteAccountRaw<T>(requestParameters: DeleteAccountRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteAccount.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes an account
*/
export function deleteAccount<T>(requestParameters: DeleteAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteAccountRaw(requestParameters, requestConfig);
}

/**
 * Fetches one specific account
 */
function getAccountRaw<T>(requestParameters: GetAccountRequest, requestConfig: runtime.TypedQueryConfig<T, UserAccount> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getAccount.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserAccountFromJSON(body), text);
    }

    return config;
}

/**
* Fetches one specific account
*/
export function getAccount<T>(requestParameters: GetAccountRequest, requestConfig?: runtime.TypedQueryConfig<T, UserAccount>): QueryConfig<T> {
    return getAccountRaw(requestParameters, requestConfig);
}

/**
 * Lists all accounts an scm is allowed to see
 */
function listAccountsOperatorRaw<T>(requestParameters: ListAccountsOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, OperatorAccountList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/list-user/operator`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OperatorAccountListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all accounts an scm is allowed to see
*/
export function listAccountsOperator<T>(requestParameters: ListAccountsOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, OperatorAccountList>): QueryConfig<T> {
    return listAccountsOperatorRaw(requestParameters, requestConfig);
}

/**
 * Lists all accounts an scm is allowed to see
 */
function listAccountsScmRaw<T>(requestParameters: ListAccountsScmRequest, requestConfig: runtime.TypedQueryConfig<T, UserAccountList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/list-user/scm`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserAccountListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all accounts an scm is allowed to see
*/
export function listAccountsScm<T>(requestParameters: ListAccountsScmRequest, requestConfig?: runtime.TypedQueryConfig<T, UserAccountList>): QueryConfig<T> {
    return listAccountsScmRaw(requestParameters, requestConfig);
}

/**
 * List all customers a collector is allowed to see
 */
function listPermissionCollectorRaw<T>(requestParameters: ListPermissionCollectorRequest, requestConfig: runtime.TypedQueryConfig<T, Array<CustomerListEntry>> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listPermissionCollector.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/permissions/collector/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CustomerListEntryFromJSON), text);
    }

    return config;
}

/**
* List all customers a collector is allowed to see
*/
export function listPermissionCollector<T>(requestParameters: ListPermissionCollectorRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<CustomerListEntry>>): QueryConfig<T> {
    return listPermissionCollectorRaw(requestParameters, requestConfig);
}

/**
 * List all plants a operator is allowed to see
 */
function listPermissionOperatorRaw<T>(requestParameters: ListPermissionOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Plant>> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listPermissionOperator.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/permissions/operator/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(PlantFromJSON), text);
    }

    return config;
}

/**
* List all plants a operator is allowed to see
*/
export function listPermissionOperator<T>(requestParameters: ListPermissionOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Plant>>): QueryConfig<T> {
    return listPermissionOperatorRaw(requestParameters, requestConfig);
}

/**
 * Resend the invitation mail for a given user
 */
function resendUserInvitationRaw<T>(requestParameters: ResendUserInvitationRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.accountInvitationResendRequest === null || requestParameters.accountInvitationResendRequest === undefined) {
        throw new runtime.RequiredError('accountInvitationResendRequest','Required parameter requestParameters.accountInvitationResendRequest was null or undefined when calling resendUserInvitation.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/resend-invitation`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AccountInvitationResendRequestToJSON(requestParameters.accountInvitationResendRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Resend the invitation mail for a given user
*/
export function resendUserInvitation<T>(requestParameters: ResendUserInvitationRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return resendUserInvitationRaw(requestParameters, requestConfig);
}

/**
 * Updates the password for a user account
 */
function setUserPasswordRaw<T>(requestParameters: SetUserPasswordRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.accountPasswordUpdateRequest === null || requestParameters.accountPasswordUpdateRequest === undefined) {
        throw new runtime.RequiredError('accountPasswordUpdateRequest','Required parameter requestParameters.accountPasswordUpdateRequest was null or undefined when calling setUserPassword.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/set-password`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AccountPasswordUpdateRequestToJSON(requestParameters.accountPasswordUpdateRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates the password for a user account
*/
export function setUserPassword<T>(requestParameters: SetUserPasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return setUserPasswordRaw(requestParameters, requestConfig);
}

/**
 * Updates the list of customers a collector is allowed to see
 */
function updatePermissionCollectorRaw<T>(requestParameters: UpdatePermissionCollectorRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updatePermissionCollector.');
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
        throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updatePermissionCollector.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/permissions/collector/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.requestBody,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates the list of customers a collector is allowed to see
*/
export function updatePermissionCollector<T>(requestParameters: UpdatePermissionCollectorRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updatePermissionCollectorRaw(requestParameters, requestConfig);
}

/**
 * Updates the list of plants a operator is allowed to see
 */
function updatePermissionOperatorRaw<T>(requestParameters: UpdatePermissionOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updatePermissionOperator.');
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
        throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updatePermissionOperator.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/admin/permissions/operator/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.requestBody,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates the list of plants a operator is allowed to see
*/
export function updatePermissionOperator<T>(requestParameters: UpdatePermissionOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updatePermissionOperatorRaw(requestParameters, requestConfig);
}

