// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProductList
 */
export interface ProductList  {
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductList
     */
    entities: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    numTotal: number;
}

export function ProductListFromJSON(json: any): ProductList {
    return {
        'entities': (json['entities'] as Array<any>).map(ProductFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function ProductListToJSON(value?: ProductList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(ProductToJSON),
        'numTotal': value.numTotal,
    };
}


