import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  listVehiclesOperator,
  VehicleOperatorListEntry,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import format from 'date-fns/format';
import { vehicleOperatorListStoreKey } from '../../../store/store-keys';
import CustomerFilter from '../../common/components/CustomerFilter';
import ShippingAgentFilter from '../../common/components/ShippingAgentFilter';
import { RootRouteMap } from '../../../routes/root-route-map';
import formStyles from '../../../components/form/formStyles.module.scss';

const VehicleList: React.FC = () => {
  const { t } = useTranslation();

  const [vehicleShippingAgentId, setVehicleShippingAgentId] =
    useState<string>('default');
  const [vehicleCustomerId, setVehicleCustomerId] = useState<string>('default');

  return (
    <>
      <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
        <CustomerFilter
          onCustomerIdChanged={setVehicleCustomerId}
          defaultValue={'default'}
        />
        <ShippingAgentFilter
          onShippingAgentIdChanged={setVehicleShippingAgentId}
          defaultValue={'default'}
        />
      </div>
      <List
        hasPagination={true}
        hasSearchBar={true}
        editEntityLink={`${RootRouteMap.plantOperator}edit-vehicle`}
        columnLabels={[
          { prop: 'licencePlateTruck', label: t('licencePlateTruckListLabel') },
          { prop: 'ggvsB3Truck', label: t('adrExpirationTruckListLabel') },
          {
            prop: 'licencePlateTrailer',
            label: t('licencePlateTrailerListLabel'),
          },
          { prop: 'ggvsB3Trailer', label: t('adrExpirationTrailerListLabel') },
          { prop: 'shippingAgentName', label: t('shippingAgent') },
          { prop: 'productName', label: t('product') },
          { prop: 'transponder', label: t('transponder') },
          { prop: 'medOk', label: t('medicalClearanceListLabel') },
          { prop: 'active', label: t('clearanceAllListLabel') },
        ]}
        columnLabelGrid={
          '1.75fr 1.75fr 1.75fr 1.75fr 1.75fr 1.75fr 1.75fr 0.75fr 0.75fr'
        }
        rowValueTransform={(obj: VehicleOperatorListEntry) => [
          obj.licencePlateTruck,
          obj.ggvsB3Truck
            ? format(obj.ggvsB3Truck, t('dateFormatDateOnly'))
            : '-',
          obj.licencePlateTrailer,
          obj.ggvsB3Trailer
            ? format(obj.ggvsB3Trailer, t('dateFormatDateOnly'))
            : '-',
          obj.shippingAgentName,
          obj.productName,
          obj.transponder,
          obj.medOk,
          obj.active,
        ]}
        entityKey={vehicleOperatorListStoreKey}
        // @ts-ignore
        queryBuilder={listVehiclesOperator}
        key={'vehicleOperatorList'}
        additionalFilterProps={{
          ...(vehicleCustomerId !== 'default'
            ? { customerId: vehicleCustomerId }
            : {}),
          ...(vehicleShippingAgentId !== 'default'
            ? { shippingAgentId: vehicleShippingAgentId }
            : {}),
        }}
        searchProps={[
          'licencePlateTrailer',
          'licencePlateTruck',
          'shippingAgentName',
          'transponder',
        ]}
      />
    </>
  );
};

export default VehicleList;
