// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerCreationResponse
 */
export interface CustomerCreationResponse  {
    /**
     * 
     * @type {string}
     * @memberof CustomerCreationResponse
     */
    id: string;
}

export function CustomerCreationResponseFromJSON(json: any): CustomerCreationResponse {
    return {
        'id': json['id'],
    };
}

export function CustomerCreationResponseToJSON(value?: CustomerCreationResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}


