// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAccount,
    UserAccountFromJSON,
    UserAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAccountList
 */
export interface UserAccountList  {
    /**
     * 
     * @type {Array<UserAccount>}
     * @memberof UserAccountList
     */
    entities: Array<UserAccount>;
    /**
     * 
     * @type {number}
     * @memberof UserAccountList
     */
    numTotal: number;
}

export function UserAccountListFromJSON(json: any): UserAccountList {
    return {
        'entities': (json['entities'] as Array<any>).map(UserAccountFromJSON),
        'numTotal': json['numTotal'],
    };
}

export function UserAccountListToJSON(value?: UserAccountList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entities': (value.entities as Array<any>).map(UserAccountToJSON),
        'numTotal': value.numTotal,
    };
}


