import React, { useEffect, useRef, useState } from 'react';
import {
  ClearanceData,
  clearanceToClearanceReq,
  GlobaleClearanceData,
} from '../components/CollectorClearances';
import { useMutation, useRequest } from 'redux-query-react';
import { Link, useParams } from 'react-router-dom';

import {
  CustomerClearance,
  CustomerClearanceRequest,
  CustomerCreationResponse,
  CustomerFull,
  deleteCustomerFull,
  getClearancesForCustomer,
  getCustomerFull,
  updateCustomerFull,
  upsertCustomerClearance,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import CollectorForm from '../components/CollectorForm';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import { AddressData } from '../../common/components/AddressContact';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import { useTranslation } from 'react-i18next';
import {
  collectorScmListStoreKey,
  customerClearanceScmStoreKey,
  customerFilterStoreKey,
  customerScmStoreKey,
  customerSelectStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import DeletePopUp from '../../../components/form/DeletePopUp';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { Button } from '@mui/material';
import { RootRouteMap, ScmRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import Spacer from '../../../components/Spacer';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditCollector: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const { id: customerId } = useParams<{ id: string }>();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false);

  const [addressData, setAddressData] = useState<AddressData>({
    name: '',
    country: '',
    email: '',
    fax: '',
    language: '',
    mobile: '',
    street: '',
    telephone: '',
    town: '',
    zipCode: '',
  });
  const [clearances, setClearances] = useState<ClearanceData>({});
  const [globalClearances, setGlobalClearances] =
    useState<GlobaleClearanceData>({
      maxWeight: 0,
      active: false,
    });

  const deleteCustomerHandle = useMutation(() =>
    deleteCustomerFull({
      customerId,
    })
  )[1];

  const [{ isPending: customerUpdatePending }, updateCustomer] = useMutation(
    (
      addr: AddressData,
      globalClear: GlobaleClearanceData,
      customerId: string
    ) =>
      updateCustomerFull<CustomerCreationResponse>({
        customerFull: {
          name: addr.name,
          active: globalClear.active,
          maxWeight: globalClear.maxWeight,
          country: addr.country,
          email: addr.email !== '' ? addr.email : undefined,
          fax: addr.fax !== '' ? addr.fax : undefined,
          language: addr.language !== '' ? addr.language : undefined,
          mobile: addr.mobile !== '' ? addr.mobile : undefined,
          street: addr.street,
          telephone: addr.telephone !== '' ? addr.telephone : undefined,
          town: addr.town,
          zipCode: addr.zipCode,
          id: customerId,
        },
      })
  );
  const [{ isPending: clearanceUpdatePending }, upsertClearances] = useMutation(
    (clear: ClearanceData, customerId: string) =>
      upsertCustomerClearance(clearanceToClearanceReq(clear, customerId))
  );

  const customerQueryConfig = getCustomerFull(
    { customerId },
    {
      transform: body => {
        return { [customerScmStoreKey]: { [body.id]: body } };
      },
      update: {
        [customerScmStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(customerScmStoreKey, customerId),
    }
  );

  const clearanceQueryConfig = getClearancesForCustomer(
    { customerId },
    {
      transform: body => {
        return { [customerClearanceScmStoreKey]: { [customerId]: body } };
      },
      update: {
        [customerClearanceScmStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(customerClearanceScmStoreKey, customerId),
    }
  );

  const [{ isPending: customerPending, lastUpdated: customerUpdateTs }] =
    useRequest(customerQueryConfig);
  const [{ isPending: clearancesPending, lastUpdated: clearanceUpdateTs }] =
    useRequest(clearanceQueryConfig);

  let customerEntity = useSelector((state: any) => {
    return (state.entities?.[customerScmStoreKey]?.[customerId] ??
      null) as CustomerFull | null;
  });
  let clearanceEntities = useSelector((state: any) => {
    return (state.entities?.[customerClearanceScmStoreKey]?.[customerId] ??
      []) as CustomerClearance[];
  });

  const invalidateEntity = () => {
    markListAsStale(collectorScmListStoreKey);
    markEntityAsStale([customerScmStoreKey, [customerId]]);
    markEntityAsStale([customerClearanceScmStoreKey, [customerId]]);
    markListAsStale(customerFilterStoreKey);
    markEntityAsStale([customerSelectStoreKey, [customerId]]);
  };

  useEffect(() => {
    setAddressData({
      country: customerEntity?.country ?? '',
      email: customerEntity?.email ?? '',
      fax: customerEntity?.fax ?? '',
      language: customerEntity?.language ?? '',
      mobile: customerEntity?.mobile ?? '',
      name: customerEntity?.name ?? '',
      street: customerEntity?.street ?? '',
      telephone: customerEntity?.telephone ?? '',
      town: customerEntity?.town ?? '',
      zipCode: customerEntity?.zipCode ?? '',
    });
    setGlobalClearances({
      maxWeight: customerEntity?.maxWeight ?? 0,
      active: customerEntity?.active ?? false,
    });
    setClearances(
      Object.fromEntries(
        clearanceEntities.map(it => [
          it.plantId,
          it as CustomerClearanceRequest,
        ])
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUpdateTs, clearanceUpdateTs]);

  const content = () => {
    if (
      clearancesPending ||
      customerPending ||
      customerUpdatePending ||
      clearanceUpdatePending ||
      customerEntity === null
    ) {
      return <Loading />;
    }
    return (
      <>
        <CollectorForm
          setClearanceData={setClearances}
          setAddressData={setAddressData}
          setGlobalClearanceData={setGlobalClearances}
          clearanceData={clearances}
          addressData={addressData}
          globalClearanceData={globalClearances}
          onValidation={setAddressDataValid}
        />

        <h2>{t('viewAllData')}</h2>

        <Link to={RootRouteMap.customer}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '100%', height: '3.5rem' }}
          >
            {t('toCustomerView')}
          </Button>
        </Link>

        <Spacer />

        <Link to={ScmRouteMap.collectorList} ref={cancelLink} />
        <RequestErrorSnackbar
          error={creationError}
          onClose={() => setCreationError(null)}
        />
        <EditButtonRow
          onSave={() => {
            invalidateEntity();
            updateCustomer(addressData, globalClearances, customerId)?.then(
              resp => {
                if (resp.status > 299) {
                  setCreationError(resp);
                }
              }
            );
            upsertClearances(clearances, customerId)?.then(resp => {
              if (resp.status > 299) {
                setCreationError(resp);
              }
            });
          }}
          disableSave={!addressDataValid}
          onCancel={() => cancelLink?.current?.click()}
          onDelete={() => setShowDeletePopup(true)}
          allowDelete={true}
        />
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader
        linkUrl={ScmRouteMap.collectorList}
        linkText={t('collectors')}
        heading={t('editCollector')}
      />
      {content()}
      {showDeletePopup && (
        <DeletePopUp
          setDeletePopUp={setShowDeletePopup}
          onDelete={() => {
            invalidateEntity();
            deleteCustomerHandle()?.then(() => cancelLink?.current?.click());
          }}
        />
      )}
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditCollector);
