import { TFunctionResult } from 'i18next';
import React from 'react';
import Label from './Label';
import styles from './LabelValuePair.module.scss';

interface Props {
  label: string;
  value: string | number | TFunctionResult;
}

const LabelValuePair: React.FC<Props> = ({ label, value }) => (
  <div className={styles.container}>
    <Label>{label}</Label>
    <span className={styles.value}>{value}</span>
  </div>
);

export default LabelValuePair;
