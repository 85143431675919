// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriverOperatorData
 */
export interface DriverOperatorData  {
    /**
     * 
     * @type {string}
     * @memberof DriverOperatorData
     */
    transponder?: string|null;
    /**
     * 
     * @type {boolean}
     * @memberof DriverOperatorData
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DriverOperatorData
     */
    medTraining?: Date|null;
}

export function DriverOperatorDataFromJSON(json: any): DriverOperatorData {
    return {
        'transponder': !exists(json, 'transponder') ? undefined : json['transponder'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'medTraining': !exists(json, 'medTraining') ? undefined : new Date(new Date(json['medTraining']).getTime()+ new Date(json['medTraining']).getTimezoneOffset()*60*1000),
    };
}

export function DriverOperatorDataToJSON(value?: DriverOperatorData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transponder': value.transponder,
        'active': value.active,
        'medTraining': value.medTraining === undefined ? undefined : value.medTraining == null ? null : new Date(value.medTraining.getTime() - value.medTraining.getTimezoneOffset()*60*1000).toISOString(),
    };
}


