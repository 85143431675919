// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CustomerClearanceList,
    CustomerClearanceListFromJSON,
    CustomerClearanceListToJSON,
    CustomerClearanceRequest,
    CustomerClearanceRequestFromJSON,
    CustomerClearanceRequestToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface GetClearancesForCustomerRequest {
    customerId: string;
}

export interface UpsertCustomerClearanceRequest {
    customerId: string;
    customerClearanceRequest: Array<CustomerClearanceRequest>;
}


/**
 * Lists the clearances for a customer (Abholer)
 */
function getClearancesForCustomerRaw<T>(requestParameters: GetClearancesForCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, CustomerClearanceList> = {}): QueryConfig<T> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
        throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getClearancesForCustomer.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer-clearance/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CustomerClearanceListFromJSON(body), text);
    }

    return config;
}

/**
* Lists the clearances for a customer (Abholer)
*/
export function getClearancesForCustomer<T>(requestParameters: GetClearancesForCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, CustomerClearanceList>): QueryConfig<T> {
    return getClearancesForCustomerRaw(requestParameters, requestConfig);
}

/**
 * Upserts customers clearance data
 */
function upsertCustomerClearanceRaw<T>(requestParameters: UpsertCustomerClearanceRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
        throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling upsertCustomerClearance.');
    }

    if (requestParameters.customerClearanceRequest === null || requestParameters.customerClearanceRequest === undefined) {
        throw new runtime.RequiredError('customerClearanceRequest','Required parameter requestParameters.customerClearanceRequest was null or undefined when calling upsertCustomerClearance.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/customer-clearance/{customerId}/upsert`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.customerClearanceRequest?.map(CustomerClearanceRequestToJSON),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Upserts customers clearance data
*/
export function upsertCustomerClearance<T>(requestParameters: UpsertCustomerClearanceRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return upsertCustomerClearanceRaw(requestParameters, requestConfig);
}

