import React from 'react';
import Loading from '../../../components/Loading';
import { getProduct, Product } from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';

interface Props {
  productId: string;
}

const ProductDisplay: React.FC<Props> = ({ productId }) => {
  const shippingAgentQueryConfig = getProduct(
    { productId },
    {
      transform: body => {
        return { product: { [body.id]: body } };
      },
      update: {
        product: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
    }
  );

  const [{ isPending: shippingAgentPending }] = useRequest(
    shippingAgentQueryConfig
  );

  let productEntity = useSelector((state: any) => {
    return (state.entities?.product?.[productId] ?? null) as Product | null;
  });

  if (shippingAgentPending) {
    return <Loading />;
  }

  return <div>{productEntity?.name ?? '-'}</div>;
};

export default ProductDisplay;
