import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DriverCustomerListEntry,
  listDriversCustomer,
} from '../../../generated/api/src';
import List from '../../../components/list/List';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAdd from '@mui/icons-material/PersonAddRounded';
import format from 'date-fns/format';
import { driverCustomerListStoreKey } from '../../../store/store-keys';
import CustomerFilter from '../../common/components/CustomerFilter';
import ShippingAgentFilter from '../../common/components/ShippingAgentFilter';
import { RootRouteMap } from '../../../routes/root-route-map';
import formStyles from '../../../components/form/formStyles.module.scss';

const DriverList: React.FC = () => {
  const { t } = useTranslation();

  const [driverShippingAgentId, setDriverShippingAgentId] =
    useState<string>('default');
  const [driverCustomerId, setDriverCustomerId] = useState<string>('default');

  const addDriverBtn = (
    <Link to={`${RootRouteMap.customer}add-driver/`}>
      <Button variant="outlined" color="secondary" startIcon={<PersonAdd />}>
        {t('addDriver')}
      </Button>
    </Link>
  );

  return (
    <>
      <div className={`${formStyles.inputContainer} ${formStyles.double}`}>
        <CustomerFilter
          onCustomerIdChanged={setDriverCustomerId}
          defaultValue={'default'}
        />
        <ShippingAgentFilter
          onShippingAgentIdChanged={setDriverShippingAgentId}
          defaultValue={'default'}
        />
      </div>
      <List
        hasPagination={true}
        hasSearchBar={true}
        addEntitySlot={addDriverBtn}
        editEntityLink={`${RootRouteMap.customer}edit-driver`}
        columnLabels={[
          { prop: 'name', label: t('name') },
          { prop: 'forename', label: t('forename') },
          { prop: 'adrExpiration', label: t('adrExpiration') },
          { prop: 'shippingAgentName', label: t('shippingAgent') },
        ]}
        columnLabelGrid={'1.75fr 1.75fr 1.75fr 2.5fr'}
        rowValueTransform={(obj: DriverCustomerListEntry) => [
          obj.name,
          obj.forename ?? '-',
          obj.adrExpiration
            ? format(obj.adrExpiration, t('dateFormatDateOnly'))
            : '-',
          obj.shippingAgentName,
        ]}
        entityKey={driverCustomerListStoreKey}
        // @ts-ignore
        queryBuilder={listDriversCustomer}
        key={'driverCustomerList'}
        additionalFilterProps={{
          ...(driverCustomerId !== 'default'
            ? { customerId: driverCustomerId }
            : {}),
          ...(driverShippingAgentId !== 'default'
            ? { shippingAgentId: driverShippingAgentId }
            : {}),
        }}
        searchProps={['name', 'forename', 'shippingAgentName']}
      />
    </>
  );
};

export default DriverList;
