import { combineEpics } from 'redux-observable';
import * as commonEpics from '../features/common/epics';
// import * as pasEpics from '../features/pas/epics';

const rootEpic = combineEpics(
  ...Object.values(commonEpics)
  // ...Object.values(pasEpics)
);

export default rootEpic;
