import { CommonState } from './reducer';
import { UserRole } from './types';

export const user = (state: CommonState) => state.user?.name;
export const id = (state: CommonState) => state.user?.id;
export const role = (state: CommonState) =>
  state.user?.role ?? UserRole.Unauthorized;
export const assumedRole = (state: CommonState) => state.assumedRole ?? null;
export const loggedIn = (state: CommonState) => !!state.user;
export const userError = (state: CommonState) => state.userError;

export const stage = (state: CommonState) => state.stage;
