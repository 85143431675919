import React, { useState } from 'react';
import {
  listShippingAgents,
  ShippingAgentListEntry,
} from '../../../generated/api/src';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';
import {
  shippingAgentFilterStoreKey,
  shippingAgentSelectStoreKey,
} from '../../../store/store-keys';
import TypeaheadEntitySelect from '../../common/components/TypeaheadEntitySelect';
import CustomerFilter from '../../common/components/CustomerFilter';

interface Props {
  onAgentSelected: (agentId: string | null) => void;
  selectedAgent: string | null;
}

const ShippingAgentSelect: React.FC<Props> = ({
  onAgentSelected,
  selectedAgent,
}) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState<string | null>(null);

  return (
    <div className={`${formStyles.inputContainer} ${formStyles.joined}`}>
      <CustomerFilter
        onCustomerIdChanged={id => {
          onAgentSelected(null);
          setCustomer(id);
        }}
        defaultValue={'default'}
        displayLabel={t('customer')}
        className={`${formStyles.input} ${formStyles.left}`}
      />
      <TypeaheadEntitySelect
        entitySearchResultStoreKey={shippingAgentFilterStoreKey}
        selectedEntitySearchStoreKey={shippingAgentSelectStoreKey}
        selectedEntityId={selectedAgent}
        onSelectedEntityIdChanged={onAgentSelected}
        // @ts-ignore
        queryBuilder={listShippingAgents}
        getOptionLabel={(it: ShippingAgentListEntry) =>
          `${it.name} (${it.town ?? '-'}, ${it.customerName})`
        }
        displayLabel={t('shippingAgent')}
        additionalFilter={
          customer != null && customer !== 'default'
            ? [encodeURIComponent(`customerId=${customer}`)]
            : undefined
        }
        key={shippingAgentSelectStoreKey}
        className={`${formStyles.input} ${formStyles.right}`}
      />
    </div>
  );
};

export default ShippingAgentSelect;
