// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerFullListEntryAllOfClearances,
    CustomerFullListEntryAllOfClearancesFromJSON,
    CustomerFullListEntryAllOfClearancesToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerFullListEntryAllOf
 */
export interface CustomerFullListEntryAllOf  {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFullListEntryAllOf
     */
    active?: boolean;
    /**
     * 
     * @type {Array<CustomerFullListEntryAllOfClearances>}
     * @memberof CustomerFullListEntryAllOf
     */
    clearances: Array<CustomerFullListEntryAllOfClearances>;
}

export function CustomerFullListEntryAllOfFromJSON(json: any): CustomerFullListEntryAllOf {
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'clearances': (json['clearances'] as Array<any>).map(CustomerFullListEntryAllOfClearancesFromJSON),
    };
}

export function CustomerFullListEntryAllOfToJSON(value?: CustomerFullListEntryAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active': value.active,
        'clearances': (value.clearances as Array<any>).map(CustomerFullListEntryAllOfClearancesToJSON),
    };
}


