import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf, RootAction, RootState, Services } from 'typesafe-actions';
import i18n from '../../i18n';
import {
  assumeRoleAsync,
  setLanguage,
  signedInAsync,
  signOutAsync,
  clearRequestCache,
} from './actions';

export const signedIn: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { user }
) =>
  action$.pipe(
    filter(isActionOf(signedInAsync.request)),
    switchMap(() =>
      user.getUser().pipe(
        map(signedInAsync.success),
        catchError(error => {
          console.error('Error getting user', error);
          return of(signedInAsync.failure(error));
        })
      )
    )
  );

export const signOut: Epic<RootAction, RootAction, RootState, Services> =
  action$ =>
    action$.pipe(
      filter(isActionOf(signOutAsync.request)),
      switchMap(() =>
        from(Auth.signOut()).pipe(
          switchMap(() => of(signOutAsync.success(), push('/'))),
          catchError(error => {
            console.error('Error signing out', error);
            return of(signOutAsync.failure(error));
          })
        )
      )
    );

export const clearRequestsOnSignout: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(signOutAsync.success)),
    map(() => clearRequestCache())
  );

export const assumeRole: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { user }
) =>
  action$.pipe(
    filter(isActionOf(assumeRoleAsync.request)),
    map(action => assumeRoleAsync.success(action.payload))
  );

export const setLanguageEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = action$ =>
  action$.pipe(
    filter(isActionOf(setLanguage)),
    tap(action => i18n.changeLanguage(action.payload)),
    ignoreElements()
  );
