// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Driver,
    DriverFromJSON,
    DriverToJSON,
    DriverCreationResponse,
    DriverCreationResponseFromJSON,
    DriverCreationResponseToJSON,
    DriverCustomerList,
    DriverCustomerListFromJSON,
    DriverCustomerListToJSON,
    DriverRequest,
    DriverRequestFromJSON,
    DriverRequestToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface CreateDriverRequest {
    driverRequest: DriverRequest;
}

export interface DeleteDriverRequest {
    driverId: string;
}

export interface GetDriverRequest {
    driverId: string;
}

export interface ListDriversCustomerRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListDriversCustomerSortDirEnum;
}

export interface UpdateDriverRequest {
    driver: Driver;
}


/**
 * Creates a new driver (Fahrer)
 */
function createDriverRaw<T>(requestParameters: CreateDriverRequest, requestConfig: runtime.TypedQueryConfig<T, DriverCreationResponse> = {}): QueryConfig<T> {
    if (requestParameters.driverRequest === null || requestParameters.driverRequest === undefined) {
        throw new runtime.RequiredError('driverRequest','Required parameter requestParameters.driverRequest was null or undefined when calling createDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/create`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DriverRequestToJSON(requestParameters.driverRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverCreationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Creates a new driver (Fahrer)
*/
export function createDriver<T>(requestParameters: CreateDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, DriverCreationResponse>): QueryConfig<T> {
    return createDriverRaw(requestParameters, requestConfig);
}

/**
 * Delete a driver details.
 */
function deleteDriverRaw<T>(requestParameters: DeleteDriverRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling deleteDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/customer/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a driver details.
*/
export function deleteDriver<T>(requestParameters: DeleteDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteDriverRaw(requestParameters, requestConfig);
}

/**
 * Returns a drivers details.
 */
function getDriverRaw<T>(requestParameters: GetDriverRequest, requestConfig: runtime.TypedQueryConfig<T, Driver> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/customer/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverFromJSON(body), text);
    }

    return config;
}

/**
* Returns a drivers details.
*/
export function getDriver<T>(requestParameters: GetDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, Driver>): QueryConfig<T> {
    return getDriverRaw(requestParameters, requestConfig);
}

/**
 * Lists all drivers, customer view
 */
function listDriversCustomerRaw<T>(requestParameters: ListDriversCustomerRequest, requestConfig: runtime.TypedQueryConfig<T, DriverCustomerList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/customer/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverCustomerListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all drivers, customer view
*/
export function listDriversCustomer<T>(requestParameters: ListDriversCustomerRequest, requestConfig?: runtime.TypedQueryConfig<T, DriverCustomerList>): QueryConfig<T> {
    return listDriversCustomerRaw(requestParameters, requestConfig);
}

/**
 * Update an existing Driver (Fahrer)
 */
function updateDriverRaw<T>(requestParameters: UpdateDriverRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.driver === null || requestParameters.driver === undefined) {
        throw new runtime.RequiredError('driver','Required parameter requestParameters.driver was null or undefined when calling updateDriver.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DriverToJSON(requestParameters.driver),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update an existing Driver (Fahrer)
*/
export function updateDriver<T>(requestParameters: UpdateDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateDriverRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListDriversCustomerSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
