import React from 'react';
import formStyles from '../../../components/form/formStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { listProducts, Product } from '../../../generated/api/src';
import TypeaheadEntitySelect from '../../common/components/TypeaheadEntitySelect';

interface Props {
  onProductSelected: (productId: string | null) => void;
  selectedProduct: string | null;
  defaultOptionText?: string;
}

const ProductSelect: React.FC<Props> = ({
  onProductSelected,
  selectedProduct,
  defaultOptionText,
}) => {
  const { t } = useTranslation();

  return (
    <div className={formStyles.inputContainer}>
      <TypeaheadEntitySelect
        entitySearchResultStoreKey={'productFilter'}
        selectedEntitySearchStoreKey={'productSelect'}
        selectedEntityId={selectedProduct}
        onSelectedEntityIdChanged={onProductSelected}
        // @ts-ignore
        queryBuilder={listProducts}
        getOptionLabel={(it: Product) =>
          it.name === 'selectProduct'
            ? t('selectProduct')
            : `${it.name} (${it.id})`
        }
        displayLabel={t('product')}
        className={formStyles.input}
      />
    </div>
  );
};

export default ProductSelect;
