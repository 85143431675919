import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Home from '../features/customer/screens/Home';
import AddShippingAgent from '../features/customer/screens/AddShippingAgent';
import EditShippingAgent from '../features/customer/screens/EditShippingAgent';
import AddDriver from '../features/customer/screens/AddDriver';
import EditDriver from '../features/customer/screens/EditDriver';
import AddVehicle from '../features/customer/screens/AddVehicle';
import EditVehicle from '../features/customer/screens/EditVehicle';
import EditCollector from '../features/customer/screens/EditCollector';
import { CustomerRouteMap } from './root-route-map';

const CustomerRoutes: React.FC = () => {
  const path = useRouteMatch().path;

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={`${path}collectors`} />}
      />
      <Route exact path={Object.values(CustomerRouteMap)} component={Home} />

      <Route
        exact
        path={`${path}edit-collector/:id`}
        component={EditCollector}
      />
      <Route
        exact
        path={`${path}add-shipping-agent`}
        component={AddShippingAgent}
      />
      <Route
        exact
        path={`${path}edit-shipping-agent/:id`}
        component={EditShippingAgent}
      />
      <Route exact path={`${path}add-driver`} component={AddDriver} />
      <Route exact path={`${path}edit-driver/:id`} component={EditDriver} />
      <Route exact path={`${path}add-vehicle`} component={AddVehicle} />
      <Route exact path={`${path}edit-vehicle/:id`} component={EditVehicle} />
    </Switch>
  );
};

export default CustomerRoutes;
