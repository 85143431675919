export enum UserRole {
  Unauthorized,
  Customer,
  Manager,
  PlantOperator,
  Admin,
  PlantOperatorMaster,
}

export interface UserData {
  // Username aka emails
  name: string;
  // Opaque user id
  id: string;
  // The role of this user as provided by AWS
  role: UserRole;
}
