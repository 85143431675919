import React, { useEffect } from 'react';
import { ListingParams } from '../../../components/list/List';
import {
  ListCustomersSortDirEnum,
  TypedQueryConfig,
} from '../../../generated/api/src';
import { QueryConfig } from 'redux-query';
import TypeaheadMultiEntitySelect from './TypeaheadMultiEntitySelect';
import { useRequest } from 'redux-query-react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as stalenessSelectors from '../../staleness/selectors';
import * as stalenessActions from '../../staleness/actions';
import { RootState } from 'typesafe-actions';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markEntityAsFresh: stalenessActions.markAsFresh,
};

interface ComponentProps {
  /**
   * The store key, unter which to store the search results of the underlying multi select
   */
  entitySearchResultStoreKey: string;
  /**
   * The store key, under which to store the selected entity
   */
  selectedEntitySearchStoreKey: string;

  /**
   * The ID of the currently selected entity
   */
  selectedEntityId: string | null;
  onSelectedEntityIdChanged: (id: string | null) => void;

  queryBuilder: (
    requestParameters: ListingParams,
    requestConfig?: TypedQueryConfig</*{[key: string]:T}*/ any, any>
  ) => QueryConfig<any>;

  getOptionLabel: (it: any) => string;
  displayLabel?: string;
  additionalFilter?: string[];
  className?: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const TypeaheadEntitySelect: React.FC<Props> = ({
  entityIsStale,
  markEntityAsFresh,
  entitySearchResultStoreKey,
  selectedEntityId,
  onSelectedEntityIdChanged,
  getOptionLabel,
  queryBuilder,
  selectedEntitySearchStoreKey,
  displayLabel,
  additionalFilter,
  className,
}) => {
  const { t } = useTranslation();

  const queryKey = `select-${selectedEntitySearchStoreKey}-${
    additionalFilter != null ? `${additionalFilter.join('-')}-` : ''
  }${selectedEntityId ?? 'default'}`;

  const myFilter =
    selectedEntityId != null
      ? [
          encodeURIComponent(`id=${selectedEntityId}`),
          ...(additionalFilter ?? []),
        ]
      : additionalFilter;

  // this request ONLY fetches the entity which is currently selected
  const [
    {
      isPending: requestPending,
      lastUpdated: lastUpdatedTs,
      isFinished: requestFinished,
    },
  ] = useRequest(
    queryBuilder(
      {
        offset: 0,
        limit: 1,
        sortDir: ListCustomersSortDirEnum.Asc,
        sort: 'id',
        filter: myFilter,
        // selectedEntityId != null
        //   ? [
        //       encodeURIComponent(`id=${selectedEntityId}`),
        //       ...(additionalFilter ?? []),
        //     ]
        //   : additionalFilter,
      },
      {
        transform: body => {
          return {
            [selectedEntitySearchStoreKey]: {
              [queryKey]: body?.entities ?? body,
            },
          };
        },
        update: {
          [selectedEntitySearchStoreKey]: (oldValue: any, newValue: any) => {
            return { ...oldValue, ...newValue };
          },
        },
        force: entityIsStale(
          selectedEntitySearchStoreKey,
          String(selectedEntityId)
        ),
        queryKey,
      }
    )
  );

  useEffect(() => {
    markEntityAsFresh([
      selectedEntitySearchStoreKey,
      [String(selectedEntityId)],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntitySearchStoreKey, lastUpdatedTs]);

  let selectedEntity = useSelector((state: any) => {
    if (requestPending || !requestFinished) {
      return 'loading';
    }

    // if there is an array without entries, propagate this information
    // this means our search did not yield anything
    if (
      state.entities?.[selectedEntitySearchStoreKey]?.[queryKey]?.length === 0
    ) {
      return 'noEntries';
    }

    // this may look weird, but we can safely assume that the array under the
    // queryKey has only one entry, as we filter for the selectedEntityId
    return (
      state.entities?.[selectedEntitySearchStoreKey]?.[queryKey]?.[0] ??
      'loading'
    );
  }) as any;

  useEffect(() => {
    if (selectedEntity === 'noEntries') {
      onSelectedEntityIdChanged(null);
      return;
    }

    // this effect handles an edge case which occurs when no previous entity exist (selectedEntityId is null):
    // in this case the first db entry is displayed, but this must also be propagated to the parent
    if (!!selectedEntity && selectedEntity !== 'loading' && !requestPending) {
      onSelectedEntityIdChanged(selectedEntity.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  const isLoading =
    requestPending ||
    selectedEntity === 'loading' ||
    selectedEntity === 'noEntries';

  return (
    <TypeaheadMultiEntitySelect
      entitySearchResultStoreKey={entitySearchResultStoreKey}
      selectedEntities={selectedEntity}
      loading={isLoading}
      disabled={isLoading}
      displayLabel={displayLabel}
      onSelectedEntitiesChanges={entity => {
        if (entity == null) {
          return;
        }
        onSelectedEntityIdChanged(entity.id);
      }}
      multiSelect={false}
      queryBuilder={queryBuilder}
      getOptionLabel={it =>
        it === 'loading'
          ? t('loading')
          : it === 'noEntries'
          ? t('selectNoEntries')
          : getOptionLabel(it)
      }
      additionalOption={selectedEntity}
      additionalFilter={additionalFilter}
      key={`${entitySearchResultStoreKey}-multi-select`}
      className={className}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(TypeaheadEntitySelect);
