import React from 'react';
import { Button } from '@mui/material';
import styles from './EditButtonRow.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
  onSave: () => void;
  disableSave?: boolean;
  allowDelete?: boolean;
  onDelete?: () => void;
}

const EditButtonRow: React.FC<Props> = ({
  onCancel,
  onSave,
  disableSave,
  allowDelete,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        {allowDelete && (
          <Button
            color="error"
            variant="outlined"
            onClick={() => onDelete && onDelete()}
          >
            {t('delete')}
          </Button>
        )}
      </div>
      <div>
        <Button color="secondary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          style={{ marginLeft: '1rem' }}
          onClick={() => onSave()}
          disabled={disableSave}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

export default EditButtonRow;
