// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerAllOf,
    CustomerAllOfFromJSON,
    CustomerAllOfToJSON,
    VehicleRequest,
    VehicleRequestFromJSON,
    VehicleRequestToJSON,
} from './';

/**
 * @type Vehicle
 * @export
 */
export interface Vehicle extends CustomerAllOf, VehicleRequest {
}

export function VehicleFromJSON(json: any): Vehicle {
    return {
        ...CustomerAllOfFromJSON(json),
        ...VehicleRequestFromJSON(json),
    };
}

export function VehicleToJSON(value?: Vehicle): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerAllOfToJSON(value),
        ...VehicleRequestToJSON(value),
    };
}
