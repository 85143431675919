import { Customer, listCustomers } from '../../../generated/api/src';
import TypeaheadEntityFilter from './TypeaheadEntityFilter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customerFilterStoreKey } from '../../../store/store-keys';

interface Props {
  onCustomerIdChanged: (id: string) => void;

  defaultValue: any;
  displayLabel?: string;
  className?: string;
}

const CustomerFilter: React.FC<Props> = ({
  onCustomerIdChanged,
  defaultValue,
  displayLabel,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <TypeaheadEntityFilter
      entitySearchResultKey={customerFilterStoreKey}
      onSelectedEntityIdChanged={onCustomerIdChanged}
      defaultValue={defaultValue}
      defaultValueText={t('selectAllCustomers')}
      // @ts-ignore
      queryBuilder={listCustomers}
      getOptionLabel={(obj: Customer) => `${obj.name} (${obj.id})`}
      displayLabel={displayLabel}
      className={className}
      maxEntries={200}
    />
  );
};

export default CustomerFilter;
