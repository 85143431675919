import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'typesafe-actions';
import services from '../services';
import rootEpic from './root-epic';
import createRootReducer from './root-reducer';
import { queryMiddleware } from 'redux-query';
import PasApiService from './network-interface';

export const history = createBrowserHistory({ basename: '/' });

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({ dependencies: services });

export const getQueries = (state: any) => state.queries;
export const getEntities = (state: any) => state.entities;

const middlewares = [
  routerMiddleware(history),
  epicMiddleware,
  queryMiddleware(
    (url, method, networkOptions) =>
      PasApiService.awsApiGatewayNetworkInterface(url, method, networkOptions),
    getQueries,
    getEntities
  ),
];
const composeEnhancers = composeWithDevTools({});
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const initialState = {
  queries: {},
  entities: {},
};

const store = createStore(createRootReducer(history), initialState, enhancer);

PasApiService.setStoreGetter(() => store.getState());
epicMiddleware.run(rootEpic);

export default store;
