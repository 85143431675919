// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DriverCustomerListEntry,
    DriverCustomerListEntryFromJSON,
    DriverCustomerListEntryToJSON,
    DriverOperatorListEntryAllOf,
    DriverOperatorListEntryAllOfFromJSON,
    DriverOperatorListEntryAllOfToJSON,
} from './';

/**
 * @type DriverOperatorListEntry
 * @export
 */
export interface DriverOperatorListEntry extends DriverCustomerListEntry, DriverOperatorListEntryAllOf {
}

export function DriverOperatorListEntryFromJSON(json: any): DriverOperatorListEntry {
    return {
        ...DriverCustomerListEntryFromJSON(json),
        ...DriverOperatorListEntryAllOfFromJSON(json),
    };
}

export function DriverOperatorListEntryToJSON(value?: DriverOperatorListEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DriverCustomerListEntryToJSON(value),
        ...DriverOperatorListEntryAllOfToJSON(value),
    };
}
