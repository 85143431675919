// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DriverFull,
    DriverFullFromJSON,
    DriverFullToJSON,
    DriverOperatorList,
    DriverOperatorListFromJSON,
    DriverOperatorListToJSON,
    DriverRequestOperator,
    DriverRequestOperatorFromJSON,
    DriverRequestOperatorToJSON,
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
} from '../models';

export interface GetDriverOperatorRequest {
    driverId: string;
}

export interface ListDriversOperatorRequest {
    offset?: number;
    limit?: number;
    search?: Array<string>;
    filter?: Array<string>;
    sort?: string;
    sortDir?: ListDriversOperatorSortDirEnum;
}

export interface UpdateDriverOperatorRequest {
    driverRequestOperator: DriverRequestOperator;
}


/**
 * Returns a drivers details.
 */
function getDriverOperatorRaw<T>(requestParameters: GetDriverOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, DriverFull> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getDriverOperator.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/operator/get/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverFullFromJSON(body), text);
    }

    return config;
}

/**
* Returns a drivers details.
*/
export function getDriverOperator<T>(requestParameters: GetDriverOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, DriverFull>): QueryConfig<T> {
    return getDriverOperatorRaw(requestParameters, requestConfig);
}

/**
 * Lists all drivers (Abholer)
 */
function listDriversOperatorRaw<T>(requestParameters: ListDriversOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, DriverOperatorList> = {}): QueryConfig<T> {
    let queryParameters: any = null;

    queryParameters = {};


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.search) {
        queryParameters['search'] = requestParameters.search;
    }


    if (requestParameters.filter) {
        queryParameters['filter'] = requestParameters.filter;
    }


    if (requestParameters.sort !== undefined) {
        queryParameters['sort'] = requestParameters.sort;
    }


    if (requestParameters.sortDir !== undefined) {
        queryParameters['sortDir'] = requestParameters.sortDir;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/operator/list`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverOperatorListFromJSON(body), text);
    }

    return config;
}

/**
* Lists all drivers (Abholer)
*/
export function listDriversOperator<T>(requestParameters: ListDriversOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, DriverOperatorList>): QueryConfig<T> {
    return listDriversOperatorRaw(requestParameters, requestConfig);
}

/**
 * Update an existing Driver (Fahrer)
 */
function updateDriverOperatorRaw<T>(requestParameters: UpdateDriverOperatorRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.driverRequestOperator === null || requestParameters.driverRequestOperator === undefined) {
        throw new runtime.RequiredError('driverRequestOperator','Required parameter requestParameters.driverRequestOperator was null or undefined when calling updateDriverOperator.');
    }

    let queryParameters: any = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/driver/operator/update`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DriverRequestOperatorToJSON(requestParameters.driverRequestOperator),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update an existing Driver (Fahrer)
*/
export function updateDriverOperator<T>(requestParameters: UpdateDriverOperatorRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateDriverOperatorRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListDriversOperatorSortDirEnum {
    Asc = 'asc',
    Desc = 'desc'
}
