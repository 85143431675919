import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from '../../../components/list/Tabs';
import CustomerList from '../components/CustomerList';
import ShippingAgentList from '../components/ShippingAgentList';
import DriverList from '../components/DriverList';
import VehicleList from '../components/VehicleList';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CustomerRouteMap } from '../../../routes/root-route-map';

const RouteToTabMapping = {
  [CustomerRouteMap.collectorList]: 0,
  [CustomerRouteMap.shippingAgentList]: 1,
  [CustomerRouteMap.driverList]: 2,
  [CustomerRouteMap.vehicleList]: 3,
};

const TabToRouteMapping = Object.fromEntries(
  Object.entries(RouteToTabMapping).map(it => [it[1], it[0]])
);

const Home: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const onChangeTab = (tab: number) => {
    history.push({ pathname: `${TabToRouteMapping[tab] ?? ''}` });
  };

  const match = useRouteMatch({
    path: Object.keys(RouteToTabMapping),
    exact: true,
  });

  const currentTab =
    match?.path != null ? RouteToTabMapping[match?.path] ?? 0 : 0;

  const allTabs = [
    t('collectors'),
    t('shippingAgents'),
    t('drivers'),
    t('vehicles'),
  ];

  if (currentTab === RouteToTabMapping[CustomerRouteMap.collectorList]) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <CustomerList />
      </>
    );
  } else if (
    currentTab === RouteToTabMapping[CustomerRouteMap.shippingAgentList]
  ) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <ShippingAgentList />
      </>
    );
  } else if (currentTab === RouteToTabMapping[CustomerRouteMap.driverList]) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <DriverList />
      </>
    );
  } else if (currentTab === RouteToTabMapping[CustomerRouteMap.vehicleList]) {
    return (
      <>
        <Tabs
          tabs={allTabs}
          currentTab={currentTab}
          setCurrentTab={onChangeTab}
        />
        <VehicleList />
      </>
    );
  } else return <span>index error</span>;
};

export default Home;
