// tslint:disable
/**
 * Pas
 * This api describes the communication between the new pas front- and backend
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CustomerAllOf,
    CustomerAllOfFromJSON,
    CustomerAllOfToJSON,
    ShippingAgentRequest,
    ShippingAgentRequestFromJSON,
    ShippingAgentRequestToJSON,
} from './';

/**
 * @type ShippingAgent
 * @export
 */
export interface ShippingAgent extends CustomerAllOf, ShippingAgentRequest {
}

export function ShippingAgentFromJSON(json: any): ShippingAgent {
    return {
        ...CustomerAllOfFromJSON(json),
        ...ShippingAgentRequestFromJSON(json),
    };
}

export function ShippingAgentToJSON(value?: ShippingAgent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CustomerAllOfToJSON(value),
        ...ShippingAgentRequestToJSON(value),
    };
}
