import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Home from '../features/admin/screens/Home';

const AdminRoutes: React.FC = () => {
  const path = useRouteMatch().path;

  return (
    <Switch>
      <Route exact path={`${path}`} component={Home} />
    </Switch>
  );
};

export default AdminRoutes;
