import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import { Link, useParams } from 'react-router-dom';
import {
  deleteDriver,
  Driver,
  getDriver,
  updateDriver,
} from '../../../generated/api/src';
import Loading from '../../../components/Loading';
import * as stalenessActions from '../../staleness/actions';
import { connect, useSelector } from 'react-redux';
import PageContainer from '../../../components/form/PageContainer';
import PageHeader from '../../../components/form/PageHeader';
import EditButtonRow from '../../../components/form/EditButtonRow';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../components/form/formStyles.module.scss';
import { TextField } from '@mui/material';
import LanguageSelect from '../../common/components/LanguageSelect';
import DriverClearances from '../../common/components/DriverClearances';
import { DatePicker } from '@mui/lab';
import {
  driverCustomerListStoreKey,
  driverCustomerStoreKey,
} from '../../../store/store-keys';
import * as stalenessSelectors from '../../staleness/selectors';
import { RootState } from 'typesafe-actions';
import DeletePopUp from '../../../components/form/DeletePopUp';
import { CustomerRouteMap } from '../../../routes/root-route-map';
import { ActionPromiseValue } from 'redux-query';
import RequestErrorSnackbar from '../../../components/RequestErrorSnackbar';
import { ValidationRegex } from '../../common/components/ValidationRegex';
import ShippingAgentSelect from '../components/ShippingAgentSelect';
import { sanitizeDateFromDatePicker } from '../../../util/SanitizeDateFromDatePickerHelper';

const mapStateToProps = (state: RootState) => ({
  entityIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsStale: stalenessActions.markCategoryAsStale,
  markEntityAsStale: stalenessActions.markAsStale,
};

interface ComponentProps {}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditDriver: React.FC<Props> = ({
  markListAsStale,
  markEntityAsStale,
  entityIsStale,
}) => {
  const { t } = useTranslation();
  const cancelLink = useRef<HTMLAnchorElement | null>(null);
  const [creationError, setCreationError] =
    useState<ActionPromiseValue<any> | null>(null);

  const { id: driverId } = useParams<{ id: string }>();

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [forename, setForename] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [adrExpiration, setAdrExpiration] = useState<Date>(
    new Date(Date.now())
  );
  const [shippingAgentId, setShippingAgentId] = useState<string | null>(null);
  const [language, setLanguage] = useState<string>('');

  const forenameOk = forename.match(ValidationRegex.Driver.Forename) != null;
  const nameOk = name.match(ValidationRegex.Driver.Name) != null;
  const adExpirationOk =
    !isNaN(adrExpiration.getTime()) && adrExpiration.getFullYear() > 2000;

  const formOk =
    [forenameOk, nameOk, adExpirationOk].every(it => it) && language !== '';

  const deleteDriverHandle = useMutation(() =>
    deleteDriver({
      driverId: driverId,
    })
  )[1];

  const [{ isPending: driverUpdatePending }, updateDriverHandle] = useMutation(
    () =>
      updateDriver({
        driver: {
          id: driverId,
          name: name,
          forename: forename,
          shippingAgentId: shippingAgentId!,
          adrExpiration: adrExpiration,
          language: language,
        },
      })
  );

  const driverQueryConfig = getDriver(
    { driverId },
    {
      transform: body => {
        return { [driverCustomerStoreKey]: { [body.id]: body } };
      },
      update: {
        [driverCustomerStoreKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: entityIsStale(driverCustomerStoreKey, driverId),
    }
  );

  const [{ isPending: driverPending, lastUpdated: driverUpdateTs }] =
    useRequest(driverQueryConfig);

  let driverEntity = useSelector((state: any) => {
    return (state.entities?.[driverCustomerStoreKey]?.[driverId] ??
      null) as Driver | null;
  });

  const invalidateEntity = () => {
    markListAsStale(driverCustomerListStoreKey);
    markEntityAsStale([driverCustomerStoreKey, [driverId]]);
  };
  useEffect(() => {
    setForename(driverEntity?.forename ?? '');
    setName(driverEntity?.name ?? '');
    setShippingAgentId(driverEntity?.shippingAgentId ?? null);
    setLanguage(driverEntity?.language ?? '');
    setAdrExpiration(driverEntity?.adrExpiration ?? new Date(Date.now()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUpdateTs]);

  if (driverPending || driverEntity == null || driverUpdatePending) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <PageHeader
        linkUrl={CustomerRouteMap.driverList}
        linkText={t('drivers')}
        heading={t('editDriver')}
      />
      <div className={formStyles.section}>
        <h2>{t('personalData')}</h2>
        <div className={`${formStyles.inputContainer} ${formStyles.joined}`}>
          <TextField
            label={t('name')}
            className={`${formStyles.input} ${formStyles.left}`}
            value={name}
            onChange={event => setName(event.target.value)}
            error={!nameOk && name !== ''}
            required
          />
          <TextField
            label={t('forename')}
            className={`${formStyles.input} ${formStyles.right}`}
            value={forename}
            onChange={event => setForename(event.target.value)}
            error={!forenameOk && forename !== ''}
            required
          />
        </div>

        <div className={formStyles.inputContainer}>
          <DatePicker
            label={t('adrExpiration')}
            value={adrExpiration}
            onChange={newValue =>
              setAdrExpiration(sanitizeDateFromDatePicker(newValue))
            }
            renderInput={params => (
              <TextField {...params} className={formStyles.picker} />
            )}
            inputFormat={t('dateFormatDateOnly')}
            mask={t('dateFormatDateMask')}
          />
        </div>

        <ShippingAgentSelect
          selectedAgent={shippingAgentId}
          onAgentSelected={agentId => setShippingAgentId(agentId)}
        />

        <LanguageSelect
          onLanguageSelected={languageId => setLanguage(languageId ?? '')}
          selectedLanguage={language}
          required
        />
      </div>
      <div className={formStyles.section}>
        <h2>{t('clearances')}</h2>
        <DriverClearances driverId={driverId} />
      </div>
      <Link to={CustomerRouteMap.driverList} ref={cancelLink} />
      <RequestErrorSnackbar
        error={creationError}
        onClose={() => setCreationError(null)}
      />
      <EditButtonRow
        onCancel={() => cancelLink?.current?.click()}
        onSave={() => {
          invalidateEntity();
          updateDriverHandle()?.then(resp => {
            if (resp.status > 299) {
              setCreationError(resp);
            }
          });
        }}
        disableSave={!formOk}
        onDelete={() => setShowDeletePopup(true)}
        allowDelete={true}
      />
      {showDeletePopup && (
        <DeletePopUp
          setDeletePopUp={setShowDeletePopup}
          onDelete={() => {
            invalidateEntity();
            deleteDriverHandle()?.then(() => cancelLink?.current?.click());
          }}
        />
      )}
    </PageContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditDriver);
