import React from 'react';
import Loading from '../../../components/Loading';
import { getShippingAgent, ShippingAgent } from '../../../generated/api/src';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';

interface Props {
  shippingAgentId: string;
}

const ShippingAgentDisplay: React.FC<Props> = ({ shippingAgentId }) => {
  const shippingAgentQueryConfig = getShippingAgent(
    { shippingAgentId },
    {
      transform: body => {
        return { shippingAgentOperator: { [body.id]: body } };
      },
      update: {
        shippingAgentOperator: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
    }
  );

  const [{ isPending: shippingAgentPending }] = useRequest(
    shippingAgentQueryConfig
  );

  let shippingAgentEntity = useSelector((state: any) => {
    return (state.entities?.shippingAgentOperator?.[shippingAgentId] ??
      null) as ShippingAgent | null;
  });

  if (shippingAgentPending) {
    return <Loading />;
  }

  return <div>{shippingAgentEntity?.name ?? '-'}</div>;
};

export default ShippingAgentDisplay;
