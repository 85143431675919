import React from 'react';

const VersionInfo: React.FC = () => {
  return (
    <>
      <pre>Stage: {process.env.REACT_APP_STAGE}</pre>
      <pre>Git revision: {process.env.REACT_APP_GIT_REVISION}</pre>
    </>
  );
};

export default VersionInfo;
