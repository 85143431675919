import styles from './List.module.scss';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
} from '@mui/material';
import Search from '@mui/icons-material/SearchRounded';
import { Link } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import Spacer from '../Spacer';
import ListLabels from './ListLabels';
import ListItem from './ListItem';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListCustomersFullSortDirEnum,
  ListCustomersSortDirEnum,
  TypedQueryConfig,
} from '../../generated/api/src';
import { QueryConfig } from 'redux-query';
import { useRequest } from 'redux-query-react';
import { connect, useSelector } from 'react-redux';
import * as stalenessSelectors from '../../features/staleness/selectors';
import * as stalenessActions from '../../features/staleness/actions';
import Sadhorse from '../../assets/images/sadhorse.svg';

export function debounce(
  fn: () => void,
  timerRef: React.MutableRefObject<NodeJS.Timer | null>,
  time: number
) {
  return () => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      timerRef.current = null;
      fn();
    }, time);
  };
}

export type SortDir = ListCustomersSortDirEnum | ListCustomersFullSortDirEnum;
export type AdditionalSearchProps = { [prop: string]: string };
export function buildListingQueryConfig(
  entityKey: string,
  queryBuilder: (
    requestParameters: ListingParams,
    requestConfig?: TypedQueryConfig<any, any>
  ) => QueryConfig<any>,
  page: number,
  pageSize: number,
  sortDir: SortDir,
  sortProp: string,
  searchText: string,
  listIsStale: (listName: string, subName: string) => boolean,
  additionalFilterProps?: AdditionalSearchProps,
  searchProps?: string[],
  filterProps?: string[]
) {
  const paginationStart = (page - 1) * pageSize;

  const additionalFilterParams = Object.entries(
    additionalFilterProps ?? {}
  ).map(it => `${encodeURIComponent(`${it[0]}=${it[1]}`)}`);
  const additionalFilterText = additionalFilterParams.join('&');

  let listingKey = `start${paginationStart}size${pageSize}sortDir${sortDir}sort${sortProp}`;
  listingKey =
    searchText === '' ? listingKey : `${listingKey}search${searchText}`;
  listingKey =
    additionalFilterText === ''
      ? listingKey
      : `${listingKey}afilter${additionalFilterText}`;

  searchProps = searchProps ?? ['name'];
  const searchParams = [
    ...(searchText !== ''
      ? searchProps.map(it => encodeURIComponent(`${it}=${searchText}`))
      : []),
  ];

  filterProps = filterProps ?? [];
  const filterParams = [
    ...additionalFilterParams,
    ...(searchText !== ''
      ? filterProps.map(it => encodeURIComponent(`${it}=${searchText}`))
      : []),
  ];
  const listRequestConfig = queryBuilder(
    {
      limit: pageSize,
      offset: paginationStart,
      search: searchParams.length !== 0 ? searchParams : undefined,
      filter: filterParams.length !== 0 ? filterParams : undefined,
      sortDir: sortDir,
      sort: sortProp,
    },
    {
      transform: body => {
        return { [entityKey]: { [listingKey]: body } };
      },
      update: {
        [entityKey]: (oldValue: any, newValue: any) => {
          return { ...oldValue, ...newValue };
        },
      },
      force: listIsStale(entityKey, listingKey),
    }
  );
  return { listingKey, listRequestConfig };
}

export interface ListingParams {
  offset?: number;
  limit?: number;
  search?: Array<string>;
  filter?: Array<string>;
  sort: string;
  sortDir: SortDir;
}

export interface ColumnMapping {
  label: string;
  prop: string;
}

// interface ComponentProps<ListType,ItemType> {
interface ComponentProps {
  /// Whether this list should display pagination controls
  hasPagination: boolean;
  /// Whether this list has a search input bar
  hasSearchBar: boolean;
  /// Whether this list has sorting controls
  hasSorting?: boolean;

  /// The mapping of entity props to the string labels of each list column
  columnLabels: ColumnMapping[];
  /// A string representing the css grid-template-column https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
  /// of this list
  columnLabelGrid: string;

  /// The same as columnLabelGrid, but this prop can have more or less entries than
  /// columns
  columnValueGrid?: string;
  /// A function which transform the object fetched from the server to
  /// the values to display in each column. This function should do all necessary filtering,
  /// mapping and string formatting
  rowValueTransform: (obj: any) => any[];

  /// A slot which can be filled with an element to display which triggers an
  // "add entity" dialog
  addEntitySlot?: JSX.Element;
  /// The route to the entity detail page. The entity id is append to this
  /// route when the user clicks it.
  editEntityLink: string;

  /// The base key which is used to reference lists in the global store.
  entityKey: string;

  /// A function which returns a redux-query QueryConfig. This is usually
  /// a autogenerated function from the openapi definition which implements
  /// the listing params
  queryBuilder: (
    requestParameters: ListingParams,
    requestConfig?: TypedQueryConfig</*{[key: string]:T}*/ any, any>
  ) => QueryConfig<any>;
  /// The name of entity properties which should be searched (inclusive) for the user input
  /// from the search bar
  searchProps?: string[];
  /// The name of entity properties which should be filtered (exclusive) for the user input
  /// from the search bar
  filterProps?: string[];
  /// An object containing key-values pairs which should always be used when filtering
  additionalFilterProps?: AdditionalSearchProps;
}

const mapStateToProps = (state: RootState) => ({
  listIsStale: (listName: string, subName: string) =>
    stalenessSelectors.isStale(state.staleness, listName, subName),
});

const dispatchProps = {
  markListAsFresh: stalenessActions.markAsFresh,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof dispatchProps;
// type Props<ListType,ItemType> = ComponentProps<ListType,ItemType> & StateProps & DispatchProps
type Props = ComponentProps & StateProps & DispatchProps;

function List({
  markListAsFresh,
  listIsStale,
  entityKey,
  queryBuilder,
  rowValueTransform,
  columnValueGrid,
  columnLabels,
  columnLabelGrid,
  hasSearchBar,
  hasPagination,
  hasSorting,
  addEntitySlot,
  editEntityLink,
  additionalFilterProps,
  searchProps,
}: Props) {
  const { t } = useTranslation();

  hasSorting = hasSorting == null ? true : hasSorting;

  const [searchText, setSearchText] = useState('');
  const debounceTimerRef = useRef<NodeJS.Timer | null>(null);

  const [currentSortIndex, setCurrentSortIndex] = useState(0);
  const [currentSortProp, setCurrentSortProp] = useState<string>(
    columnLabels[0].prop ?? ''
  );
  const [currentSortDir, setCurrentSortDir] =
    useState<ListCustomersSortDirEnum>(ListCustomersSortDirEnum.Asc);

  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);

  let { listingKey, listRequestConfig } = buildListingQueryConfig(
    entityKey,
    queryBuilder,
    page,
    pageSize,
    currentSortDir,
    currentSortProp,
    searchText,
    listIsStale,
    additionalFilterProps,
    searchProps
  );

  const [{ isPending }] = useRequest(listRequestConfig);

  let entityData = useSelector((state: any) => {
    return (state.entities[entityKey] ?? {})[listingKey]?.entities;
  });

  let entityNum = useSelector((state: any) => {
    return (state.entities[entityKey] ?? {})[listingKey]?.numTotal ?? 0;
  });
  const numPages = Math.ceil(entityNum / pageSize);

  useEffect(() => {
    markListAsFresh([entityKey, [listingKey]]);
  }, [markListAsFresh, entityKey, listingKey]);

  let filterControl = <></>;
  if (hasSearchBar) {
    filterControl = (
      <div className={addEntitySlot && `${styles.withButton}`}>
        <FormControl className={styles.searchInput}>
          <InputLabel htmlFor="search-input">{t('search')}</InputLabel>
          <OutlinedInput
            id="search-input"
            label={t('search')}
            onChange={event =>
              debounce(
                () => {
                  setSearchText(event.target.value);
                  setPage(1);
                },
                debounceTimerRef,
                300
              )()
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" size="large">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {addEntitySlot}
      </div>
    );
  }

  let paginationControl = <></>;
  if (hasPagination) {
    paginationControl = (
      <div className={styles.pagination}>
        <Pagination
          color="secondary"
          count={numPages}
          page={page}
          onChange={(event, page) => {
            if (page != null) {
              setPage(page);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div>
      {filterControl}
      <Spacer />

      <div>
        <ListLabels
          hasSorting={hasSorting}
          labels={columnLabels.map(it => it.label)}
          grid={columnLabelGrid}
          currentSortIndex={currentSortIndex}
          currentSortDir={currentSortDir}
          onChangeSort={index => {
            if (index === currentSortIndex) {
              setCurrentSortDir(
                currentSortDir === ListCustomersSortDirEnum.Asc
                  ? ListCustomersSortDirEnum.Desc
                  : ListCustomersSortDirEnum.Asc
              );
            }
            setCurrentSortIndex(index);
            setCurrentSortProp(columnLabels[index].prop ?? '');
          }}
        />
        {isPending ? (
          <div className={styles.fakeItems}>
            {[...Array(10)].map((e, i) => (
              <div className={styles.fakeItem} key={i} />
            ))}
          </div>
        ) : entityData?.length > 0 ? (
          entityData.map((obj: any, i: number) => (
            <Link to={`${editEntityLink}/${obj.id}`} key={`${entityKey}-${i}`}>
              <ListItem
                values={rowValueTransform(obj)}
                grid={columnValueGrid ?? columnLabelGrid}
              />
            </Link>
          ))
        ) : (
          <div className={styles.fakeItems}>
            <div className={styles.nothing}>
              <img
                className={styles.img}
                src={Sadhorse}
                alt="A very sad horse"
              />
              Zu diesen Kriterien wurde nichts gefunden…
            </div>
          </div>
        )}
      </div>

      {paginationControl}
    </div>
  );
}

export default connect(mapStateToProps, dispatchProps)(List);
// export default function List<ListType,ItemType> () {
//   return connect(mapStateToProps)(_List as (props: Props<ListType,ItemType>) => typeof _List<ListType,ItemType>);
// }
//
// export default function<ListType,ItemType>() {
//   return connect<StateProps,DispatchProps,ComponentProps<ListType, ItemType>>
//     (mapStateToProps,dispatchProps)(((props, context) => List<ListType,ItemType>(props));
// }
